import { Avatar, Card, Stack } from '@mui/material';
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { AppLoader } from '../../components/AppLoader';
import { Header } from '../../components/Header';
import Input from '../../components/Input';
import { useQuery } from '../../hooks';
import { fetchProfile } from '../../state/actions/profile.action';
import { useAuthStore } from '../../state/stores';
// import { useProfileService } from '../../state/stores/profile.store';

const AppInfo = () => {
  const { LAST_GIT_COMMIT_HASH, CURRENT_BRANCH, REACT_APP_VERSION } = process.env;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        paddingRight: '.6rem',
        paddingBottom: '.4rem',
        fontSize: '12px',
        fontWeight: 600,
        color: 'gray',
      }}
    >
      <span>
        <label style={{ fontSize: '10px', fontWeight: 'lighter' }}>App Version </label> :{' '}
        {REACT_APP_VERSION}
      </span>
      <strong>&nbsp;|&nbsp;</strong>
      <span>
        <label style={{ fontSize: '10px', fontWeight: 'lighter' }}>Current Branch </label> :{' '}
        {CURRENT_BRANCH}
      </span>
      <strong>&nbsp;|&nbsp;</strong>
      <span>
        <label style={{ fontSize: '10px', fontWeight: 'lighter' }}>Git Commit Hash </label> :{' '}
        {LAST_GIT_COMMIT_HASH}
      </span>
    </div>
  );
};

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   color: theme.palette.text.secondary,
// }));

export const Profile = () => {
  const { user } = useAuthStore();
  const { loading, exec } = useQuery();
  // const { profile } = useProfileService();
  useEffect(() => {
    exec(() => fetchProfile());
  }, []);

  const [fNameFirstLetter] = user?.first_name || '';
  const [lNameFirstLetter] = user?.last_name || '';
  return (
    <>
      <Header title="Profile" />
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Card
          variant="elevation"
          elevation={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '40%',
            margin: '0 auto',
            marginTop: '6rem',
            p: 2,
          }}
        >
          <Avatar
            // src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
            variant="rounded"
            sizes="large"
          >
            {fNameFirstLetter}
            {lNameFirstLetter}
          </Avatar>
          <Box width={'100%'} mt={4}>
            <Stack display={'flex'} flexDirection={'row'}>
              <Input
                fullWidth
                disabled
                variant="outlined"
                label="First Name"
                value={user?.first_name}
                _onChange={() => {}}
              />
              <Input
                sx={{ ml: 1 }}
                fullWidth
                disabled
                variant="outlined"
                label="Last Name"
                value={user?.last_name}
                _onChange={() => {}}
              />
            </Stack>
            <Stack>
              <Input
                fullWidth
                disabled
                variant="outlined"
                label="Email Address"
                value={user?.default_email}
                _onChange={() => {}}
              />
            </Stack>
            <Stack>
              <Input
                fullWidth
                disabled
                variant="outlined"
                label="Phone Number"
                value={user?.default_mobile_number}
                _onChange={() => {}}
              />
            </Stack>
          </Box>
        </Card>
      )}
      <AppInfo />
    </>
  );
};
