import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { usePostStore } from '../../state/stores/posts.store';
import { useProductStore } from '../../state/stores/products.store';
import { VideoUpload } from '../ProductForm/VideoUpload';
import { FileUpload } from '../ProductForm/FileUpload';

type TMedia = {
  columnError: Record<string, boolean>;
  setColumnError: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  disabled: boolean | undefined;
  postImage: any;
  postVideo: any;
};

export const PostMedia = ({
  postImage,
  postVideo,
  columnError,
  setColumnError,
  disabled,
}: TMedia) => {
  const uploadImage = useProductStore((state) => state.uploadImage);
  const uploadVideo = usePostStore((state) => state.uploadVideo);
  const setForm = usePostStore((state) => state.setForm);

  // const postImage = image ? [image] : [];
  // const postVideo = videoUrl ? [videoUrl] : [];

  const isImageDisabled = disabled;
  const isVideoDisabled = disabled;

  const onImageDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const acceptedFile = acceptedFiles[0];
      const imageData = new FormData();
      imageData.append('image', acceptedFile);
      const imageObject = await uploadImage(imageData);
      const imageLink = imageObject?.img_str;
      if (imageLink) {
        setForm({ item: 'media_info', value: { is_video: false, media_url: imageLink } });
      }
      if (columnError.image) setColumnError((prev) => ({ ...prev, image: false }));
    },
    [setForm, uploadImage, columnError.image, setColumnError]
  );

  const onVideoDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const acceptedFile = acceptedFiles[0];
      const videoData = new FormData();
      videoData.append('video', acceptedFile);
      const videoObject = await uploadVideo(videoData);
      const videoLink = videoObject?.video_str;
      if (videoLink) {
        setForm({ item: 'media_info', value: { is_video: true, media_url: videoLink } });
      }
      if (columnError.videoUrl) setColumnError((prev) => ({ ...prev, videoUrl: false }));
    },
    [setForm, uploadVideo, columnError.videoUrl, setColumnError]
  );

  const handleRemoveVideo = () => {
    setForm({ item: 'media_info', value: { removeVideo: true } });
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <Box
        sx={{
          flex: 1,
          p: 2,
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;',
        }}
      >
        <Typography variant="h6" sx={{ pl: 2, opacity: 0.6 }} fontWeight={600}>
          Post Video
        </Typography>
        <VideoUpload
          videos={postVideo}
          onVideoDrop={onVideoDrop}
          disabled={isVideoDisabled}
          error={columnError.videoUrl}
          handleRemoveVideo={handleRemoveVideo}
        />
      </Box>
      <Typography sx={{ opacity: 0.6 }}>OR</Typography>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" sx={{ py: 1, opacity: 0.6 }} fontWeight={600}>
          Post Image
        </Typography>
        <FileUpload
          images={postImage}
          onImageDrop={onImageDrop}
          preFilledImages={undefined}
          handlePreFilledImageRemove={(_, index: number) => () => {
            setForm({ item: 'media_info', value: { removeImage: index } });
          }}
          error={columnError.image}
          disabled={isImageDisabled}
        />
      </Box>
    </Stack>
  );
};
