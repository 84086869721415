import { Box } from '@mui/material';
// import React, { useEffect } from 'react';
import { Header } from '../../components/Header';
// import { fetchBrands } from '../../state/actions/brands.actions';
// import { useBrandService } from '../../state/stores/brands.store';

export const Home = () => {
  // useEffect(() => {
  //   fetchBrands();
  // }, []);

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Home" />
      <h1>Homepage</h1>
    </Box>
  );
};
