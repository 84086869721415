import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { ProductForm } from '../../components/ProductForm';
import { useProductStore } from '../../state/stores/products.store';

export const EditProduct = () => {
  const params = useParams();
  const location: any = useLocation();
  const navigate = useNavigate();
  const fetchProductDetails = useProductStore((state) => state.fetchProductDetails);
  const rowId = location.state?.rowId;
  const isDisabled = location.state?.isDisabled;

  useEffect(() => {
    if (rowId) {
      fetchProductDetails(rowId);
    }
  }, [fetchProductDetails, rowId]);

  useEffect(() => {
    if (!rowId) {
      navigate('/products', { replace: true, state: {} });
    }
  }, [navigate, rowId]);

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Edit Product" />
      <ProductForm id={params.productId} isDisabled={isDisabled} />
    </Box>
  );
};
