// import { WindowTwoTone } from '@mui/icons-material';
import { axiosInstance } from '../axios';
import { showSnack } from '../stores/snackBar.store';

export const fetchPostService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/post/list?_start=${_start}&_end=${_end}`)
    .then((res) => res.data)
    
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const fetchInstaServer = (): any => {
  return axiosInstance
    .get(`/insta/signin`)
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const videoUploadService = (videoData: any): any => {
  return axiosInstance({
    method: 'POST',
    url: '/upload/video',
    data: videoData,
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const postDetailService = (id: number): any => {
  return axiosInstance
    .get(`vendor/post/details?post_id=${id}`)
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const postCreateService = (formData: any) => {
  const { brand, categoryId, product, categoryName, description, hashTags, image, videoUrl, media_urls } =
    formData;

  return axiosInstance({
    method: 'POST',
    url: '/vendor/post/create',
    data: {
      category_id: categoryId,
      category_name: categoryName,
      description,
      brand_id: brand,
      product_id: product,
      tags: hashTags,
      image,
      video_url: videoUrl,
      is_video: !!videoUrl,
      media_urls,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const editPostService = (formData: any) => {
  const { id, categoryId, categoryName, description, hashTags, product, media_urls } = formData;

  return axiosInstance({
    method: 'POST',
    url: '/vendor/post/edit',
    data: {
      post_id: id,
      category_id: categoryId,
      product_id: product,
      category_name: categoryName,
      description,
      tags: hashTags,
      media_urls,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const getHashTagsService = () => {
  return axiosInstance
    .get('/vendor/post/hashtag/list')
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const createHashTagsService: any = (tag: string) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/post/hashtag/create',
    data: {
      tag,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const deletePostService: any = (postId: number) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/post/delete',
    data: {
      id: postId,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const togglePostService: any = (postId: number, enable: boolean) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/post/status/change',
    data: {
      post_id: postId,
      enable: +enable,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};
