import { IWarehouseForm } from '../../pages/WareHousePage/CreateWareHouse';
import { axiosInstance } from '../axios';
import { showSnack } from '../stores/snackBar.store';
import { setStoreList, setWarehouseLoading } from '../stores/warehouse.store';

export const fetchStore = (): any => {
  setWarehouseLoading(true);
  return axiosInstance
    .get('/vendor/store/list')
    .then((res) => {
      setStoreList(res.data.data || []);
      return res.data.data || [];
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    })
    .finally(() => {
      setWarehouseLoading(false);
    });
};

export const getStore = (id: string) => {
  setWarehouseLoading(true);
  return axiosInstance
    .get('/vendor/store/details', { params: { store_id: id } })
    .then((res) => {
      return res.data || {};
    })
    .catch((err) => {
      showSnack('error', err?.data?.message || 'Some error occured while fetching.');
      throw err;
    })
    .finally(() => {
      setWarehouseLoading(false);
    });
};

export const createStore = (payload: IWarehouseForm) => {
  setWarehouseLoading(true);
  return axiosInstance
    .post('/vendor/store/create', payload)
    .then((res) => {
      showSnack('success', res.data.message);
      return res.data;
    })
    .catch((err) => {
      showSnack('error', err?.data?.message || 'Some error occured, Please try again later.');
      throw err;
    })
    .finally(() => {
      setWarehouseLoading(false);
    });
};

export const updateStore = (payload: IWarehouseForm) => {
  setWarehouseLoading(true);
  return axiosInstance
    .post('/vendor/store/edit', payload)
    .then((res) => {
      showSnack('success', res.data.message);
      return res.data;
    })
    .catch((err) => {
      showSnack('error', err?.data?.message || 'Some error occured, Please try again later.');
      throw err;
    })
    .finally(() => {
      setWarehouseLoading(false);
    });
};

export const deleteStore = (id: string) => {
  setWarehouseLoading(true);
  return axiosInstance
    .post('/vendor/store/edit')
    .then((res) => {
      showSnack('success', res.data.message);
      return res.data;
    })
    .catch((err) => {
      showSnack('error', err?.data?.message || 'Some error occured, Please try again later.');
      throw err;
    })
    .finally(() => {
      setWarehouseLoading(false);
    });
};
