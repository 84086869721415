import { ReactComponent as BrandIconLight } from '../../assets/icons/light/brand.svg';
import { ReactComponent as HomeIconLight } from '../../assets/icons/light/home.svg';
import { ReactComponent as PostIconLight } from '../../assets/icons/light/posts.svg';
import { ReactComponent as ProductIconLight } from '../../assets/icons/light/products.svg';
import { ReactComponent as WareHouseIconLight } from '../../assets/icons/light/warehouse.svg';
import { ReactComponent as OrdersIconLight } from '../../assets/icons/light/orders_reports.svg';

import { ReactComponent as BrandIconDark } from '../../assets/icons/bold/brands.svg';
import { ReactComponent as HomeIconDark } from '../../assets/icons/bold/home.svg';
import { ReactComponent as PostIconDark } from '../../assets/icons/bold/posts.svg';
import { ReactComponent as ProductIconDark } from '../../assets/icons/bold/products.svg';
import { ReactComponent as WareHouseIconDark } from '../../assets/icons/bold/warehouse.svg';
import { ReactComponent as OrderIconDark } from '../../assets/icons/bold/orders_reports.svg';

export const ROUTES = [
  {
    name: 'Home',
    route: '/',
    icon: (isActive: boolean) => (isActive ? <HomeIconDark /> : <HomeIconLight />),
  },
  {
    name: 'Brand',
    route: '/brand',
    icon: (isActive: boolean) => (isActive ? <BrandIconDark /> : <BrandIconLight />),
  },
  {
    name: 'Orders',
    route: '/orders',
    icon: (isActive: boolean) => (isActive ? <OrderIconDark /> : <OrdersIconLight />),
  },
  {
    name: 'Warehouse',
    route: '/warehouse',
    icon: (isActive: boolean) => (isActive ? <WareHouseIconDark /> : <WareHouseIconLight />),
  },
  {
    name: 'Products',
    route: '/products',
    icon: (isActive: boolean) => (isActive ? <ProductIconDark /> : <ProductIconLight />),
  },
  {
    name: 'Post',
    route: '/post',
    icon: (isActive: boolean) => (isActive ? <PostIconDark /> : <PostIconLight />),
  },
  {
    name: 'Support',
    route: '/support',
    icon: (isActive: boolean) => (isActive ? <PostIconDark /> : <PostIconLight />),
  },
];
