import { axiosInstance } from '../axios';
import { setBrandList, setBrandLoading } from '../stores/brands.store';
import { showSnack } from '../stores/snackBar.store';

export const fetchBrands = (): any => {
  setBrandLoading(true);
  return axiosInstance
    .get('/vendor/brand/list')
    .then((res) => {
      setBrandList(res.data.data || []);
      return res.data.data || [];
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    })
    .finally(() => {
      setBrandLoading(false);
    });
};

export const createBrand = (payload: {
  logo: string | null;
  name: string;
  description: string;
}) => {
  setBrandLoading(true);
  return axiosInstance
    .post('/vendor/brand/create', payload)
    .then((res) => {
      showSnack('success', res.data.message);
      return res;
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    })
    .finally(() => {
      setBrandLoading(false);
    });
};

export const editBrand = (payload: {
  logo: string | null;
  description: string;
  brand_id: string | null;
}) => {
  setBrandLoading(true);
  return axiosInstance
    .post('/vendor/brand/edit', payload)
    .then((res) => {
      showSnack('success', res.data.message);
      return res.data;
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    })
    .finally(() => {
      setBrandLoading(false);
    });
};

export const deleteBrand = (id: number) => {
  setBrandLoading(true);
  return axiosInstance
    .post('/vendor/brand/delete', { id })
    .then((res) => {
      showSnack('success', res.data.message);
      return res;
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    })
    .finally(() => {
      setBrandLoading(false);
    });
};

export const getBrand = (id: string | null) => {
  setBrandLoading(true);
  return axiosInstance({ method: 'GET', url: '/vendor/brand/details', params: { brand_id: id } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    })
    .finally(() => {
      setBrandLoading(false);
    });
};
