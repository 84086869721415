import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: red[500],
          fontSize: 18,
          '&$error': {
            fontSize: 24,
            color: red[500],
          },
        },
      },
    },
  },
});
