import { Add, ArrowDownward, Delete } from '@mui/icons-material';
import { Button, Checkbox, IconButton, Stack } from '@mui/material';
import DataTable, { TableProps } from 'react-data-table-component';
// import { DataRow } from '../../state/stores/orders.store';

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate: boolean) => isIndeterminate };

const contextActions = (deleteHandler: () => void) => (
  <IconButton color="secondary" onClick={deleteHandler}>
    <Delete />
  </IconButton>
);

export const CustomDataTable = <T,>(
  props: TableProps<T> & {
    actionTitle?: string;
    deleteAll?: () => void;
    handleRowClicked?: (row: any) => void;
    toggleCleared?: boolean;
    onActionClick?: () => void;
    onSecondaryActionClick?: () => void;
    secondaryActionTitle?: string;
    secondaryActionIcon?: any;
    expandedComponent?: any;
  }
) => {
  const expandableRows = !!props.expandedComponent;

  const actions = (
    <Stack direction="row" spacing={3}>
      {props.secondaryActionTitle ? (
        <Button
          variant="outlined"
          startIcon={props.secondaryActionIcon}
          onClick={props.onSecondaryActionClick}
        >
          {props.secondaryActionTitle}
        </Button>
      ) : null}
      {props.actionTitle ? (
        <Button variant="contained" endIcon={<Add />} onClick={props.onActionClick}>
          Create {props.actionTitle}
        </Button>
      ) : null}
    </Stack>
  );

  return (
    <DataTable
      pagination
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      responsive
      actions={actions}
      contextActions={props.deleteAll && contextActions(props.deleteAll)}
      clearSelectedRows={props.toggleCleared}
      onRowClicked={props.handleRowClicked}
      paginationComponentOptions={{ noRowsPerPage: true }}
      striped
      expandableRows={expandableRows}
      expandableRowsComponent={props.expandedComponent}
      customStyles={{
        table: {
          style: {
            height: 'calc(100vh - 190px)',
            overflow: 'auto',
          },
        },
        headCells: {
          style: {
            fontWeight: 600,
            fontSize: 14,
          },
        },
        rows: {
          style: {
            padding: '5px 0',
          },
        },
        contextMenu: {
          style: {
            '.MuiButtonBase-root': {
              color: 'gray',
            },
          },
        },
      }}
      {...props}
    />
  );
};
