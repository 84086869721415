import { Box, Button, Stack, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CurrencyInput } from './CurrencyInput';
import { useProductStore } from '../../state/stores/products.store';
import { showSnack } from '../../state/stores/snackBar.store';
import { AppTabs } from '../AppTabs';

export const ProductPrice = ({ id }: { id: string }) => {
  const [tab, setTab] = useState(0);
  const productQty = useProductStore((state) => state.priceForm.quantity);
  const sellingPrice = useProductStore((state) => state.priceForm.sellingPrice);
  const offerPrice = useProductStore((state) => state.priceForm.offerPrice);
  const taxPercentage = useProductStore((state) => state.priceForm.taxPercentage);
  const priceId = useProductStore((state) => state.priceModal.priceId);
  const setPriceForm = useProductStore((state) => state.setPriceForm);
  const clearPriceForm = useProductStore((state) => state.clearPriceForm);

  const createProductPrice = useProductStore((state) => state.createProductPrice);
  const editProductPrice = useProductStore((state) => state.editProductPrice);
  // const deleteProductPrice = useProductStore((state)=> state.deleteProductPrice);
  const fetchProductPrices = useProductStore((state) => state.fetchProductPrices);

  const isEdit = !!priceId;

  useEffect(() => {
    return () => {
      clearPriceForm();
    };
  }, [clearPriceForm]);

  const handleClose = () => {
    clearPriceForm();
  };

  const handleFormChange = useCallback(
    (item: string) => {
      return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPriceForm({ item, value: e.target.value });
      };
    },
    [setPriceForm]
  );

  const handleSubmit = async () => {
    if (!productQty || !sellingPrice || !offerPrice) {
      showSnack('error', 'Please fill all the required values.');
      return;
    }
    try {
      const result = isEdit
        ? await editProductPrice({
            id: priceId,
            productId: +id,
            productQty,
            sellingPrice,
            offerPrice,
            taxPercentage
          })
        : await createProductPrice({ id, productQty, sellingPrice, offerPrice, taxPercentage });
      if (result && typeof result === 'string') {
        handleClose();
        showSnack('success', result);
        fetchProductPrices(id);
      }
    } catch (error) {
      showSnack('error');
    }
  };

  const productPriceStack = (
    <Box>
      <Stack spacing={3} component="form" sx={{ minWidth: 300 }}>
        <TextField
          value={productQty}
          autoFocus
          margin="dense"
          id="product-price-quantity"
          label="Product Variant"
          fullWidth
          variant="standard"
          onChange={handleFormChange('quantity')}
        />
        <TextField
          value={sellingPrice}
          label="Selling Price"
          name="sellingPrice"
          id="product-selling-price"
          InputProps={{
            inputComponent: CurrencyInput,
          }}
          fullWidth
          variant="standard"
          onChange={handleFormChange('sellingPrice')}
        />
        <TextField
          value={offerPrice}
          label="Offer Price"
          name="Offer Price"
          id="product-offer-price"
          InputProps={{
            inputComponent: CurrencyInput,
          }}
          fullWidth
          variant="standard"
          onChange={handleFormChange('offerPrice')}
        />
        <TextField
          value={taxPercentage}
          label="Tax Percentage"
          name="Tax Percentage"
          id="tax-percentage"
          fullWidth
          variant="standard"
          onChange={handleFormChange('taxPercentage')}
        />
      </Stack>
      <Stack spacing={4} direction="row" sx={{ mt: 6 }}>
        {isEdit ? (
          <Button onClick={clearPriceForm} variant="text" color="error" sx={{ flex: 1 }}>
            Cancel
          </Button>
        ) : null}
        <Button onClick={handleSubmit} variant="outlined" sx={{ flex: 1 }}>
          {isEdit ? 'Save' : 'Add'}
        </Button>
      </Stack>
    </Box>
  );

  const tabElements = [
    {
      label: 'Product Prices Form',
      element: productPriceStack,
    },
  ];

  return <AppTabs tabs={tabElements} value={tab} setValue={setTab} />;
};
