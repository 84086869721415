import { Button, Dialog, DialogContent, Stack, TextareaAutosize, Typography } from '@mui/material';
import React, { useState } from 'react';
import { postSupportComment } from '../../state/actions/support.action';
import { showSnack } from '../../state/stores/snackBar.store';

const WriteReplyModal = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: any;
}) => {
  const [comment, setComment] = useState('');
  return (
    <Dialog id="pickup-modal" open={open} fullWidth>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await postSupportComment(data.desk_ticket_id, comment);
              showSnack('success', 'Comment posted successfully.');
              onClose();
            } catch {
              showSnack('error', 'Something went wrong, Please try again');
            }
          }}
        >
          <Stack direction={'row'} justifyContent="space-between" alignItems={'flex-start'}>
            <Stack>
              <Typography variant="caption">
                <strong>Contact Support</strong>
              </Typography>
              <Typography variant="body2">
                Orders / ... / Tickets / {data.desk_ticket_id}
              </Typography>
            </Stack>
            <Stack>
              <Button onClick={onClose} size="small" variant="outlined">
                Close
              </Button>
            </Stack>
          </Stack>
          <Stack mt={2}>
            <Typography variant="caption" mb={1}>
              <strong>Write your reply</strong>
            </Typography>
            <TextareaAutosize
              style={{ padding: '10px' }}
              minRows={8}
              onChange={(e) => setComment(e.target.value)}
            />
          </Stack>
          <Stack mt={2} direction={'row'}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '5px' }}
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WriteReplyModal;
