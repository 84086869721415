import { memo } from 'react';
import { Badge, Box, Stack, Typography } from '@mui/material';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { grey, red } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

type TFileUploadProps = {
  images: string[];
  onImageDrop:
    | (<T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent
      ) => void)
    | undefined;
  preFilledImages: string[] | undefined;
  handlePreFilledImageRemove: (image: string, index: number) => () => void;
  error?: boolean;
  disabled?: boolean;
  multi?: boolean;
};

export const FileUpload = memo(
  ({
    images,
    onImageDrop,
    preFilledImages,
    handlePreFilledImageRemove,
    error,
    disabled,
    multi = true,
  }: TFileUploadProps) => {
    const combinedImages = preFilledImages ? images.concat(preFilledImages) : images;

    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      multiple: multi,
      onDrop: onImageDrop,
    });

    const getDisabled = () => {
      if (disabled) {
        return true;
      } else if (combinedImages.length > 0 && !multi) {
        return true;
      } else return false;
    };

    return (
      <Box sx={{ flex: 1 }}>
        {combinedImages && (
          <Box sx={{ minHeight: 140 }}>
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{
                maxHeight: 200,
                overflow: 'auto',
                p: 2,
                mb: 2,
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: error ? red[500] : grey[300],
                borderRadius: 2,
                height: 162,
              }}
            >
              {combinedImages.length ? (
                combinedImages.map((item: string, index) => (
                  <Badge
                    key={`${item}-${index}`}
                    color="primary"
                    badgeContent={<CloseIcon sx={{ fontSize: 12, cursor: 'pointer' }} />}
                    sx={{ m: 1 }}
                    onClick={handlePreFilledImageRemove(item, index)}
                  >
                    <Box
                      sx={{
                        overflow: 'hidden',
                        border: '1px solid lightgrey',
                        borderRadius: 3,
                        height: 110,
                      }}
                    >
                      <img
                        src={`${item}`}
                        style={{
                          objectFit: 'fill',
                          height: '100%',
                        }}
                        alt={item}
                        loading="lazy"
                        width={100}
                        height={100}
                      />
                    </Box>
                  </Badge>
                ))
              ) : (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography variant="body2" sx={{ color: grey[600] }}>
                    Selected images will be displayed here.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: grey[100],
            p: 8,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: grey[200],
            borderRadius: 2,
            ':hover': {
              borderColor: grey[400],
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: disabled ? 'no-drop' : 'pointer',
            }}
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} disabled={getDisabled()} />
            <p style={{ margin: '1.4px 0' }}>Drag 'n' drop </p>
            <p style={{ margin: '1.4px 0' }}>Or</p>
            <p style={{ margin: '1.4px 0' }}>Click Here..</p>
          </Box>
        </Box>
      </Box>
    );
  }
);
