import { Box } from '@mui/material';
import { Listings } from '../../components/Listings';
import { productHeaders } from './products.utils';
import { THeaderCell } from '../../components/DataTable/dataTable.types';
import { useCallback, useEffect, useState } from 'react';
import { useProductStore } from '../../state/stores/products.store';
import { Header } from '../../components/Header';

const productHeaderCells: THeaderCell[] = productHeaders.map((product) => {
  return {
    id: product.id,
    numeric: false,
    disablePadding: false,
    label: product.name,
  };
});

const TOTAL_PRODUCTS_PER_PAGE = 10;

export const Products = () => {
  const [page, setPage] = useState(0);
  const fetchProducts = useProductStore((state) => state.fetchProducts);
  const productsData = useProductStore((state) => state.products);
  const totalCount = useProductStore((state) => state.totalProducts);

  const handleProducts = useCallback(() => {
    const _start = page * TOTAL_PRODUCTS_PER_PAGE;
    const _end = (page === 0 ? 1 : page + 1) * TOTAL_PRODUCTS_PER_PAGE - 1;
    fetchProducts(_start, _end);
  }, [fetchProducts, page]);

  // Fetch Products
  useEffect(() => {
    handleProducts();
  }, [handleProducts]);

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Products" />
      <Listings
        productHeaderCells={productHeaderCells}
        rows={productsData}
        type="products"
        page={page}
        setPage={setPage}
        totalCount={totalCount}
      />
    </Box>
  );
};
