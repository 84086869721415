import { Box, Stack, Typography } from '@mui/material';

import { forwardRef } from 'react';
import { useOrdersStore } from '../../state/stores/orders.store';

export const ShippingLabelComponentToPrint = forwardRef((props, ref) => {
  const shippingLabelData = useOrdersStore((state) => state.shippingLabelData);

  if (!shippingLabelData) return null;

  const {
    shipping_label_barcode,
    uid_barcode,
    transportation_type,
    payment_type,
    shipping_address: { email, address_1, address_2, address_3, state, city, country, pincode },
    product_details: { name: productName, total_price, item_price },
    seller_address: {
      name,
      address,
      city: sellerCity,
      country: sellerCountry,
      pincode: sellerPincode,
    },
    return_address: {
      address: returnAddress,
      city: returnCity,
      country: returnCountry,
      pincode: returnPincode,
    },
  } = shippingLabelData;

  const bb = { borderBottom: '1px solid black' };
  const br = { borderRight: '1px solid black' };

  return (
    <Stack ref={ref} sx={{ width: 400, border: '1px solid black' }}>
      <Stack flex={1} sx={{ borderBottom: '1px solid black' }} p={1}>
        <Box
          flex={1}
          p={2}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src={shipping_label_barcode} alt="shipping label barcode" />
        </Box>
        <Stack direction="row" p={1}>
          <Typography flex={1}>122003</Typography>
          <Typography flex={1} sx={{ textAlign: 'right' }}>
            DUM/WLT
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ borderBottom: '1px solid black' }}>
        <Stack flex={3} flexWrap="wrap" sx={{ borderRight: '1px solid black' }} p={1}>
          <Typography>Shipping Address:</Typography>
          <Typography variant="h6">{email}</Typography>
          <Typography>
            {address_1} {address_2}
          </Typography>
          <Typography>
            {address_3}
          </Typography>
          <Typography>
            {city}, {state}, {country}
          </Typography>
          <Typography>PIN: {pincode}</Typography>
        </Stack>
        <Stack flex={1} justifyContent="center" alignItems="center">
          <Typography variant="h6">{payment_type}</Typography>
          <Typography>{total_price}</Typography>
          <Typography>{transportation_type}</Typography>
        </Stack>
      </Stack>
      <Stack flex={3} flexWrap="wrap" sx={{ borderBottom: '1px solid black' }} p={1}>
        <Typography>Seller Address:</Typography>
        <Typography variant="h6">{name}</Typography>
        <Typography>{address}</Typography>
        <Typography>
          {sellerCity}, {sellerCountry}
        </Typography>
        <Typography>PIN: {sellerPincode}</Typography>
      </Stack>
      <Box flex={1}>
        <table style={{ width: '100%', borderSpacing: 0 }}>
          <thead>
            <tr>
              <th style={{ ...bb, ...br }}>Product</th>
              <th style={{ textAlign: 'center', ...bb, ...br }}>Price</th>
              <th style={{ textAlign: 'center', ...bb }}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '4px', ...bb, ...br }}>{productName}</td>
              <td style={{ textAlign: 'center', ...bb, ...br }}>{item_price}</td>
              <td style={{ textAlign: 'center', ...bb }}>{total_price}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td style={{ ...br, ...bb }}>Total</td>
              <td style={{ textAlign: 'center', fontWeight: 'bold', ...br, ...bb }}>
                {item_price}
              </td>
              <td style={{ textAlign: 'center', fontWeight: 'bold', ...bb }}>{total_price}</td>
            </tr>
          </tfoot>
        </table>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid black',
        }}
        p={2}
      >
        <img src={uid_barcode} alt="UID barcode" />
      </Box>
      <Stack flex={3} flexWrap="wrap" p={1}>
        <Typography>Return Address:</Typography>
        <Typography>{returnAddress}</Typography>
        <Typography>
          {returnCity}, {returnCountry}
        </Typography>
        <Typography>PIN: {returnPincode}</Typography>
      </Stack>
    </Stack>
  );
});
