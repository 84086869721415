import { Box, Button, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostSwitch } from '../../pages/PostsPage/PostSwitch';
import { useBrandService } from '../../state/stores/brands.store';
import { usePostStore } from '../../state/stores/posts.store';
import { useProductStore } from '../../state/stores/products.store';
import { showSnack } from '../../state/stores/snackBar.store';
import { FormTabs } from '../FormTabs';
import { FormSelect } from '../ProductForm/FormSelect';
import { TSelectOptions } from '../ProductForm/productForm.types';
import { FormInput } from '../ProductForm/ProductFormInput';
import { HashTagsSelect } from './HashTagsSelect';
import { validatePostForm } from './postForm.utils';
import { PostMedia } from './PostMedia';
import { PostTags } from './PostTags';

export const PostForm = ({ id, isDisabled }: { id?: string; isDisabled?: boolean }) => {
  const [columnError, setColumnError] = useState<Record<string, boolean>>({});
  const [disabled, setDisabled] = useState(() => isDisabled);
  const [value, setValue] = useState(0);
  const brand = usePostStore((state) => state.postForm.brand);
  const category = usePostStore((state) => state.postForm.category);
  const product = usePostStore((state) => state.postForm.product);
  const description = usePostStore((state) => state.postForm.description);
  const hashTags = usePostStore((state) => state.postForm.hashTags);
  // const image = usePostStore((state) => state.postForm.image);
  // const videoUrl = usePostStore((state) => state.postForm.videoUrl);
  const media_info = usePostStore((state) => state.postForm.media_info);
  const brandService = useBrandService((state) => state.brands);
  const categories = useProductStore((state) => state.categories);
  const hashTagsList = usePostStore((state) => state.hashTagsList);
  const filteredProductList = usePostStore((state) => state.filteredProductList);
  const postStatus = usePostStore((state) => state.postStatus);
  const navigate = useNavigate();

  const setForm = usePostStore((state) => state.setForm);
  const setBrand = usePostStore((state) => state.setBrand);
  const setCategory = usePostStore((state) => state.setCategory);
  const setProduct = usePostStore((state) => state.setProduct);
  const clearForm = usePostStore((state) => state.clearForm);
  const createPost = usePostStore((state) => state.createPost);
  const editPost = usePostStore((state) => state.editPost);
  const fetchFilteredProducts = usePostStore((state) => state.fetchFilteredProducts);

  const [media_urls, setMedia_urls] = useState<Array<any>>([]);

  useEffect(() => {
    console.log('media_info', media_info);
    if (media_info.length) setMedia_urls(media_info);
  }, [media_info]);

  useEffect(() => {
    return () => {
      clearForm();
    };
  }, [clearForm]);

  useEffect(() => {
    if (brand?.id && category?.id) {
      const _start = 0;
      const _end = 9;
      fetchFilteredProducts({ _start, _end, brand: brand.id, category: category.id });
    }
  }, [brand, category, fetchFilteredProducts, media_urls]);

  const isEdit = !!id;
  const showNext = !isEdit && value !== 1;

  const brandOptions = useMemo(() => {
    return brandService?.map((brand) => ({
      id: brand.id,
      label: brand.name,
    }));
  }, [brandService]);

  const handleFormChange = useCallback(
    (item: string) => {
      return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ item, value: e.target.value });
        if (columnError[item]) setColumnError((prev) => ({ ...prev, [item]: false }));
      };
    },
    [setForm, columnError]
  );

  const handleBrandChange = useCallback(
    (brand: TSelectOptions | null) => {
      setBrand(brand);
      if (columnError.brand) setColumnError((prev) => ({ ...prev, brand: false }));
    },
    [setBrand, columnError.brand]
  );

  const handleCategoryChange = useCallback(
    (category: TSelectOptions | null) => {
      setCategory(category);
      if (columnError.categoryId) setColumnError((prev) => ({ ...prev, categoryId: false }));
    },
    [setCategory, columnError.categoryId]
  );

  const handleProductChange = useCallback(
    (category: TSelectOptions | null) => {
      setProduct(category);
      if (columnError.product) setColumnError((prev) => ({ ...prev, product: false }));
    },
    [setProduct, columnError.product]
  );

  const getTagsId = () => {
    try {
      const hashTagsIdMap = hashTagsList.reduce<{ [key: string]: number }>((acc, curr) => {
        acc[curr.tag] = curr.id;
        return acc;
      }, {});
      return hashTags.map((tag) => hashTagsIdMap[tag]);
    } catch (error) {
      return [];
    }
  };

  const handleSubmit = async () => {
    let mediaArray: Array<any> = [];
    if (media_info.length) {
      media_info.forEach((item: any) => {
        if (item.is_video) mediaArray.push({ is_video: 1, media_url: item.media_url });
        if (!item.is_video) mediaArray.push({ is_video: 0, media_url: item.media_url });
      });

      setMedia_urls(mediaArray);
    }

    // } if (image) media_urls.push({ is_video: 0, media_url: image });
    // if (videoUrl) media_urls.push({ is_video: 1, media_url: videoUrl });

    const submitData = {
      brand: brand?.id,
      categoryId: category?.id,
      product: product?.id,
      description,
      hashTags: getTagsId(),
      media_urls,
      categoryName: category?.label,
    };
    const editSubmitData = {
      id,
      categoryId: category?.id,
      product: product?.id,
      description,
      hashTags: getTagsId(),
      media_urls,
      categoryName: category?.label,
    };
    const invalidColumns = validatePostForm(isEdit ? editSubmitData : submitData, isEdit);
    if (invalidColumns.length) {
      const errors: Record<string, boolean> = {};
      for (const item of invalidColumns) {
        errors[item] = true;
      }
      showSnack('error', 'Please fill the required fields.');
      setColumnError(errors);
      return;
    }
    try {
      const result: any = isEdit ? await editPost(editSubmitData) : await createPost(submitData);
      if (result && typeof result === 'string') {
        showSnack('success', result);
        navigate('/post', { replace: true });
      }
    } catch (error) {
      showSnack('error');
    }
  };

  const formElements = (
    <Stack direction="row" spacing={3} sx={{ my: 2 }}>
      <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
        <FormSelect
          value={brand}
          handleChange={handleBrandChange}
          label="Brand"
          selectId="brandId"
          options={brandOptions}
          error={columnError.brand}
          disabled={disabled || isEdit}
          required
        />
        <FormSelect
          value={category}
          handleChange={handleCategoryChange}
          label="Category"
          selectId="category"
          options={categories}
          error={columnError.categoryId}
          disabled={disabled}
        />
        <FormSelect
          value={product}
          handleChange={handleProductChange}
          label="Product"
          selectId="product"
          options={filteredProductList}
          error={columnError.product}
          disabled={disabled}
        />
        <FormInput
          value={description}
          label="Description"
          inputId="description"
          handleChange={handleFormChange}
          item="description"
          multiline
          required
          disabled={disabled}
          minRows={7}
          maxRows={9}
          error={columnError.description}
        />
      </Stack>
      <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
        <HashTagsSelect
          error={columnError.hashTags}
          disabled={disabled}
          setColumnError={setColumnError}
        />
        <PostTags hashTags={hashTags} disabled={disabled} />
      </Stack>
    </Stack>
  );

  const mediaElements = (
    <PostMedia
      postImage={media_info
        .filter((item: any) => item.is_video === false)
        .map((item: any) => {
          return item.media_url;
        })}
      postVideo={media_info
        .filter((item: any) => item.is_video === true)
        .map((item: any) => {
          return item.media_url;
        })}
      columnError={columnError}
      setColumnError={setColumnError}
      disabled={disabled}
    />
  );

  const formActions = (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ py: 2 }}>
      {isEdit ? (
        <Stack direction="row" alignItems="center" sx={{ marginRight: 5 }}>
          <Typography variant="caption" color="GrayText">
            Disable
          </Typography>
          <PostSwitch id={Number(id)} isActive={Boolean(postStatus)} />
          <Typography variant="caption" color="GrayText">
            Enable
          </Typography>
        </Stack>
      ) : null}
      <Button
        variant="text"
        color="error"
        onClick={() => {
          navigate('/post', { replace: true });
        }}
        size="large"
      >
        Cancel
      </Button>
      {disabled ? (
        <Button variant="outlined" size="large" onClick={() => setDisabled(false)}>
          Edit
        </Button>
      ) : showNext ? (
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            setValue((prev) => prev + 1);
          }}
        >
          Next
        </Button>
      ) : (
        <Button variant="outlined" size="large" onClick={handleSubmit}>
          {isEdit ? 'Save' : 'Submit'}
        </Button>
      )}
    </Stack>
  );

  const tabElements = [
    { label: 'Post', element: formElements },
    { label: 'Media', element: mediaElements },
  ];

  return (
    <Box sx={{ padding: 3, mx: 2 }}>
      <FormTabs
        elements={tabElements}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
        formActions={formActions}
      />
    </Box>
  );
};
