import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { PostForm } from '../../components/PostForm';
import { usePostStore } from '../../state/stores/posts.store';
import { showSnack } from '../../state/stores/snackBar.store';

export const EditPost = () => {
  const params = useParams();
  const location: any = useLocation();
  const navigate = useNavigate();
  const fetchPostDetails = usePostStore((state) => state.fetchPostDetails);
  const rowId = location.state?.rowId;
  const isDisabled = location.state?.isDisabled;

  useEffect(() => {
    if (rowId) {
      fetchPostDetails(rowId).then((message) => {
        if (message) {
          showSnack('error', message);
          navigate('/post', { replace: true });
        }
      });
    }
  }, [fetchPostDetails, rowId, navigate]);

  useEffect(() => {
    if (!rowId) {
      navigate('/post', { replace: true, state: {} });
    }
  }, [navigate, rowId]);

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Edit Post" />
      <PostForm id={params.postId} isDisabled={isDisabled} />
    </Box>
  );
};
