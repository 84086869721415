import { AlertColor } from '@mui/material';
import create from 'zustand';

export const useSnackBarStore = create<{
  open: boolean;
  severity: AlertColor;
  message: string | undefined;
}>(() => ({
  open: false,
  severity: 'success',
  message: '',
}));

export const showSnack = (severity: AlertColor, message?: string) => {
  useSnackBarStore.setState({
    open: true,
    severity,
    message: message || 'Something went wrong. Please try again later.',
  });
};

export const hideSnack = () => {
  const { message, severity } = useSnackBarStore.getState();
  useSnackBarStore.setState({ open: false, message, severity });
};
