import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, ReactNode } from 'react';

type TTab = {
  label: string | JSX.Element;
  element: JSX.Element | null;
};

type TFormTabsProps = {
  elements: TTab[];
  value: number;
  formActions?: JSX.Element | null;
  onChange: (e: React.SyntheticEvent<Element, Event>, value: any) => void;
};

type TTabPanelProps = {
  children: ReactNode;
  value: number;
  index: number;
};

function TabPanel(props: TTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const FormTabs = ({ value, onChange, elements = [], formActions }: TFormTabsProps) => {
  return (
    <Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={onChange}
          aria-label="tabs"
        >
          {elements.map((element, index) => (
            <Tab key={`${index}-tab`} label={element.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        <Box>{formActions}</Box>
      </Box>
      {elements.map((element, index) => (
        <TabPanel key={`${index}-tabPanel`} value={value} index={index}>
          {element.element}
        </TabPanel>
      ))}
    </Fragment>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
