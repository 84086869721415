import { Box, Button, Divider, Stack, Typography, Switch } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductStore } from '../../state/stores/products.store';
import { FormSelect } from './FormSelect';
import { FormInput } from './ProductFormInput';
import { TSelectOptions } from './productForm.types';
import { ProductPriceTable } from './ProductPriceTable';
import { validateProductForm } from './productForm.utils';
import { showSnack } from '../../state/stores/snackBar.store';
import { InventoryTable } from './InventoryTable';
import { FormTabs } from '../FormTabs';
import { useBrandService } from '../../state/stores/brands.store';
import { ProductMultimedia } from './ProductMultimedia';
import { ProductSwitch } from '../DataTable/ProductsDataCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HashTagsSelect } from './HashTagsSelect';
import { PostTags } from './PostTags';

export const ProductForm = ({ id, isDisabled }: { id?: string; isDisabled?: boolean }) => {
  const [columnError, setColumnError] = useState<Record<string, boolean>>({});
  const [disabled, setDisabled] = useState(() => isDisabled);
  const [value, setValue] = useState(0);
  const name = useProductStore((state) => state.form.name);
  const skuId = useProductStore((state) => state.form.skuId);
  const category = useProductStore((state) => state.form.category);
  const subCategory = useProductStore((state) => state.form.subCategory);
  const brand = useProductStore((state) => state.form.brand);
  const longDescription = useProductStore((state) => state.form.longDescription);
  const shortDescription = useProductStore((state) => state.form.shortDescription);
  const howToUse = useProductStore((state) => state.form.howToUse);
  const ingredients = useProductStore((state) => state.form.ingredients);
  const weight = useProductStore((state) => state.form.weight);
  const dimension = useProductStore((state) => state.form.dimension);
  const soldBy = useProductStore((state) => state.form.soldBy);
  const shippedFrom = useProductStore((state) => state.form.shippedFrom);
  const images = useProductStore((state) => state.form.images);
  const preFilledImages = useProductStore((state) => state.form.preFilledImages);
  const countryOfOrigin = useProductStore((state) => state.form.countryOfOrigin);
  const hashTags = useProductStore((state) => state.form.hashtags);
  const categoryOptions = useProductStore((state) => state.categories);
  const subCategories = useProductStore((state) => state.subCategories);
  const brandService = useBrandService((state) => state.brands);
  const productStatus = useProductStore((state) => state.productStatus);
  const hashTagsList = useProductStore((state) => state.hashTagsList);
  const allowReturn = useProductStore((state) => state.form.allow_return);
  const setForm = useProductStore((state) => state.setForm);
  const setCategory = useProductStore((state) => state.setCategory);
  const setSubcategory = useProductStore((state) => state.setSubcategory);
  const setBrand = useProductStore((state) => state.setBrand);
  const setAllowReturn = useProductStore((state) => state.setAllowReturn);
  const clearForm = useProductStore((state) => state.clearForm);
  const fetchCategories = useProductStore((state) => state.fetchCategories);
  const fetchSubCategories = useProductStore((state) => state.fetchSubCategories);
  const createProduct = useProductStore((state) => state.createProduct);
  const editProduct = useProductStore((state) => state.editProduct);
  const navigate = useNavigate();
  const isEdit = !!id;

  const showNext = !isEdit && value !== 1;

  const brandOptions = useMemo(() => {
    return brandService?.map((brand) => ({
      id: brand.id,
      label: brand.name,
    }));
  }, [brandService]);

  useEffect(() => {
    if (!id) {
      fetchCategories();
    }
    return () => {
      clearForm();
    };
  }, [id, clearForm, fetchCategories]);

  const handleFormChange = useCallback(
    (item: string) => {
      return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ item, value: e.target.value });
        if (columnError[item]) setColumnError((prev) => ({ ...prev, [item]: false }));
      };
    },
    [setForm, columnError]
  );

  const handleCategoryChange = useCallback(
    (category: TSelectOptions | null) => {
      setCategory(category);
      if (columnError.category) setColumnError((prev) => ({ ...prev, category: false }));
      if (category?.id) {
        fetchSubCategories(category.id);
      }
    },
    [setCategory, fetchSubCategories, columnError.category]
  );

  const handleSubcategoryChange = useCallback(
    (subCategory: TSelectOptions | null) => {
      setSubcategory(subCategory);
      if (columnError.subCategory) setColumnError((prev) => ({ ...prev, subCategory: false }));
    },
    [setSubcategory, columnError.subCategory]
  );

  const handleBrandChange = useCallback(
    (category: TSelectOptions | null) => {
      setBrand(category);
      if (columnError.brand) setColumnError((prev) => ({ ...prev, brand: false }));
    },
    [setBrand, columnError.brand]
  );
  const getTagsId = () => {
    try {
      const hashTagsIdMap = hashTagsList.reduce<{ [key: string]: number }>((acc, curr) => {
        acc[curr.tag] = curr.id;
        return acc;
      }, {});
      return hashTags.map((tag) => hashTagsIdMap[tag]);
    } catch (error) {
      return [];
    }
  };
  const handleSubmit = async () => {
    const submitData = {
      name,
      skuId,
      category: category?.id,
      subcategory_id: subCategory?.id,
      longDescription,
      shortDescription,
      howToUse,
      ingredients,
      weight,
      dimension,
      soldBy,
      shippedFrom,
      images: images as string[],
      brandId: brand?.id,
      countryOfOrigin,
      hashTags: getTagsId(),
      allow_return: allowReturn,
    };
    const editSubmitData = {
      id,
      name,
      category: category?.id,
      subcategory_id: subCategory?.id,
      longDescription,
      shortDescription,
      howToUse,
      ingredients,
      weight,
      dimension,
      soldBy,
      shippedFrom,
      images: (preFilledImages ? [...preFilledImages, ...images] : images) as string[],
      brandId: brand?.id,
      countryOfOrigin,
      hashTags: getTagsId(),
      allow_return: allowReturn,
    };
    const invalidColumns = validateProductForm(isEdit ? editSubmitData : submitData, isEdit);
    if (invalidColumns.length) {
      const errors: Record<string, boolean> = {};
      for (const item of invalidColumns) {
        errors[item] = true;
      }
      showSnack('error', 'Please fill the required fields.');
      setColumnError(errors);
      return;
    }
    try {
      const result: any = isEdit
        ? await editProduct(editSubmitData)
        : await createProduct(submitData);
      if (result && typeof result === 'string') {
        showSnack('success', result);
        navigate('/products', { replace: true });
      }
    } catch (error) {
      showSnack('error');
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowReturn(event.target.checked);
  };

  const primaryProductForm = (
    <Stack
      component="form"
      spacing={3}
      sx={{
        mx: 'auto',
        mt: 4,
      }}
      autoComplete="off"
      direction="row"
    >
      <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
        <Stack direction="row">
          <Stack spacing={3} sx={{ flex: '1 1 100%', marginRight: 2 }}>
            <FormInput
              value={name}
              label="Name"
              inputId="product-name"
              handleChange={handleFormChange}
              item="name"
              required
              error={columnError.name}
              disabled={disabled}
            />
          </Stack>
          <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
            <FormInput
              value={skuId}
              label="Sku Id"
              inputId="sku-id"
              handleChange={handleFormChange}
              item="skuId"
              required
              error={columnError.skuId}
              disabled={disabled || isEdit}
            />
          </Stack>
        </Stack>
        <Stack spacing={2} direction="row">
          <FormSelect
            value={brand}
            handleChange={handleBrandChange}
            label="Brand"
            selectId="brandId"
            options={brandOptions}
            error={columnError.brandId}
            disabled={disabled || isEdit}
            required
          />
          <FormSelect
            value={category}
            handleChange={handleCategoryChange}
            label="Category"
            selectId="product-category"
            options={categoryOptions}
            error={columnError.category}
            disabled={disabled}
            required
          />
        </Stack>
        <FormSelect
          value={subCategory}
          handleChange={handleSubcategoryChange}
          label="Sub Category"
          selectId="product-sub-category"
          options={subCategories}
          error={columnError.subCategory}
          disabled={disabled}
        />
        <FormInput
          value={shortDescription}
          label="Short Description"
          inputId="short-description"
          handleChange={handleFormChange}
          item="shortDescription"
          required
          error={columnError.shortDescription}
          disabled={disabled}
        />
        <FormInput
          value={longDescription}
          handleChange={handleFormChange}
          item="longDescription"
          inputId="long-description"
          label="Description"
          multiline
          required
          error={columnError.longDescription}
          disabled={disabled}
        />
        <FormInput
          value={ingredients}
          label="Ingredients"
          inputId="ingredients"
          handleChange={handleFormChange}
          item="ingredients"
          required
          error={columnError.ingredients}
          disabled={disabled}
        />
      </Stack>
      <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
        <FormInput
          value={howToUse}
          label="How To Use"
          inputId="how-to-use"
          handleChange={handleFormChange}
          item="howToUse"
          multiline
          required
          error={columnError.howToUse}
          disabled={disabled}
        />
        <Stack direction="row">
          <Stack spacing={3} sx={{ flex: '1 1 100%', marginRight: 2 }}>
            <FormInput
              value={weight}
              label="Weight"
              inputId="weight"
              handleChange={handleFormChange}
              item="weight"
              disabled={disabled}
            />
            <FormInput
              value={dimension}
              label="Dimension"
              inputId="dimension"
              handleChange={handleFormChange}
              item="dimension"
              disabled={disabled}
              placeHolder="L X B X H"
            />
          </Stack>
          <Divider />
          <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
            <FormInput
              value={soldBy}
              label="Sold By"
              inputId="soldBy"
              handleChange={handleFormChange}
              item="soldBy"
              error={columnError.soldBy}
              disabled={disabled}
              required
            />
            <FormInput
              value={shippedFrom}
              label="Shipped From"
              inputId="shipped-from"
              handleChange={handleFormChange}
              item="shippedFrom"
              error={columnError.shippedFrom}
              disabled={disabled}
              required
            />
          </Stack>
        </Stack>
        <FormInput
          value={countryOfOrigin}
          label="Country of origin"
          inputId="country-of-origin"
          handleChange={handleFormChange}
          item="countryOfOrigin"
          error={columnError.countryOfOrigin}
          disabled={disabled}
          required
        />
        <Stack direction="row" justifyContent="flex-start" alignItems="self-start" spacing={2}>
          <FormControlLabel
            control={<Switch checked={allowReturn!} onChange={handleChange} />}
            labelPlacement="start"
            disabled={disabled}
            label="Allow Product Return"
          />
        </Stack>
        <Stack spacing={3} sx={{ flex: '1 1 100%' }}>
          <HashTagsSelect
            error={columnError.hashTags}
            disabled={disabled}
            setColumnError={setColumnError}
          />
          <PostTags hashTags={hashTags} disabled={disabled} />
        </Stack>
      </Stack>
    </Stack>
  );

  const inventoryForm = isEdit ? <InventoryTable id={id} /> : null;

  const productPriceForm = isEdit ? <ProductPriceTable id={id} /> : null;

  const formActions = (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ py: 2 }}>
      {isEdit ? (
        <Stack direction="row" alignItems="center" sx={{ marginRight: 5 }}>
          <Typography variant="caption" color="GrayText">
            Disable
          </Typography>
          <ProductSwitch id={Number(id)} isActive={Boolean(productStatus)} />
          <Typography variant="caption" color="GrayText">
            Enable
          </Typography>
        </Stack>
      ) : null}
      <Button
        variant="text"
        color="error"
        onClick={() => {
          navigate('/products', { replace: true });
        }}
        size="large"
      >
        Cancel
      </Button>
      {disabled ? (
        <Button variant="outlined" size="large" onClick={() => setDisabled(false)}>
          Edit
        </Button>
      ) : showNext ? (
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            setValue((prev) => prev + 1);
          }}
        >
          Next
        </Button>
      ) : (
        <Button variant="outlined" size="large" onClick={handleSubmit}>
          {isEdit ? 'Save' : 'Submit'}
        </Button>
      )}
    </Stack>
  );

  const productMedia = (
    <ProductMultimedia
      images={images}
      preFilledImages={preFilledImages}
      columnError={columnError}
      setColumnError={setColumnError}
      disabled={disabled}
    />
  );

  const createTabElemets = [
    {
      label: 'Product',
      element: primaryProductForm,
    },
    {
      label: 'Media',
      element: productMedia,
    },
  ];

  const editTabElements = [
    {
      label: 'Product',
      element: primaryProductForm,
    },
    {
      label: 'Media',
      element: productMedia,
    },
    {
      label: 'Product Inventory',
      element: inventoryForm,
    },
    {
      label: 'Product Variant Price',
      element: productPriceForm,
    },
  ];

  const tabElements = isEdit ? editTabElements : createTabElemets;

  return (
    <Box sx={{ padding: 2, overflow: 'auto', mx: 3 }}>
      <FormTabs
        elements={tabElements}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
        formActions={formActions}
      />
    </Box>
  );
};
