import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Navigator } from './Navigator';
import { AppSnackBar } from './components/AppSnackBar';
import { AppLoader } from './components/AppLoader';
import { theme } from './theme';
import { useEffect } from 'react';
import { getProfile } from './state/actions/auth.action';
import { useQuery } from './hooks';

function App() {
  const { exec, ready } = useQuery();
  useEffect(() => {
    exec(() => getProfile());
  }, []);
  if (!ready) return <AppLoader loading={!ready} />;
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Navigator />
        <AppSnackBar />
        <AppLoader />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
