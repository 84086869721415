import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useOrdersStore } from '../../state/stores/orders.store';
import { showSnack } from '../../state/stores/snackBar.store';
import { useWarehouseStore } from '../../state/stores/warehouse.store';
import { FormSelect } from '../ProductForm/FormSelect';
import { TSelectOptions } from '../ProductForm/productForm.types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const PickupRequestModal = ({
  approvedSelected,
  handleToggle,
}: {
  approvedSelected: number[];
  handleToggle: () => void;
}) => {
  const [selected, setSelected] = useState<TSelectOptions | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const modalOpen = useOrdersStore((state) => state.pickupRequestModal.open);
  const storeList = useWarehouseStore((state) => state.store);
  const loading = useOrdersStore((state) => state.loading);

  const setPickupRequestModal = useOrdersStore((state) => state.setPickupRequestModal);
  const createPickupRequest = useOrdersStore((state) => state.createPickupRequest);

  const storeSelectList = useMemo(() => {
    return (storeList || [])?.map((store) => ({ label: store.registered_name, id: store.id }));
  }, [storeList]);

  const handleModal = () => {
    setPickupRequestModal({ open: false, data: null });
    setSelected(null);
  };

  const handleStoreChange = (store: TSelectOptions | null) => {
    setSelected(store);
  };

  const handlePickupRequest = () => {
    if (!selected) {
      showSnack('error', 'Please select a store to create a pickup request.');
      return;
    }
    try {
      const dateFormat = `${startDate?.getFullYear()}-${
        startDate?.getMonth() + 1
      }-${startDate?.getDate()}`;
      const timeFormat = `${startTime?.getHours()}:${startTime?.getMinutes()}:${startTime?.getSeconds()}`;
      createPickupRequest(approvedSelected, '' + selected?.id, dateFormat, timeFormat).then(() => {
        handleModal();
        handleToggle();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog id="pickup-modal" onClose={handleModal} open={modalOpen} fullWidth>
      <DialogTitle>Select Store</DialogTitle>
      <DialogContent dividers>
        <Stack>
          <FormSelect
            value={selected}
            handleChange={handleStoreChange}
            label="Stores"
            selectId="store"
            options={storeSelectList}
            required
          />
          <Stack direction="row" spacing={4} margin="10px 0">
            <Box width="100%">
              <Typography margin="2px 0">Pickup Date</Typography>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                className="date-picker"
                portalId="pickup-modal"
              />
            </Box>
            <Box width="100%">
              <Typography margin="2px 0">Pickup Time</Typography>
              <DatePicker
                selected={startTime}
                onChange={(time) => {
                  setStartTime(time);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm:ss"
                timeFormat="HH:mm"
                className="date-picker"
                portalId="pickup-modal"
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePickupRequest}
          disabled={loading}
          endIcon={<CircularProgress size={20} sx={{ display: loading ? 'flex' : 'none' }} />}
        >
          Create Pickup Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};
