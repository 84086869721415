import axios from 'axios';
import { axiosInstance, BASE_URL } from '../axios';
import { showSnack } from '../stores/snackBar.store';

export const fetchProductsService = (
  _start: number,
  _end: number,
  brand?: number,
  category?: number
): any => {
  return axiosInstance
    .get(
      `/vendor/product/list?_start=${_start}&_end=${_end}${brand ? `&brand_id=${brand}` : ''}${
        category ? `&category_id=${category}` : ''
      }`
    )
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const fetchPostProductService = (
  _start: number,
  _end: number,
  brand?: number,
  category?: number
): any => {
  return axiosInstance
    .get(
      `/vendor/product/total/list?${brand ? `&brand_id=${brand}` : ''}${
        category ? `&category_id=${category}` : ''
      }`
    )
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const productCreateService = (formData: any): any => {
  const {
    images,
    name,
    longDescription,
    shortDescription,
    howToUse,
    ingredients,
    weight,
    dimension,
    soldBy,
    shippedFrom,
    skuId,
    category,
    subcategory_id,
    brandId,
    countryOfOrigin,
    allow_return,
    hashTags,
  } = formData;
  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/create',
    data: {
      brand_id: brandId,
      images_list: images,
      name,
      long_description: longDescription,
      short_description: shortDescription,
      how_to_use: howToUse,
      ingredients,
      weight,
      dimension,
      sold_by: soldBy,
      shipped_from: shippedFrom,
      sku_id: skuId,
      category_id: category,
      subcategory_id,
      country_of_origin: countryOfOrigin,
      allow_return: allow_return,
      tags: hashTags,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const productEditService = (formData: any): any => {
  const {
    id,
    images,
    name,
    longDescription,
    shortDescription,
    howToUse,
    ingredients,
    weight,
    dimension,
    soldBy,
    shippedFrom,
    category,
    subcategory_id,
    brandId,
    countryOfOrigin,
    allow_return,
    hashTags,
  } = formData;

  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/edit',
    data: {
      id,
      images_list: images,
      name,
      long_description: longDescription,
      short_description: shortDescription,
      how_to_use: howToUse,
      ingredients,
      weight,
      dimension,
      sold_by: soldBy,
      shipped_from: shippedFrom,
      category_id: category,
      subcategory_id,
      brand_id: brandId,
      country_of_origin: countryOfOrigin,
      allow_return,
      tags: hashTags,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const productDetailService = (id: number): any => {
  return axiosInstance
    .get(`/vendor/product/details?id=${id}`)
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const productDeleteService = (id: number): any => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/delete',
    data: {
      id,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const fetchCategoriesService = (category_id?: number): any => {
  return axios
    .get(
      category_id
        ? `${BASE_URL}/api/categories?category_id=${category_id}`
        : `${BASE_URL}/api/categories`
    )
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const imageUploadService = (imageData: any): any => {
  return axiosInstance({
    method: 'POST',

    url: '/upload/image',
    data: imageData,
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const createProductPriceService = (formData: any): any => {
  const { id, productQty, sellingPrice, offerPrice, taxPercentage } = formData;
  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/price/create',
    data: {
      product_id: id,
      unit_quantity: productQty,
      selling_price: sellingPrice,
      offer_price: offerPrice,
      tax_percentage: taxPercentage,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const editProductPriceService = (formData: any): any => {
  const { id, productQty, sellingPrice, offerPrice, productId, taxPercentage } = formData;
  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/price/edit',
    data: {
      id: id,
      product_id: productId,
      unit_quantity: productQty,
      selling_price: sellingPrice,
      offer_price: offerPrice,
      tax_percentage: taxPercentage,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};
export const deleteProductPriceService = (formData: any): any => {
  const { productId, priceId } = formData;
  console.log('ProductID: ', productId);
  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/price/delete',
    data: {
      product_id: productId,
      price_id: priceId,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const fetchProductPriceService = (id: any): any => {
  return axiosInstance
    .get(`vendor/product/price/list?product_id=${id}`)
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const productInventoryService = (productId: string): any => {
  return axiosInstance
    .get(`/product/inventory/list?product_id=${productId}`)
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const addProductInventoryService = (formData: any): any => {
  const { id, productQty, productUnit, productBatchNumber } = formData;
  return axiosInstance({
    method: 'POST',
    url: '/product/inventory/credit',
    data: {
      product_id: id,
      quantity: productQty,
      product_unit_quantity: productUnit,
      batch_number: productBatchNumber,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const debitProductInventoryService = (formData: any): any => {
  const { id, productQty, productUnit, productBatchNumber, debitReason } = formData;
  return axiosInstance({
    method: 'POST',
    url: '/product/inventory/debit',
    data: {
      product_id: id,
      quantity: productQty,
      product_unit_quantity: productUnit,
      batch_number: productBatchNumber,
      debit_reason: debitReason,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const toggleProductService: any = (productId: number, enable: boolean) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/product/status/change',
    data: {
      product_id: productId,
      enable: +enable,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};
