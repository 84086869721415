import create from 'zustand';

export const useWarehouseStore = create<{
  store: any[];
  loading: boolean;
  error: string | unknown;
}>((set) => ({
  store: [],
  loading: false,
  error: null,
}));

export const setStoreList = (list: Array<any>) => {
  useWarehouseStore.setState({ store: list });
};

export const setWarehouseLoading = (payload: boolean) => {
  useWarehouseStore.setState({ loading: payload });
};
