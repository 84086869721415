import { Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import { usePostStore } from '../../state/stores/posts.store';
import { showSnack } from '../../state/stores/snackBar.store';

export const PostSwitch = ({ isActive, id }: { isActive: boolean; id: number }) => {
  const [checked, setChecked] = useState(() => isActive);

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  return (
    <Switch
      checked={checked}
      inputProps={{ 'aria-label': 'post-enable/disable' }}
      onClick={(e) => {
        e.stopPropagation();
        if (e.isPropagationStopped()) {
          usePostStore
            .getState()
            .togglePost(id, !checked)
            .then((message) => {
              if (message) {
                showSnack('success', message);
                setChecked(!checked);
              }
            })
            .catch(() => {
              showSnack('error', 'Something went wrong. Please try again.');
            });
        }
      }}
    />
  );
};
