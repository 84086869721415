import create from 'zustand';

export const useProfileService = create<{
  profile: any;
  loading: boolean;
  error: string | unknown;
}>((set) => ({
  profile: '',
  loading: false,
  error: null,
}));

export const setProfile = (list: any) => {
  useProfileService.setState({ profile: list });
};

