import { memo } from 'react';
import { Badge, Box, Stack, Typography } from '@mui/material';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { blue } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

type TVideoUploadProps = {
  videos: string[];
  onVideoDrop?:
    | (<T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent
      ) => void)
    | undefined;
  handleRemoveVideo?: () => void;
  error?: boolean;
  disabled?: boolean;
  multi?: boolean;
};

export const VideoUpload = memo(
  ({
    videos,
    onVideoDrop,
    error,
    disabled,
    handleRemoveVideo,
    multi = false,
  }: TVideoUploadProps) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: 'video/mp4',
      multiple: multi,
      onDrop: onVideoDrop,
    });

    const getDisabled = () => {
      if (disabled) {
        return true;
      } else if (videos && videos.length > 0 && !multi) {
        return true;
      } else return false;
    };

    return (
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        {videos && (
          <Box sx={{ minHeight: 140, width: '100%' }}>
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{
                maxHeight: 400,
                overflow: 'hidden',
                p: 2,
                mb: 2,
                height: 300,
              }}
            >
              {videos.length ? (
                videos.map((item: string, index: number) => (
                  <Badge
                    key={`${item}-${index}`}
                    color="primary"
                    badgeContent={<CloseIcon sx={{ fontSize: 12, cursor: 'pointer' }} />}
                    sx={{ m: 1 }}
                    onClick={handleRemoveVideo}
                  >
                    <video width="100%" controls>
                      <source src={item} type="video/mp4"></source>
                    </video>
                  </Badge>
                ))
              ) : (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: '100%',
                  }}
                >
                  <video width="100%" controls>
                    <source type="video/mp4"></source>
                  </video>
                </Stack>
              )}
            </Stack>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: blue[500],
            p: 1,
            width: 200,
            borderRadius: 2,
            mr: 1.5,
            ':hover': {
              backgroundColor: blue[600],
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: disabled ? 'no-drop' : 'pointer',
            }}
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} disabled={getDisabled()} />
            <Typography sx={{ color: 'white' }} fontWeight={600}>
              Select Video
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
);
