import { Autocomplete, TextField } from '@mui/material';
import { TSelectProps } from './productForm.types';

export const FormSelect = ({
  value,
  handleChange,
  options,
  label,
  selectId,
  error,
  required,
  disabled,
  variant = 'filled',
}: TSelectProps) => {
  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        handleChange(newValue);
      }}
      id={selectId}
      options={options}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} variant={variant} error={error} />
      )}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      disabled={disabled}
    />
  );
};
