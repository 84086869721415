import { Button, Chip, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderListData } from '../../components/OrderListing/OrderListData';
import { useQuery } from '../../hooks';
import { fetchCommentList, fetchTicketDetails } from '../../state/actions/support.action';
import ReplyCard from './replyCard';
import WriteReplyModal from './writeReplyModal';

const SupportDetailPage = () => {
  const { id: ticketId } = useParams();

  const { exec, data } = useQuery();
  const { exec: execCommentList, data: commnetListData } = useQuery();

  const [showReplyModal, setShowReplyModal] = useState(false);

  useEffect(() => {
    exec(() => fetchTicketDetails(ticketId || ''));
  }, [ticketId]);

  useEffect(() => {
    if (data) execCommentList(() => fetchCommentList(data.desk_ticket_id || ''));
  }, [data]);

  console.log(commnetListData);
  if (!data) return null;
  return (
    <>
      <Stack padding={6}>
        <Stack
          display={'flex'}
          direction="row"
          justifyContent={'space-between'}
          alignItems="center"
        >
          <Stack>
            <Typography variant="h5" mb={1}>
              <strong>Contact Support</strong>
            </Typography>
            <Typography variant="caption">Support / Tickets / {data.desk_ticket_id}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Chip
              label="1 New Response"
              style={{ color: '#7F39FB', borderColor: '#7F39FB', marginRight: '10px' }}
              variant="outlined"
            />
            <Chip label={data.desk_ticket_status} style={{ backgroundColor: '#F4D096' }} />
          </Stack>
        </Stack>

        <Stack direction={'row'} gap={4} my={4}>
          <Stack flex={1} gap={1}>
            <OrderListData title="Order ID" data={data.orderitem_id} />
            <OrderListData title="Item Name" data={data.product_name} />
            <OrderListData title="Support Ticket" data={data.desk_ticket_id} />
          </Stack>
          <Stack flex={1} gap={1}>
            <OrderListData title="Order Status" data={data.order_status} />
            <OrderListData title="SKU" data={data.sku} />
            <OrderListData title="Category" data={data.category_text} />
          </Stack>
        </Stack>

        <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
          <Stack>
            <Typography variant="caption">Subject Line</Typography>
            <Typography color="#7F39FB" variant="subtitle2">
              {data.subject || '-'}
            </Typography>
          </Stack>
          <Stack>
            <Button variant="contained" color="primary" onClick={() => setShowReplyModal(true)}>
              Write Reply
            </Button>
          </Stack>
        </Stack>
        {commnetListData &&
          commnetListData.comments.map((com: any = {}) => {
            if (typeof com == 'string') return <ReplyCard comment={com} />;
            return <ReplyCard comment={com.comment} date={com.date} email={com.email} />;
          })}
        {/* <ReplyCard /> */}
      </Stack>
      <WriteReplyModal
        open={showReplyModal}
        onClose={() => {
          setShowReplyModal(false);
          if (data) execCommentList(() => fetchCommentList(data.desk_ticket_id || ''));
        }}
        data={data}
      />
    </>
  );
};

export default SupportDetailPage;
