import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Header } from '../../components/Header';
import { ProductForm } from '../../components/ProductForm';
import { fetchBrands } from '../../state/actions/brands.actions';
import { useProductStore } from '../../state/stores/products.store';

export const CreateProduct = () => {
  const fetchHashTags = useProductStore((state) => state.fetchHashTags);

  useEffect(() => {
    fetchHashTags();
    fetchBrands();
  }, [fetchHashTags]);

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Create Product" />
      <ProductForm />
    </Box>
  );
};
