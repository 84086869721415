import create from 'zustand';

export const useAuthStore = create<{
  isLoggedIn: boolean;
  user: null | Record<string, any>;
}>(() => ({
  isLoggedIn: false,
  user: null,
}));

export const setIsLoggedIn = (isLoggedIn: boolean) => {
  useAuthStore.setState({ isLoggedIn });
};

export const setUser = (user: null | Record<string, any>) => {
  useAuthStore.setState({ user });
};

// if (localStorage.getItem('user_data')) {
//  ` const user = localStorage.getItem('user_data') || JSON.stringify({});`
//   setIsLoggedIn(true);
//   setUser(JSON.parse(user));
// }
