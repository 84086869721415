import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AppLoader } from '../../components/AppLoader';
import { THeaderCell } from '../../components/DataTable/dataTable.types';
import { Header } from '../../components/Header';
import { Listings } from '../../components/Listings';
import { useQuery } from '../../hooks';
import { fetchBrands } from '../../state/actions/brands.actions';
import { useBrandService } from '../../state/stores/brands.store';

export const BrandHeaders = [
  { id: 'logo', name: 'Logo' },
  { id: 'name', name: 'Name' },
  { id: 'description', name: 'Description' },
  { id: 'action', name: 'Actions' },
];

export const Brands = () => {
  const { loading, exec } = useQuery();
  const { brands } = useBrandService();
  const [page, setPage] = useState(0);

  const brandHeaderCells: THeaderCell[] = BrandHeaders.map((brand) => {
    return {
      id: brand.id,
      numeric: false,
      disablePadding: false,
      label: brand.name,
    };
  });

  useEffect(() => {
    exec(() => fetchBrands());
  }, []);
  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Brands" />
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Listings
          productHeaderCells={brandHeaderCells}
          rows={brands}
          type="brand"
          page={page}
          setPage={setPage}
          totalCount={brands.length}
          showCheckBoxes={false}
        />
      )}
    </Box>
  );
};
