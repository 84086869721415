import { Box, Divider, Stack } from '@mui/material';
import { GridColumns, GridSortModel } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useProductStore } from '../../state/stores/products.store';
import { CustomDataGrid } from '../DataGrid';
import { NoData } from '../DataTable/NoData';
import { AddInventoryModal } from './AddInventoryModal';

export const InventoryTable = ({ id }: { id: string }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);
  const inventoryData = useProductStore((state) => state.inventory);
  const fetchInventory = useProductStore((state) => state.fetchInventory);

  useEffect(() => {
    fetchInventory(id);
  }, [fetchInventory, id]);

  const inventoryColumns: GridColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 90,
      },
      {
        field: 'product_unit_quantity',
        headerName: 'Product Variant',
        width: 180,
      },
      {
        field: 'inventory_type',
        headerName: 'Inventory Type',
        width: 180,
      },
      {
        field: 'batch_number',
        headerName: 'Batch Number',
        width: 180,
      },
      {
        field: 'debit_reason',
        headerName: 'Debit Reason',
        width: 180,
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        type: 'number',
        width: 90,
      },
    ],
    []
  );

  return (
    <Stack spacing={2} direction="row" sx={{ mt: 4 }}>
      <Box sx={{ flex: '1  100%' }}>
        {inventoryData?.length ? (
          <CustomDataGrid
            columns={inventoryColumns}
            rows={inventoryData}
            sortModel={sortModel}
            setSortModel={setSortModel}
            disableColumnMenu={false}
          />
        ) : (
          <NoData isSmall={true} title="No inventory available." />
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ flex: '1 2 100%' }}>
        <AddInventoryModal id={id} />
      </Box>
    </Stack>
  );
};
