import create from 'zustand';
import { TSelectOptions } from '../../components/ProductForm/productForm.types';
import {
  TCategory,
  TProducts,
  TFetchProducts,
  TCategoryResponse,
} from '../../pages/ProductsPage/products.utils';
import { fetchBrands } from '../actions/brands.actions';
import {
  addProductInventoryService,
  createProductPriceService,
  debitProductInventoryService,
  deleteProductPriceService,
  editProductPriceService,
  fetchCategoriesService,
  fetchProductPriceService,
  fetchProductsService,
  imageUploadService,
  productCreateService,
  productDeleteService,
  productDetailService,
  productEditService,
  productInventoryService,
  toggleProductService,
} from '../actions/products.actions';
import { createHashTagsService, getHashTagsService } from '../actions/posts.action';
type TProductForm = {
  name: string;
  shortDescription: string;
  longDescription: string;
  howToUse: string;
  skuId: string;
  category: TSelectOptions | null;
  subCategory: TSelectOptions | null;
  brand?: TSelectOptions | null;
  images: string[];
  preFilledImages?: string[];
  ingredients: string;
  weight: string;
  dimension: string;
  soldBy: string;
  shippedFrom: string;
  countryOfOrigin: string;
  hashtags: string[];
  allow_return: boolean | null;
};

type TImageUploadResponse = {
  img_str: string;
};

type TInventoryForm = {
  unit: string;
  quantity: string;
  batchNumber: string;
  debitReason: string;
};

type TPriceForm = {
  quantity: string;
  sellingPrice: string;
  offerPrice: string;
  taxPercentage: string;
};

type TProductPriceList = {
  offer_price: string;
  product_id?: number;
  selling_price: string;
  unit_quantity: string;
  taxPercentage: string;
};

type TPriceModalForm = {
  open?: boolean;
  priceId?: number;
  extra?: TProductPriceList | undefined;
};

type TInventoryModalForm = {
  open: boolean;
  productId?: number;
};
type THashtagsList = {
  id: number;
  tag: string;
};
type TInventory = {
  batch_number: string;
  id: number;
  inventory_type: string;
  order_uid: string;
  product_id: number;
  product_unit_quantity: string;
  quantity: number;
  debit_reason: string;
};

const formInitialState: TProductForm = {
  name: '',
  shortDescription: '',
  longDescription: '',
  howToUse: '',
  skuId: '',
  category: null,
  subCategory: null,
  brand: null,
  ingredients: '',
  weight: '',
  dimension: '',
  soldBy: '',
  shippedFrom: '',
  images: [],
  preFilledImages: [],
  countryOfOrigin: '',
  hashtags: [],
  allow_return: false,
};

const initialInventoryForm: TInventoryForm = {
  quantity: '',
  unit: '',
  batchNumber: '',
  debitReason: '',
};

const initialPriceForm: TPriceForm = {
  quantity: '',
  sellingPrice: '',
  offerPrice: '',
  taxPercentage: '',
};

export const useProductStore = create<{
  products: TProducts[];
  form: TProductForm;
  hashTagsList: THashtagsList[];
  inventoryForm: TInventoryForm;
  priceForm: TPriceForm;
  loading: boolean;
  categories: TSelectOptions[];
  subCategories: TSelectOptions[];
  error: string | unknown;
  totalProducts: number;
  productPriceList: TProductPriceList[];
  priceModal: TPriceModalForm;
  inventory: TInventory[];
  inventoryModal: TInventoryModalForm;
  productStatus: boolean | null;
  allow_return: boolean | null;
  setForm: (payload: { item: string; value: string | string[] | File[] }) => void;
  setInventoryForm: (payload: { item: string; value: string }) => void;
  setPriceForm: (payload: { item: string; value: string }) => void;
  setPriceModalForm: (payload: TPriceModalForm) => void;
  setInventoryModalForm: (payload: TInventoryModalForm) => void;
  removePreFilledImage: (image: string, index: number) => void;
  clearForm: () => void;
  clearInventoryForm: () => void;
  clearPriceForm: () => void;
  setCategory: (payload: TSelectOptions | null) => void;
  setSubcategory: (payload: TSelectOptions | null) => void;
  setBrand: (payload: TSelectOptions | null) => void;
  setAllowReturn: (payload: boolean) => void;
  fetchProducts: (_start: number, _end: number) => Promise<void>;
  fetchCategories: () => Promise<TCategory[] | undefined>;
  fetchSubCategories: (categoryId: number) => Promise<TCategory[] | undefined>;
  createProduct: (formData: any) => Promise<void | string>;
  editProduct: (formData: any) => Promise<void | string>;
  fetchProductDetails: (id: number) => Promise<void>;
  deleteProduct: (id: number) => Promise<void | string>;
  addProductInventory: (formData: any) => Promise<void | string>;
  debitProductInventory: (formData: any) => Promise<void | string>;
  createProductPrice: (formData: any) => Promise<void | string>;
  editProductPrice: (formData: any) => Promise<void | string>;
  deleteProductPrice: (formData: any) => Promise<void | string>;
  fetchProductPrices: (id: any) => Promise<void | string>;
  uploadImage: (imageData: any) => Promise<TImageUploadResponse | undefined>;
  fetchInventory: (productId: string) => Promise<void>;
  toggleProduct: (productId: number, enable: boolean) => Promise<string | undefined>;
  fetchHashTags: () => Promise<THashtagsList[] | undefined>;
  createHashTags: (tag: string) => Promise<void | number | string>;
  setTag: (payload: string, type: 'add' | 'remove') => void;
}>((set, get) => ({
  products: [],
  loading: false,
  error: null,
  form: formInitialState,
  inventoryForm: initialInventoryForm,
  priceForm: initialPriceForm,
  categories: [],
  subCategories: [],
  totalProducts: 0,
  productPriceList: [],
  priceModal: { open: false, priceId: 0 },
  inventoryModal: { open: false, id: '' },
  inventory: [],
  hashTagsList: [],
  productStatus: null,
  allow_return: null,
  setForm: (payload) => {
    const { item, value } = payload;
    if (Array.isArray(value)) {
      set((state) => {
        if (item === 'images') {
          return {
            form: {
              ...state.form,
              images: [...state.form.images, ...(value as string[])],
            },
          };
        }
        return {
          form: {
            ...state.form,
            [item]: value,
          },
        };
      });
    } else {
      set((state) => {
        return {
          form: {
            ...state.form,
            [item]: value,
          },
        };
      });
    }
  },
  setInventoryForm: (payload) => {
    const { item, value } = payload;
    set((state) => ({
      inventoryForm: {
        ...state.inventoryForm,
        [item]: value,
      },
    }));
  },
  setPriceForm: (payload) => {
    const { item, value } = payload;
    set((state) => ({
      priceForm: {
        ...state.priceForm,
        [item]: value,
      },
    }));
  },
  setPriceModalForm: (payload) => {
    const { open, priceId, extra } = payload;
    if (priceId && extra) {
      set({
        priceModal: {
          open,
          priceId,
        },
        priceForm: {
          offerPrice: extra.offer_price,
          quantity: extra.unit_quantity,
          sellingPrice: extra.selling_price,
          taxPercentage: extra.taxPercentage,
        },
      });
    } else {
      set({
        priceModal: {
          open: payload.open,
          priceId: payload.priceId,
        },
      });
    }
  },
  setInventoryModalForm: (payload) => {
    const { open, productId } = payload;
    set({
      inventoryModal: {
        open,
        productId,
      },
    });
  },
  setCategory: (payload) => {
    set((state) => {
      return {
        form: {
          ...state.form,
          category: payload,
        },
      };
    });
  },
  setSubcategory: (payload) => {
    set((state) => {
      return {
        form: {
          ...state.form,
          subCategory: payload,
        },
      };
    });
  },
  setBrand: (payload) => {
    set((state) => {
      return {
        form: {
          ...state.form,
          brand: payload,
        },
      };
    });
  },
  setAllowReturn: (payload) => {
    set((state) => {
      return {
        form: {
          ...state.form,
          allow_return: payload,
        },
      };
    });
  },
  removePreFilledImage: (image: string, index: number) => {
    const preFilledImages = get().form.preFilledImages;
    const images = get().form.images;
    const filteredPreFilledImages = preFilledImages?.filter((_, idx) => idx !== index);
    const imagesFilter = images.filter((_, idx) => idx !== index);
    set((state) => {
      return {
        form: {
          ...state.form,
          preFilledImages: filteredPreFilledImages,
          images: imagesFilter,
        },
      };
    });
  },
  clearForm: () => {
    set({
      form: formInitialState,
      inventoryForm: initialInventoryForm,
      priceForm: initialPriceForm,
      productStatus: null,
      allow_return: null,
    });
  },
  clearInventoryForm: () => {
    set({ inventoryForm: initialInventoryForm });
  },
  clearPriceForm: () => {
    set({ priceForm: initialPriceForm, priceModal: { priceId: 0 } });
  },
  fetchProducts: async (_start, _end) => {
    try {
      set({ loading: true });
      const products: TFetchProducts = await fetchProductsService(_start, _end);
      set({ products: products.data, totalProducts: products.totalCount, loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchCategories: async () => {
    try {
      set({ loading: true });
      const { data }: TCategoryResponse = await fetchCategoriesService();
      const mapCategories = data
        ? data.map((category) => ({
            label: category.title,
            id: category.id,
          }))
        : [];
      set({ categories: mapCategories, loading: false });
      return data;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchSubCategories: async (categoryId: number) => {
    try {
      set({ loading: true });
      const { data }: TCategoryResponse = await fetchCategoriesService(categoryId);
      const mapCategories = data
        ? data.map((category) => ({
            label: category.title,
            id: category.id,
          }))
        : [];
      set({ subCategories: mapCategories, loading: false });
      return data;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createProduct: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await productCreateService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  editProduct: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await productEditService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchProductDetails: async (id: number) => {
    try {
      set({ loading: true });
      const categories = await get().fetchCategories();
      const productDetails = await productDetailService(id);
      const brands = await fetchBrands();
      const hashTagsList = await get().fetchHashTags();
      let formCategory: TSelectOptions | null = null;
      let formSubcategory: TSelectOptions | null = null;
      let formBrand: TSelectOptions | null = null;
      let formHashTags: string[] | null = null;
      if (productDetails) {
        const {
          name,
          long_description,
          sku_id,
          images_list,
          short_description,
          how_to_use,
          product_spec: { dimension, shipped_from, sold_by, weight },
          ingredients,
          product_price_list,
          brand_id,
          country_of_origin,
          is_active,
          hashtags,
          allow_return,
        } = productDetails;

        if (categories) {
          const filteredCategory = categories
            .filter((category) => category.id === productDetails?.category_id)
            ?.map((category) => ({
              label: category.title,
              id: category.id,
            }))?.[0];
          if (filteredCategory) formCategory = filteredCategory;
        }
        if (formCategory) {
          const subCategories = await get().fetchSubCategories(formCategory.id);
          const filteredSubcategory = subCategories
            ?.filter((category) => category.id === productDetails?.subcategory_id)
            ?.map((category) => ({
              label: category.title,
              id: category.id,
            }))?.[0];
          if (filteredSubcategory) formSubcategory = filteredSubcategory;
        }
        if (brands) {
          const filteredBrand = brands
            .filter((brand: any) => brand.id === brand_id)
            .map((brand: any) => ({
              label: brand.name,
              id: brand.id,
            }))[0];
          if (filteredBrand) formBrand = filteredBrand;
        }
        if (hashTagsList) {
          formHashTags = hashTagsList
            ?.filter((tag) => hashtags?.includes(tag.id))
            ?.map((tag) => tag.tag);
        }
        set({
          form: {
            name: name,
            longDescription: long_description || '',
            shortDescription: short_description || '',
            howToUse: how_to_use || '',
            category: formCategory,
            subCategory: formSubcategory,
            skuId: sku_id || '',
            ingredients: ingredients || '',
            weight: weight || '',
            dimension: dimension || '',
            soldBy: sold_by || '',
            shippedFrom: shipped_from || '',
            images: [],
            preFilledImages: images_list || [],
            brand: formBrand,
            countryOfOrigin: country_of_origin,
            hashtags: formHashTags || [],
            allow_return: Boolean(allow_return),
          },
          productPriceList: product_price_list,
          productStatus: Boolean(is_active),
        });
      }
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  deleteProduct: async (id: number) => {
    try {
      set({ loading: true });
      const status = await productDeleteService(id);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  addProductInventory: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await addProductInventoryService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  debitProductInventory: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await debitProductInventoryService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createProductPrice: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await createProductPriceService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  editProductPrice: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await editProductPriceService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  deleteProductPrice: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await deleteProductPriceService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  uploadImage: async (imageData: any) => {
    try {
      set({ loading: true });
      const image: TImageUploadResponse = await imageUploadService(imageData);
      set({ loading: false });
      return image;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchProductPrices: async (id: any) => {
    try {
      set({ loading: true });
      const productsPrices = await fetchProductPriceService(id);
      set({ productPriceList: productsPrices.data, loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchInventory: async (productId: string) => {
    try {
      set({ loading: true });
      const inventory = await productInventoryService(productId);
      set({ inventory: inventory.data, loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  toggleProduct: async (productId: number, enable: boolean) => {
    try {
      set({ loading: true });
      const status = await toggleProductService(productId, enable);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchHashTags: async () => {
    try {
      set({ loading: true });
      const hashTagsList: THashtagsList[] = await getHashTagsService();
      console.log(hashTagsList);
      set({ loading: false, hashTagsList });
      return hashTagsList;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createHashTags: async (tag: string) => {
    try {
      set({ loading: true });
      const status = await createHashTagsService(tag);
      set({ loading: false });
      if (status?.id) {
        let timeout: any;
        timeout = setTimeout(() => {
          get().fetchHashTags();
          clearTimeout(timeout);
        }, 1000);
        return status.id;
      }
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  setTag: (payload, type) => {
    const tags = get().form.hashtags;
    if (type === 'remove') {
      set((state) => ({
        form: {
          ...state.form,
          hashtags: tags.filter((tag) => tag !== payload),
        },
      }));
    } else {
      const addedTag = [...tags, payload];
      const uniqueTags = Array.from(new Set(addedTag));
      set((state) => ({
        form: {
          ...state.form,
          hashtags: uniqueTags,
        },
      }));
    }
  },
}));
