import { useState } from 'react';

export const useQuery = () => {
  const [ready, setReady] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState<null | Object>(null);
  const [data, setData] = useState<null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const exec = (fn: () => Promise<any>) => {
    setLoading(true);
    fn?.()
      .then((res) => {
        setData(res);
        setLoading(false);
        setErr(false);
        setReady(true);
        setSuccess(true);
      })
      .catch((err) => {
        setData(null);
        setLoading(false);
        setErr(err);
        setReady(true);
        setSuccess(false);
      });
  };
  return {
    ready,
    err,
    data,
    loading,
    exec,
    success,
  };
};
