import React, { useState } from 'react';

import { login } from '../../state/actions/auth.action';

import { useQuery, useFormState } from '../../hooks';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Input from '../../components/Input';
import { AppLoader } from '../../components/AppLoader';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ButtonGroup } from '@mui/material';

export const Login: React.FC = (props) => {
  const [isCreator, setIsCreator] = useState(false);
  const navigate = useNavigate();
  const { exec, loading } = useQuery();
  const [formState, onValueChange] = useFormState({
    email: '',
    password: '',
  });

  const handleOnSubmit = (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    exec(() =>
      login(formState).then(() => {
        const lastUrl = localStorage.getItem('last_url');
        if (lastUrl) {
          localStorage.removeItem('last_url');
          navigate(lastUrl);
        }
      })
    );
  };

  if (loading) {
    return <AppLoader loading={loading} />;
  }

  return (
    <div
      style={{
        backgroundImage: 'url(./background.png)',
        display: ' flex',
        justifyContent: 'center',
        width: '100%',
        paddingBottom: '30%',
      }}
    >
      {/* <Container component="main" maxWidth="xs"> */}
      <Card sx={{ minWidth: 275, justifyContent: 'center', marginTop: 10 }}>
        <CardContent>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              <img
                src="/mesky-white.png"
                height={100}
                width={100}
                alt="Mesky"
                className="rounded-xl"
              />
            </div>

            <ButtonGroup variant="outlined" aria-label="outlined  button group">
              <button
                type="button"
                onClick={() => {
                  setIsCreator(true);
                }}
                style={
                  isCreator
                    ? {
                        backgroundColor: '#F9A603',
                        width: 150,
                        height: 40,
                        color: '#FFFFFF',
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                      }
                    : {
                        backgroundColor: '#FFFFFF',
                        width: 150,
                        height: 40,
                        color: '#000000',
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                      }
                }
              >
                Creator
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsCreator(false);
                }}
                style={
                  !isCreator
                    ? {
                        backgroundColor: '#F9A603',
                        width: 150,
                        height: 40,
                        textDecorationColor: '#FFFFFF',
                        color: '#FFFFFF',
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                      }
                    : {
                        backgroundColor: '#FFFFFF',
                        width: 150,
                        height: 40,
                        color: '#000000',
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                      }
                }
              >
                Brand
              </button>
            </ButtonGroup>
            <Box
              component="form"
              onSubmit={handleOnSubmit}
              noValidate
              sx={{ mt: 1, width: '100%' }}
            >
              <Input
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                _onChange={onValueChange}
              />
              <Input
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                _onChange={onValueChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button type="submit" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
              <Box sx={{ w: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {/* </Container> */}
    </div>
  );
};
