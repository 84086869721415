import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { WareHouseForm } from './CreateWareHouse';

export const EditWareHouse = () => {
  const params = useParams();
  const warehouseId = params.warehouseId;
  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Edit Ware House" />
      <WareHouseForm id={warehouseId} />
    </Box>
  );
};
