import { Box, Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormTabs } from '../../components/FormTabs';
import { Header } from '../../components/Header';
import { useOrdersStore } from '../../state/stores/orders.store';
import {
  // PendingOrders,
  ApprovedOrders,
  RejectedOrders,
  CancelledOrders,
  ReturnedOrders,
} from '../../components/OrderListing';
import { CompletedOrders } from '../../components/OrderListing/CompletedOrders';

export const Orders = () => {
  const [tab, setTab] = useState(0);
  const [approvedSelected, setApprovedSelected] = useState<number[]>([]);
  const [returnSelected, setReturnSelected] = useState<number[]>([]);
  const [completeSelected, setCompleteSelected] = useState<number[]>([]);
  const setPickupRequestModal = useOrdersStore((state) => state.setPickupRequestModal);

  const orderTabs = useMemo(() => {
    return [
      {
        label: 'Open',
        element: (
          <ApprovedOrders
            setSelectedRows={setApprovedSelected}
            approvedSelected={approvedSelected}
          />
        ),
      },
      {
        label: 'Cancelled',
        element: <CancelledOrders />,
      },
      {
        label: 'Delivered',
        element: <RejectedOrders />,
      },
      {
        label: 'Returns',
        element: (
          <ReturnedOrders setSelectedRows={setReturnSelected} approvedSelected={returnSelected} />
        ),
      },
      {
        label: 'Completed',
        element: (
          <CompletedOrders
            setSelectedRows={setCompleteSelected}
            approvedSelected={completeSelected}
          />
        ),
      },
    ];
  }, [approvedSelected, completeSelected, returnSelected]);

  const showButton = approvedSelected.length > 0;

  const handlePickupRequestModal = () => {
    setPickupRequestModal({ open: true });
  };

  const createPickupRequestButton = () => {
    return (
      <Button variant="contained" onClick={handlePickupRequestModal} sx={{ mx: 2, width: 200 }}>
        Create Pickup
      </Button>
    );
  };

  return (
    <Box>
      <Header title="Order Listings" />
      <FormTabs
        value={tab}
        onChange={(_, newValue) => {
          setTab(newValue);
        }}
        elements={orderTabs}
        formActions={showButton ? createPickupRequestButton() : null}
      />
    </Box>
  );
};
