import { Avatar, Box, TableCell, Tooltip } from '@mui/material';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { TCell } from './dataTable.types';
import EditIcon from '@mui/icons-material/Edit';
import { blue } from '@mui/material/colors';

export const BrandsDataCell = ({ row, labelId }: { row: TCell; labelId: string }) => {
  const navigate = useNavigate();

  const handleBrandDetails = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, rowId: number) => {
    e.stopPropagation();
    if (e.isPropagationStopped()) {
      navigate(`/brand/edit/${rowId}`, { state: { rowId, isDisabled: false } });
    }
  };
  return (
    <Fragment>
      <TableCell align="center" padding="none" sx={{ pl: 2 }}>
        <Avatar
          alt={row.name as string}
          src={`${row.logo}`}
          variant="rounded"
          sx={{ width: 40, height: 40 }}
        />
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.name}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.description}
      </TableCell>
      <TableCell align="center">
        <Box onClick={(e) => handleBrandDetails(e, row.id as number)}>
          <Tooltip title="Edit">
            <EditIcon
              sx={{
                fontSize: 24,
                opacity: 0.7,
                cursor: 'pointer',
                ':hover': {
                  color: blue[400],
                },
              }}
            />
          </Tooltip>
        </Box>
      </TableCell>
    </Fragment>
  );
};
