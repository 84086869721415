import { Button, Stack, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { useProductStore } from '../../state/stores/products.store';
import { showSnack } from '../../state/stores/snackBar.store';
import { AppTabs } from '../AppTabs';

export const AddInventoryModal = ({ id }: { id: string }) => {
  const [tab, setTab] = useState(0);
  const productQty = useProductStore((state) => state.inventoryForm.quantity);
  const productUnit = useProductStore((state) => state.inventoryForm.unit);
  const productBatchNumber = useProductStore((state) => state.inventoryForm.batchNumber);
  const debitReason = useProductStore((state) => state.inventoryForm.debitReason);
  const setInventoryForm = useProductStore((state) => state.setInventoryForm);
  const clearInventoryForm = useProductStore((state) => state.clearInventoryForm);

  const addProductInventory = useProductStore((state) => state.addProductInventory);
  const fetchInventory = useProductStore((state) => state.fetchInventory);
  const debitProductInventory = useProductStore((state) => state.debitProductInventory);

  const isDebit = tab === 1;

  const handleClose = () => {
    clearInventoryForm();
  };

  const handleFormChange = useCallback(
    (item: string) => {
      return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInventoryForm({ item, value: e.target.value });
      };
    },
    [setInventoryForm]
  );

  const handleSubmit = async () => {
    if (!productQty || !productUnit || !productBatchNumber) {
      showSnack('error', 'Please fill all the required values.');
      return;
    }
    try {
      const result = isDebit
        ? await debitProductInventory({
            id,
            productQty,
            productBatchNumber,
            productUnit,
            debitReason,
          })
        : await addProductInventory({ id, productQty, productBatchNumber, productUnit });
      if (result && typeof result === 'string') {
        handleClose();
        showSnack('success', result);
        fetchInventory(id);
      }
    } catch (error) {
      showSnack('error');
    }
  };

  const debitButtom = (
    <Button onClick={handleSubmit} variant="outlined" sx={{ flex: 1 }} color="error">
      Debit
    </Button>
  );

  const creditButton = (
    <Button onClick={handleSubmit} variant="outlined" sx={{ flex: 1 }} color="success">
      Credit
    </Button>
  );

  const inventoryStack = (type: 'debit' | 'credit', element?: JSX.Element) => {
    return (
      <Stack spacing={3} component="form" sx={{ minWidth: 300 }}>
        <TextField
          value={productQty}
          autoFocus
          margin="dense"
          id="product-quantity"
          label="Product Quantity"
          type="number"
          fullWidth
          variant="standard"
          onChange={handleFormChange('quantity')}
        />
        <TextField
          value={productUnit}
          margin="dense"
          id="product-unit"
          label="Product Variant"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleFormChange('unit')}
        />
        <TextField
          value={productBatchNumber}
          margin="dense"
          id="product-batch-number"
          label="Product Batch Number"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleFormChange('batchNumber')}
        />
        {isDebit ? (
          <TextField
            value={debitReason}
            margin="dense"
            id="product-debit-reason"
            label="Debit Reason"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleFormChange('debitReason')}
          />
        ) : null}
        <Stack direction="row" spacing={2} sx={{ mt: 6 }}>
          {type === 'debit' ? debitButtom : creditButton}
        </Stack>
      </Stack>
    );
  };

  const tabElements = [
    {
      label: 'Credit',
      element: inventoryStack('credit'),
    },
    {
      label: 'Debit',
      element: inventoryStack('debit'),
    },
  ];

  return <AppTabs tabs={tabElements} value={tab} setValue={setTab} />;
};
