import { Box, Typography } from '@mui/material';

export const NoData = ({
  isSmall,
  title,
  subtitle,
}: {
  isSmall?: boolean;
  title?: string;
  subtitle?: string;
}) => {
  return (
    <Box
      sx={{
        minHeight: isSmall ? 200 : 450,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" sx={{ opacity: 0.8 }}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Box>
  );
};
