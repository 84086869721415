export type TProducts = {
  cost_price: string;
  default_image: string;
  id: number;
  name: string;
  selling_price: string;
  short_name: string;
  sku_id: string;
  average_rating: string;
  brand_name: string;
  images_list: string[] | null;
  rating_count: number;
  review_count: number;
};

export type TFetchProducts = {
  data: TProducts[];
  totalCount: number;
};

export type TCategory = {
  description: string;
  id: number;
  is_group: number;
  path: string;
  title: string;
};

export type TCategoryResponse = {
  data: TCategory[] | undefined;
  totalCount: number;
};

export const productHeaders = [
  { id: 'name', name: 'Name' },
  { id: 'sku_id', name: 'SKU ID' },
  { id: 'short_description', name: 'Short Description' },
  { id: 'long_description', name: 'Long Description' },
  { id: 'average_rating', name: 'Average Rating' },
  { id: 'rating_count', name: 'Rating Count' },
  { id: 'review_count', name: 'Review Count' },
  { id: 'images_list', name: 'Images' },
  { id: 'active', name: 'Active' },
  { id: 'action', name: 'Actions' },
];
