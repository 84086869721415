import React from 'react';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import { blue } from '@mui/material/colors';

type TTab = {
  label: string;
  element: JSX.Element;
};

type TAppTabsProps = {
  tabs: TTab[];
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
};

export const AppTabs = ({ tabs, value, setValue }: TAppTabsProps) => {
  return (
    <TabsUnstyled
      defaultValue={0}
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue as number);
      }}
    >
      <TabsList>
        {tabs.map((tab, index) => (
          <Tab key={`${tab.label}-${index}-tab`}>{tab.label}</Tab>
        ))}
      </TabsList>
      {tabs.map((tab, index) => (
        <TabPanel key={`${tab.label}-${index}-tabpanel`} value={index}>
          {tab.element}
        </TabPanel>
      ))}
    </TabsUnstyled>
  );
};

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[200]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
