import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useOrdersStore } from '../../state/stores/orders.store';
import { Box } from '@mui/material';

enum DeliveryStatusTypes {
  PENDING = 'PENDING',
  MANIFESTED = 'Manifested',
  SHIPPING_LABEL_GENERATED = 'Shipping Label Generated',
  PICKUP_SCHEDULED = 'PICKUP_SCHEDULED',
}

export const ApprovedMenu = ({
  id,
  uid,
  waybill,
  deliveryStatus,
  handleShippingPrint,
  visibility,
  invoiceLink,
}: {
  id: number;
  uid: string;
  waybill: string;
  deliveryStatus: string;
  invoiceLink?: string;
  handleShippingPrint: () => void;
  visibility?: {
    generatewaybill?: boolean;
    printShippingLabel?: boolean;
    updateAsDispatch?: boolean;
    downloadInvoice?: boolean;
  };
}) => {
  const _visibility = visibility || {
    downloadInvoice: false,
    generatewaybill: false,
    printShippingLabel: false,
    updateAsDispatch: false,
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const setStoreModal = useOrdersStore((state) => state.setStoreModal);
  const generateShippingLabel = useOrdersStore((state) => state.generateShippingLabel);
  const orderPickedUp = useOrdersStore((state) => state.orderPickedup);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGenerateWaybill = () => {
    handleClose();
    setStoreModal({ open: true, data: { uid } });
  };

  const handlePrintWaybill = () => {
    handleClose();
    if (uid) {
      generateShippingLabel(uid).then((res) => {
        if (res) {
          handleShippingPrint();
        }
      });
    }
  };

  const handleOrderPickedUp = () => {
    handleClose();
    if (id) {
      orderPickedUp(id);
    }
  };
  const handleInvoice = () => {
    handleClose();
    if (invoiceLink) window.open(invoiceLink, '_blank');
  };

  // const nextActionButton = [
  //   deliveryStatus === DeliveryStatusTypes.PENDING && (
  //     <Button key={DeliveryStatusTypes.PENDING} size="small" onClick={handleGenerateWaybill}>
  //       Generate Waybill
  //     </Button>
  //   ),
  //   deliveryStatus === DeliveryStatusTypes.MANIFESTED && (
  //     <Button key={DeliveryStatusTypes.MANIFESTED} size="small" onClick={handlePrintWaybill}>
  //       Print Shipping Label
  //     </Button>
  //   ),
  //   deliveryStatus === DeliveryStatusTypes.SHIPPING_LABEL_GENERATED && null,
  //   deliveryStatus === DeliveryStatusTypes.PICKUP_SCHEDULED && (
  //     <Button key={DeliveryStatusTypes.PICKUP_SCHEDULED} size="small" onClick={handleOrderPickedUp}>
  //       Order Picked Up
  //     </Button>
  //   ),
  // ];

  return (
    <Box display="flex">
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {_visibility.generatewaybill && (
          <MenuItem onClick={handleGenerateWaybill} disabled={!!waybill}>
            Generate Waybill
          </MenuItem>
        )}
        {_visibility.printShippingLabel && (
          <MenuItem onClick={handlePrintWaybill}>Print Shipping Label</MenuItem>
        )}
        {_visibility.updateAsDispatch && (
          <MenuItem
            sx={{ display: DeliveryStatusTypes.PICKUP_SCHEDULED ? 'flex' : 'none' }}
            onClick={handleOrderPickedUp}
          >
            Update as dispatched
          </MenuItem>
        )}
        {_visibility.downloadInvoice && (
          <MenuItem onClick={handleInvoice}>Download Invoice</MenuItem>
        )}
      </Menu>
    </Box>
  );
};
