import { Navigate, Route, Routes } from 'react-router-dom';
import Brands from './pages/BrandsPage';
import { Login } from './pages/LoginPage';
import { Home } from './pages/HomePage';
import { AppDrawer } from './components/AppDrawer';
import { Products } from './pages/ProductsPage';
import Profile from './pages/ProfilePage';
import { CreateProduct, EditProduct } from './pages/ProductPage';
import Warehouse, { CreateWareHouse, EditWareHouse } from './pages/WareHousePage';
import { useAuthStore } from './state/stores';
import Post from './pages/PostsPage';
import { CreateBrand, EditBrand } from './pages/BrandPage';
import { CreatePost } from './pages/PostsPage/CreatePost';
import { EditPost } from './pages/PostsPage/EditPost';
import { Orders, OrderDetails } from './pages/OrdersPage';
import SupportPage from './pages/SupportPage';
import SupportDetailPage from './pages/SupportPage/supportDetailPage';

export const Navigator = () => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <RequireAuth authType="PRE_AUTH">
            <Login />
          </RequireAuth>
        }
      />
      <Route element={<AppDrawer />}>
        <Route
          path="/home"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="products"
          element={
            <RequireAuth>
              <Products />
            </RequireAuth>
          }
        />
        <Route
          path="brand"
          element={
            <RequireAuth>
              <Brands />
            </RequireAuth>
          }
        />
        <Route
          path="brand/create"
          element={
            <RequireAuth>
              <CreateBrand />
            </RequireAuth>
          }
        />
        <Route
          path="brand/edit/:brandId"
          element={
            <RequireAuth>
              <EditBrand />
            </RequireAuth>
          }
        />
        <Route
          path="warehouse"
          element={
            <RequireAuth>
              <Warehouse />
            </RequireAuth>
          }
        />
        <Route
          path="warehouse/create"
          element={
            <RequireAuth>
              <CreateWareHouse />
            </RequireAuth>
          }
        />
        <Route
          path="warehouse/edit/:warehouseId"
          element={
            <RequireAuth>
              <EditWareHouse />
            </RequireAuth>
          }
        />
        <Route
          path="post"
          element={
            <RequireAuth>
              <Post />
            </RequireAuth>
          }
        />
        <Route
          path="products/create"
          element={
            <RequireAuth>
              <CreateProduct />
            </RequireAuth>
          }
        />
        <Route
          path="products/edit/:productId"
          element={
            <RequireAuth>
              <EditProduct />
            </RequireAuth>
          }
        />
        <Route
          path="profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="post/create"
          element={
            <RequireAuth>
              <CreatePost />
            </RequireAuth>
          }
        />
        <Route
          path="post/edit/:postId"
          element={
            <RequireAuth>
              <EditPost />
            </RequireAuth>
          }
        />
        <Route
          path="orders"
          element={
            <RequireAuth>
              <Orders />
            </RequireAuth>
          }
        />
        <Route
          path="order/:orderId"
          element={
            <RequireAuth>
              <OrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path="support"
          element={
            <RequireAuth>
              <SupportPage />
            </RequireAuth>
          }
        />
        <Route
          path="support/:id"
          element={
            <RequireAuth>
              <SupportDetailPage />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

function RequireAuth({
  children,
  authType = 'POST_AUTH',
}: {
  children: JSX.Element;
  authType?: 'PRE_AUTH' | 'POST_AUTH';
}) {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const user = useAuthStore((state) => state.user);
  const isUser = !!user;

  if (authType === 'POST_AUTH') {
    const lastUrl = localStorage.getItem('last_url');
    const currPath = window.location.pathname;
    if (isLoggedIn && isUser) return children;
    if (!lastUrl && currPath !== '/') localStorage.setItem('last_url', currPath);
    return <Navigate to="/login" replace />;
  } else {
    return isLoggedIn && isUser ? <Navigate to="/home" replace /> : children;
  }
}
