import { axiosInstance } from '../axios';
import { useAuthStore } from '../stores/auth.store';
import { setIsLoggedIn, setUser } from '../stores/auth.store';
import { showSnack } from '../stores/snackBar.store';

export type TLogin = {
  email: string;
  password: string;
};

export const logout = () => {
  localStorage.removeItem('login_token');
  setIsLoggedIn(false);
  setUser(null);
};

export const login = (payload: TLogin) => {
  return axiosInstance
    .post('/auth/email/signin', payload)
    .then(({ data }) => {
      localStorage.setItem('login_token', data.auth_token);
      useAuthStore.setState({ isLoggedIn: true, user: data });
      showSnack('success', 'Logged in successfully !!');
      return getProfile();
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      setIsLoggedIn(false);
      throw err;
    });
};

export const register = (payload: TLogin) => {
  return axiosInstance
    .post('/v1/auth/register', payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      setIsLoggedIn(false);
      throw err;
    });
};

export const getProfile = () => {
  const token = localStorage.getItem('login_token') || '';

  if (token)
    return axiosInstance
      .get('/auth/profile')
      .then((res) => {
        useAuthStore.setState({ isLoggedIn: true, user: { ...res.data, auth_token: token } });
        return res.data;
      })
      .catch((err) => {
        setIsLoggedIn(false);
        throw err;
      });

  return Promise.resolve('');
};
