import { TextField } from '@mui/material';
import React from 'react';
import { TInput } from './input.types';

export const Input: React.FC<TInput> = (props) => {
  return (
    <TextField
      {...props}
      sx={{
        flex: '1 1 100%',
        background: 'transparent',
        border: 0,
        fontSize: '1.2rem',
        outline: 'none',
        color: 'white',
        marginBottom: '1rem',
        display: 'flex',
        ...props.sx,
      }}
      onChange={(evn) => {
        props._onChange({ [props?.name || '']: evn.currentTarget.value });
      }}
      autoComplete="new-password"
    />
  );
};
