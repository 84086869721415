import { Box } from '@mui/system';
import { Instagram } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { CustomDataTable } from '../../components/CustomDataTable';
import { Header } from '../../components/Header';
import { usePostStore } from '../../state/stores/posts.store';
import { fetchInstaServer } from '../../state/actions/posts.action';
import { postColumns, TSelectedRow } from './posts.utils';
import { showSnack } from '../../state/stores/snackBar.store';

const TOTAL_POSTS_PER_PAGE = 10;
const selectProps = { indeterminate: (isIndeterminate: any) => isIndeterminate };

export const Post = () => {
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const posts = usePostStore((state) => state.posts);
  const postTotalCount = usePostStore((state) => state.postTotalCount);

  const fetchPosts = usePostStore((state) => state.fetchPosts);
  const deletePost = usePostStore((state) => state.deletePost);
  const navigate = useNavigate();

  const handlePosts = useCallback(() => {
    const _start = (page - 1) * TOTAL_POSTS_PER_PAGE;
    const _end = page * TOTAL_POSTS_PER_PAGE - 1;
    fetchPosts(_start, _end);
  }, [fetchPosts, page]);

  useEffect(() => {
    handlePosts();
  }, [handlePosts]);

  const handleChange = (selected: TSelectedRow) => {
    if (selected.selectedRows) {
      const allRows = selected.selectedRows.map((item) => item.id as number);
      setSelectedRows(allRows);
    }
  };

  const handleRowClicked = (row: any) => {
    navigate(`/post/edit/${row.id}`, { state: { rowId: row.id, isDisabled: true } });
  };

  const deleteAll = async () => {
    const result = window.confirm(
      'All the selected posts with be deleted.\nDo you wish to continue ?'
    );
    if (result) {
      try {
        for (const row of selectedRows) {
          await deletePost(row);
        }
        handlePosts();
        setSelectedRows([]);
        setToggleCleared(!toggleCleared);
      } catch (error) {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    }
  };

  const onActionClick = () => {
    navigate('/post/create');
  };

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const onActionInstaClick = async () => {
    const a = await fetchInstaServer();
    openInNewTab(a.url);
  };

  return (
    <Box>
      <Header title="Posts" />
      <Box>
        <CustomDataTable
          title="Posts Listing"
          actionTitle="Post"
          highlightOnHover
          defaultSortFieldId="created_date"
          columns={postColumns}
          data={posts}
          handleRowClicked={handleRowClicked}
          deleteAll={deleteAll}
          toggleCleared={toggleCleared}
          onActionClick={onActionClick}
          onChangePage={(newPage) => {
            setPage(newPage);
          }}
          paginationTotalRows={postTotalCount}
          paginationServer
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectProps}
          onSelectedRowsChange={handleChange}
          clearSelectedRows={toggleCleared}
          secondaryActionIcon={<Instagram />}
          secondaryActionTitle="Create Post from"
          onSecondaryActionClick={onActionInstaClick}
        />
      </Box>
    </Box>
  );
};
