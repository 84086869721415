import { Snackbar, Alert } from '@mui/material';
import { hideSnack, useSnackBarStore } from '../state/stores/snackBar.store';

export const AppSnackBar = () => {
  const { open, message, severity } = useSnackBarStore();
  return (
    <Snackbar
      open={open}
      onClose={() => {
        hideSnack();
      }}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert elevation={6} variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
