import axios from 'axios';

export const BASE_URL = 'https://test.mesky.store';
// export const BASE_URL ='http://3.110.148.126:8020';

export const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

axiosInstance.interceptors.request.use((conf) => {
  const token = localStorage.getItem('login_token') || '';
  if (token) {
    conf.headers = {
      ...conf.headers,
      Authorization: token,
    };
  }
  return conf;
});
