import { axiosInstance } from '../axios';
import { setProfile } from '../stores/profile.store';
import { showSnack } from '../stores/snackBar.store';

export const fetchProfile = (): any => {
  return axiosInstance
    .get('/auth/profile')
    .then((res) => {
      setProfile(res.data || '');
      return res.data || '';
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};
