export const formatSize = (size: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const finalSize = (size / Math.pow(1024, i)) * 1;
  return finalSize.toFixed(2) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
};

const isNullOrUndefined = (value: any) =>
  value === null ||
  value === undefined ||
  value === '' ||
  (Array.isArray(value) && value.length === 0);

const productRequiredColumns = [
  'name',
  'skuId',
  'category',
  'brandId',
  'longDescription',
  'shortDescription',
  'howToUse',
  'ingredients',
  'soldBy',
  'shippedFrom',
  'images',
  'countryOfOrigin',
];

export const validateProductForm = (formData: any, isEdit: boolean): string[] => {
  const invalidColumns: string[] = [];
  for (const item in formData) {
    if (!isEdit && productRequiredColumns.includes(item) && isNullOrUndefined(formData[item])) {
      invalidColumns.push(item);
    }
  }
  return invalidColumns;
};
