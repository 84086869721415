import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppLoader } from '../../components/AppLoader';
import { Header } from '../../components/Header';
import Input from '../../components/Input';
import { FileUpload } from '../../components/ProductForm/FileUpload';
// import { FormInput } from '../../components/ProductForm/ProductFormInput';
import { useFormState, useQuery } from '../../hooks';
import { editBrand, getBrand } from '../../state/actions/brands.actions';
import { imageUploadService } from '../../state/actions/products.actions';

export const EditBrand = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  const [startEditing, setStartEditing] = useState(!(state as any).isDisabled);
  const { data, loading, exec } = useQuery();
  const { data: data1, loading: loading1, exec: exec1 } = useQuery();

  const [formState, handleInputChange, resetForm, setInitiaState, dirty] = useFormState<{
    logo: string | null;
    name: string;
    description: string;
  }>({
    logo: null,
    description: '',
    name: '',
  });

  useEffect(() => {
    exec(() =>
      getBrand(params?.brandId || null).then((data) => {
        setInitiaState({ logo: data.logo, description: data.description, name: data.name });
      })
    );
  }, []);

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    exec1(() =>
      editBrand({
        logo: formState.logo,
        description: formState.description,
        brand_id: params?.brandId || null,
      }).then(() => {
        navigate('/brand');
      })
    );
  };

  const onImageDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const imageData = new FormData();
        imageData.append('image', acceptedFiles[0]);
        const imageObject = await imageUploadService(imageData);

        const imageLink = imageObject?.img_str;

        handleInputChange({ logo: imageLink });
        // if (columnError.images) setColumnError((prev) => ({ ...prev, images: false }));
      }
    },
    [handleInputChange]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Edit Brand" />
      {loading ? (
        <AppLoader loading={true} />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <Box display={'flex'} flexDirection={'column'} padding={'2rem'}>
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Stack flex={1}>
                <Typography variant="h5">{formState.name}</Typography>
              </Stack>
              <Stack
                direction="row"
                // justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                sx={{ py: 2, pb: 4 }}
              >
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    navigate('/brand', { replace: true });
                  }}
                  size="large"
                >
                  Cancel
                </Button>

                {startEditing ? (
                  <Button
                    variant="outlined"
                    size="large"
                    role="form"
                    type="submit"
                    disabled={!dirty}
                  >
                    Save
                  </Button>
                ) : (
                  <Button variant="outlined" size="large" onClick={() => setStartEditing(true)}>
                    Edit
                  </Button>
                )}
              </Stack>
            </Stack>

            <Box display={'flex'}>
              <Box sx={{ width: '20rem', marginRight: '2rem' }}>
                <FileUpload
                  images={formState.logo ? [formState.logo] : []}
                  onImageDrop={onImageDrop}
                  preFilledImages={undefined}
                  handlePreFilledImageRemove={(img) => () => {
                    handleInputChange({ logo: null });
                  }}
                  disabled={!startEditing}
                  multi={false}
                />
              </Box>
              <Box flex={1}>
                <Input
                  value={formState.name}
                  label="Brand Name"
                  name="name"
                  _onChange={handleInputChange}
                  required
                  disabled={true}
                />
                <Input
                  value={formState.description}
                  name="description"
                  label="Description"
                  _onChange={handleInputChange}
                  disabled={!startEditing}
                  type={''}
                  multiline
                  minRows={15}
                />
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
};
