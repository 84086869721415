import { Box, TableCell, Tooltip } from '@mui/material';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { TCell } from './dataTable.types';
import EditIcon from '@mui/icons-material/Edit';
import { blue } from '@mui/material/colors';

export const WarehouseDataCell = ({ row, labelId }: { row: TCell; labelId: string }) => {  
  const navigate = useNavigate();

  const handleStoreDetails = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, rowId: number) => {
    e.stopPropagation();
    if (e.isPropagationStopped()) {
      navigate(`/warehouse/edit/${rowId}`, { state: { rowId, isDisabled: false } });
    }
  };

  return (
    <Fragment>
      <TableCell align="left" id={labelId}>
        {row.name}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.is_active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.city || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.modified_date}
      </TableCell>
      <TableCell align="left">
        <Box onClick={(e) => handleStoreDetails(e, row.id as number)}>
          <Tooltip title="Edit">
            <EditIcon
              sx={{
                fontSize: 24,
                opacity: 0.7,
                cursor: 'pointer',
                ':hover': {
                  color: blue[400],
                },
              }}
            />
          </Tooltip>
        </Box>
      </TableCell>
    </Fragment>
  );
};
