import create from 'zustand';

export const useBrandService = create<{
  brands: any[];
  loading: boolean;
  error: string | unknown;
}>((set) => ({
  brands: [],
  loading: false,
  error: null,
}));

export const setBrandList = (list: Array<any>) => {
  useBrandService.setState({ brands: list });
};

export const setBrandLoading = (payload: boolean) => {
  useBrandService.setState({ loading: payload });
};
