import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

type TTarget = {
  name: string;
  value: string;
};

type TOnChange = {
  target: TTarget;
};

type TProps = {
  name: string;
  onChange: (target: TOnChange) => void;
};

export const CurrencyInput: any = forwardRef((props: TProps, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      thousandsGroupStyle="lakh"
      isNumericString
      prefix="₹"
    />
  );
});
