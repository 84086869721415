import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // IconButton,
  Stack,
} from '@mui/material';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { ReactComponent as ProductIconLight } from '../../assets/icons/light/products.svg';
import { AppLoader } from '../../components/AppLoader';
import { CustomDataTable } from '../../components/CustomDataTable';
import { Header } from '../../components/Header';
import Input from '../../components/Input';
import { useFormState, useQuery } from '../../hooks';
import { fetchOrderItemDetails, updateOrderStatus } from '../../state/actions/orders.action';

const OrderDetais = () => {
  // const navigate = useNavigate();
  const { orderId } = useParams();
  const { exec, data, ready } = useQuery();

  const [formState, handleInputChange] = useFormState({
    reason: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState<'REJECTED' | 'ACCEPTED'>('ACCEPTED');

  const columns: TableColumn<{ [key: string]: string | number }>[] = useMemo(
    () => [
      {
        name: 'SKU',
        selector: (row) => row.sku_id,
        sortable: true,
      },
      {
        name: 'Product Name',
        selector: (row) => row.product_name,
        sortable: true,
      },
      {
        name: 'Variant',
        selector: (row) => row.product_unit_quantity,
        sortable: true,
      },

      {
        name: 'Unit Price',
        selector: (row) => row.item_price,
        sortable: true,
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity,
        sortable: true,
      },
    ],
    []
  );

  const handleActionClick = (action: 'REJECTED' | 'ACCEPTED') => (e: any) => {
    e.stopPropagation();
    setShowModal(false);
    setTimeout(() => {
      setAction(action);
      setShowModal(true);
    }, 0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setAction('ACCEPTED');
      handleInputChange({ reason: '' });
    }, 0);
  };

  useEffect(() => {
    if (orderId) exec(() => fetchOrderItemDetails(orderId));
  }, [orderId]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (orderId)
      updateOrderStatus(orderId, action, formState.reason).then(() => {
        handleCloseModal();
        exec(() => fetchOrderItemDetails(orderId));
      });
  };
  const getChipColor = () => {
    switch (data.accept_status) {
      case 'PENDING':
        return 'warning';
      case 'APPROVED':
        return 'success';
      case 'REJECTED':
        return 'error';
      default:
        return 'default';
    }
  };

  const renderSection = () => {
    if (!ready) return <AppLoader />;
    return (
      <Box padding={2}>
        <Card>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            paddingX={2}
            justifyContent={'space-between'}
          >
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <h3>{data.uid}</h3>
              <Chip
                label={data.accept_status}
                color={getChipColor()}
                size="small"
                variant="filled"
                sx={{ fontSize: 10, ml: 1 }}
              />
            </Stack>
            {data.accept_status === 'PENDING' && (
              <Stack display={'flex'} flexDirection={'row'}>
                <Button
                  aria-label="Reject"
                  size="small"
                  variant="outlined"
                  title="Reject"
                  onClick={(e) => handleActionClick('REJECTED')(e)}
                  color="error"
                  startIcon={<CancelIcon />}
                  sx={{ mr: 1 }}
                >
                  Reject
                </Button>
                <Button
                  aria-label="Accept"
                  size="small"
                  variant="contained"
                  title="Accept"
                  onClick={(e) => handleActionClick('ACCEPTED')(e)}
                  color="primary"
                  startIcon={<DoneAllIcon />}
                >
                  Accept
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
        <Card sx={{ mt: 2 }}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            paddingX={2}
            paddingY={1}
          >
            <HomeOutlinedIcon sx={{ mr: 1 }} />
            <text>{data.address}</text>
          </Stack>
        </Card>
        <Card sx={{ mt: 2 }}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-start'}
            paddingX={2.4}
            paddingY={1}
          >
            <ProductIconLight style={{ marginTop: '1.2rem' }} />
            <Stack flex={1} ml={1.2} height={100}>
              <CustomDataTable
                pagination={false}
                highlightOnHover
                columns={columns}
                data={[
                  {
                    ...data,
                  },
                ]}
                noHeader={true}
              />
            </Stack>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            paddingX={2.4}
            paddingY={1}
            mt={1}
          >
            <SellOutlinedIcon fontSize="small" />
            <Stack flex={1} ml={1.2}>
              <strong> {data.total_price}</strong>
            </Stack>
          </Stack>
        </Card>
        <Box mt={2} color={'gray'}>
          <small>Modified Date : {new Date(data.modified_date).toLocaleString()}</small>
          <br />
          <small>Created Date : {new Date(data.created_date).toLocaleString()}</small>
        </Box>
        <Dialog onClose={handleCloseModal} open={showModal} fullWidth>
          <DialogTitle>{action === 'ACCEPTED' ? 'Accept' : 'Reject'} Order</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              {action === 'ACCEPTED' ? (
                'Are you sure, you want to accept the order ?'
              ) : (
                <>
                  <p>Please mention the reason of rejecting the order : </p>
                  <Input
                    value={formState.reason}
                    name="reason"
                    label="Reason"
                    _onChange={handleInputChange}
                    disabled={false}
                    type={''}
                    multiline
                    minRows={6}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              {action === 'ACCEPTED' ? (
                <>
                  <Button size="small" variant="outlined" color="error" onClick={handleCloseModal}>
                    No
                  </Button>
                  <Button type="submit" size="small">
                    Yes
                  </Button>
                </>
              ) : (
                <>
                  <Button size="small" variant="outlined" color="error" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button type="submit" size="small">
                    Reject
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    );
  };
  return (
    <Box>
      <Header title="Order Listings" />
      {renderSection()}
    </Box>
  );
};

export default OrderDetais;
