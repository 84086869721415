import { Box, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

export const HStack = ({ children }: { children: React.ReactNode }) => (
  <Stack
    flex={1}
    direction="row"
    spacing={1}
    alignItems="center"
    sx={{ borderColor: grey[200], borderWidth: 1, borderStyle: 'solid' }}
  >
    {children}
  </Stack>
);

export const RowTitle = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ bgcolor: grey[100], p: 1, minWidth: 130, fontSize: 14 }}>{children}</Box>
);

export const RowData = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ fontStyle: 'italic' }}>{children}</Box>
);
