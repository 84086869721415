import { Button, Checkbox, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
// import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { TDataRow, TSelectedRow } from '../../pages/PostsPage/posts.utils';
import { DataRow, useOrdersStore } from '../../state/stores/orders.store';
import { CustomDataTable } from '../CustomDataTable';
import { CreateSupportTicketModal } from './CreateSupportTicketModal';
import { OrderListData } from './OrderListData';
import { PickupRequestModal } from './PickupRequestModal';
import { ShippingLabelComponentToPrint } from './ShippingLabelComponentToPrint';
import { StoreDialog } from './StoreDialog';

const TOTAL_APPROVED_ORDERS_PER_PAGE = 10;
const selectProps = { indeterminate: (isIndeterminate: any) => isIndeterminate };

export const ReturnedOrders = ({
  approvedSelected,
  setSelectedRows,
}: {
  approvedSelected: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  // const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [showSupport, setShowSupport] = useState(false);

  const [toggleCleared, setToggleCleared] = useState(false);
  const returnedOrders = useOrdersStore((state) => state.returnedOrders);
  const orderTotalCount = useOrdersStore((state) => state.totalCount);

  const fetchReturneddOrders = useOrdersStore((state) => state.fetchReturnedOrders);

  const handleOrders = useCallback(() => {
    const _start = (page - 1) * TOTAL_APPROVED_ORDERS_PER_PAGE;
    const _end = page * TOTAL_APPROVED_ORDERS_PER_PAGE - 1;
    fetchReturneddOrders(_start, _end);
  }, [fetchReturneddOrders, page]);

  const handleRowClick = (rowData: any) => {
    // rowData?.id && navigate('/order/' + rowData.id);
  };

  useEffect(() => {
    handleOrders();
  }, [handleOrders]);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: 'print-page',
  });

  const handleShippingPrint = useCallback(() => {
    handlePrint();
  }, [handlePrint]);

  const handleChange = (selected: TSelectedRow) => {
    if (selected.selectedRows) {
      const allRows = selected.selectedRows.map((item) => item.id as number);
      setSelectedRows(allRows);
    }
  };

  const handleToggle = useCallback(() => {
    setToggleCleared(!toggleCleared);
  }, [toggleCleared]);

  const columns: TableColumn<TDataRow>[] = [
    {
      name: 'Unique Order ID',
      selector: (row) => row.uid,
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: 'SKU ID',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: 'Qty',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: 'Variant',
      selector: (row) => row.product_unit_quantity,
      sortable: true,
    },
    {
      name: 'Ordered At',
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.accept_status,
      sortable: true,
    },
  ];

  const expandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    return (
      <>
        <Grid container columns={2}>
          <Grid item sx={{ flex: 1 }}>
            <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
              <OrderListData title="Store Name" data={data.store_name} />
              <OrderListData title="Item Price" data={data.item_price} />
              <OrderListData title="Order Amount" data={data.total_price} />
              <OrderListData title="Shipping Address" data={data.address} />
              <OrderListData title="Shipment Status" data={data.total_price} />
              <OrderListData title="Customer Email" data={data.customer_email} />
            </Stack>
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
              <OrderListData title="Mobile Number" data={data.customer_mobile} />
              <OrderListData title="Order Status" data={data.delivery_status} />
              <OrderListData title="Last Updated" data={data.modified_date} />
              <OrderListData title="Reason/notes" data={data.return_reason} />
              <OrderListData title="Remarks" data={data.reject_reason} />
            </Stack>
          </Grid>
        </Grid>
        <Stack display={'flex'} direction="row" justifyContent="end" paddingRight={6} my={1}>
          <Button
            onClick={() => {
              setSelectedData(data);
              setShowSupport(true);
            }}
          >
            Contact Support
          </Button>
        </Stack>
      </>
    );
  };

  return (
    <>
      <CustomDataTable
        highlightOnHover
        defaultSortFieldId="created_date"
        columns={columns}
        data={returnedOrders}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        paginationTotalRows={orderTotalCount}
        paginationServer
        noHeader={true}
        handleRowClicked={handleRowClick}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggleCleared}
        expandedComponent={expandedComponent}
      />
      <StoreDialog handleOrders={handleOrders} />
      <PickupRequestModal approvedSelected={approvedSelected} handleToggle={handleToggle} />
      <CreateSupportTicketModal
        data={selectedData}
        approvedSelected={approvedSelected}
        open={showSupport}
        onClose={() => {
          setShowSupport(false);
          setSelectedData(null);
        }}
        handleToggle={handleToggle}
      />
      <div style={{ display: 'none' }}>
        <ShippingLabelComponentToPrint ref={componentRef} />
      </div>
    </>
  );
};
