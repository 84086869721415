import React from 'react';
import { DataGrid, GridColumns, GridSortModel } from '@mui/x-data-grid';

type TRow = {
  [cell: string]: string | number;
};

type TDataGridProps = {
  rows: TRow[];
  columns: GridColumns;
  disableColumnMenu?: boolean;
  checkboxSelection?: boolean;
  sortModel: GridSortModel;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
};

export const CustomDataGrid = ({
  checkboxSelection = false,
  rows,
  columns,
  disableColumnMenu = true,
  sortModel,
  setSortModel,
}: TDataGridProps) => {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        disableColumnMenu={disableColumnMenu}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </div>
  );
};
