import { Box, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
// import { useNavigate } from 'react-router-dom';
import { useOrdersStore, DataRow } from '../../state/stores/orders.store';
import { CustomDataTable } from '../CustomDataTable';
// import { HStack, RowData, RowTitle } from './ExpandedStyles';
import { OrderListData } from './OrderListData';

const TOTAL_APPROVED_ORDERS_PER_PAGE = 10;

export const CancelledOrders = () => {
  // const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const cancelledOrders = useOrdersStore((state) => state.cancelledOrders);
  const orderTotalCount = useOrdersStore((state) => state.totalCount);

  const fetchCancelledOrders = useOrdersStore((state) => state.fetchCancelledOrders);

  const handleOrders = useCallback(() => {
    const _start = (page - 1) * TOTAL_APPROVED_ORDERS_PER_PAGE;
    const _end = page * TOTAL_APPROVED_ORDERS_PER_PAGE - 1;
    fetchCancelledOrders(_start, _end);
  }, [fetchCancelledOrders, page]);

  const handleRowClick = (rowData: any) => {
    // rowData?.id && navigate('/order/' + rowData.id);
  };

  useEffect(() => {
    handleOrders();
  }, [handleOrders]);

  const columns: TableColumn<{ [key: string]: string | number }>[] = useMemo(
    () => [
      {
        name: 'UID',
        selector: (row) => row.uid,
        sortable: true,
      },
      {
        name: 'Customer Address',
        selector: (row) => row.address,
        sortable: true,
      },
      {
        name: 'Product Name',
        selector: (row) => row.product_name,
        sortable: true,
      },
      {
        name: 'Variant',
        selector: (row) => row.product_unit_quantity,
        sortable: true,
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity,
        sortable: true,
      },
      {
        name: 'Ordered At',
        selector: (row) => row.created_date,
        sortable: true,
      },
      {
        name: 'Status',
        selector: (row) => row.accept_status,
        sortable: true,
      },
    ],
    []
  );

  const expandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    return (
      <Grid container columns={2}>
        <Grid item sx={{ flex: 1 }}>
          <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
            <OrderListData title="Store Name" data={data.store_name} />
            <OrderListData title="Item Price" data={data.item_price} />
            <OrderListData title="Order Amount" data={data.total_price} />
            <OrderListData title="Shipping Address" data={data.address} />
            <OrderListData title="Shipment Status" data={data.total_price} />
            <OrderListData title="Customer Email" data={data.customer_email} />
          </Stack>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
            <OrderListData title="Mobile Number" data={data.customer_mobile} />
            <OrderListData title="Order Status" data={data.delivery_status} />
            <OrderListData title="Last Updated" data={data.modified_date} />
            <OrderListData title="Reason/notes" data={data.cancel_reason} />
            <OrderListData title="Remarks" data={data.reject_reason} />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <CustomDataTable
        highlightOnHover
        defaultSortFieldId="created_date"
        columns={columns}
        data={cancelledOrders}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        paginationTotalRows={orderTotalCount}
        paginationServer
        noHeader={true}
        handleRowClicked={handleRowClick}
        expandedComponent={expandedComponent}
      />
    </Box>
  );
};
