import { axiosInstance } from '../axios';
import { showSnack } from '../stores/snackBar.store';

export const fetchPendingOrdersService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/order/list/approved?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchApprovedOrdersService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/order/list/approved?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const generateShippingLabelService = (uid: string): any => {
  return axiosInstance
    .get(`/vendor/order/delhivery/shippinglabel?uid=${uid}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchRejectedOrdersService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/order/list/delivered?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchCancelledOrdersService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/order/list/cancelled?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchReturnedOrdersService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/order/list/returned?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchCompletedOrdersService = (_start: number, _end: number): any => {
  return axiosInstance
    .get(`/vendor/order/list/completed?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const createVendorOrderService = (storeId: string, uid: string) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/order/delhivery/create',
    data: {
      store_id: storeId,
      uid,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const updateOrderStatus = (
  orderId: string | number,
  status: 'REJECTED' | 'ACCEPTED',
  rejectReason?: string
) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/order/modify/accept_status',
    data: {
      orderitem_id: orderId,
      accept_status: status === 'ACCEPTED' ? 'True' : 'False',
      ...(rejectReason ? { reject_reason: rejectReason } : {}),
    },
  })
    .then((res) => {
      showSnack('success', res?.data?.message);
      return res.data;
    })
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const fetchOrderItemDetails = (orderId: string) => {
  return axiosInstance({
    method: 'GET',
    url: '/vendor/order/details',
    params: { orderitem_id: orderId },
  }).then((res) => res.data);
};

export const pickupRequestService = (
  orderIds: number[],
  storeId: string,
  pickupDate: any,
  pickupTime: any
) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/order/delhivery/pickup',
    data: {
      order_ids: orderIds,
      store_id: storeId,
      pickup_date: pickupDate,
      pickup_time: pickupTime,
    },
  })
    .then((res) => res.status)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};

export const orderPickedupService = (orderId: number) => {
  return axiosInstance({
    method: 'POST',
    url: '/vendor/order/delhivery/haspickedup',
    data: {
      order_id: orderId,
    },
  })
    .then((res) => res.status)
    .catch((err) => {
      showSnack('error', err?.response?.data?.message);
      throw err;
    });
};
