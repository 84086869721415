import { Box, Button, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLoader } from '../../components/AppLoader';
import { Header } from '../../components/Header';
import Input from '../../components/Input';
import { FileUpload } from '../../components/ProductForm/FileUpload';
// import { FormInput } from '../../components/ProductForm/ProductFormInput';
import { useFormState, useQuery } from '../../hooks';
import { createBrand } from '../../state/actions/brands.actions';
import { imageUploadService } from '../../state/actions/products.actions';

export const CreateBrand = () => {
  const navigate = useNavigate();
  const [imageUploading, setImageUploading] = useState(false);

  const [formState, handleInputChange, dirty] = useFormState<{
    logo: string | null;
    name: string;
    description: string;
  }>({
    logo: null,
    description: '',
    name: '',
  });

  const { exec } = useQuery();

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    exec(() =>
      createBrand(formState).then(() => {
        navigate('/brand');
      })
    );
  };

  const onImageDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const imageData = new FormData();
        imageData.append('image', acceptedFiles[0]);
        setImageUploading(true);
        try {
          const imageObject = await imageUploadService(imageData);
          const imageLink = imageObject?.img_str;
          handleInputChange({ logo: imageLink });
        } finally {
          setImageUploading(false);
        }
        // if (columnError.images) setColumnError((prev) => ({ ...prev, images: false }));
      }
    },
    [handleInputChange]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Create Brand" />
      {false ? (
        <AppLoader loading={true} />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <Box display={'flex'} flexDirection={'column'} padding={'2rem'}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              sx={{ py: 2, pb: 4 }}
            >
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  navigate('/brand', { replace: true });
                }}
                size="large"
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                size="large"
                role="form"
                type="submit"
                disabled={!dirty || !formState.name || imageUploading}
              >
                Create
              </Button>
            </Stack>

            <Box display={'flex'}>
              <Box sx={{ width: '20rem', marginRight: '2rem' }}>
                <FileUpload
                  images={formState.logo ? [formState.logo] : []}
                  onImageDrop={onImageDrop}
                  preFilledImages={undefined}
                  handlePreFilledImageRemove={(img) => () => {}}
                  disabled={false}
                  multi={false}
                />
              </Box>
              <Box flex={1}>
                <Input
                  value={formState.name}
                  label="Brand Name"
                  name="name"
                  _onChange={handleInputChange}
                  required
                  disabled={false}
                />
                <Input
                  value={formState.description}
                  name="description"
                  label="Description"
                  _onChange={handleInputChange}
                  disabled={false}
                  type={''}
                  multiline
                  minRows={15}
                />
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
};
