import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteBrand, fetchBrands } from '../../state/actions/brands.actions';
import { useProductStore } from '../../state/stores/products.store';
import { showSnack } from '../../state/stores/snackBar.store';
import { DataTable } from '../DataTable';
import { THeaderCell, TCell } from '../DataTable/dataTable.types';

export const Listings = ({
  productHeaderCells,
  rows,
  type,
  page,
  setPage,
  totalCount,
  showCheckBoxes = true,
}: {
  productHeaderCells: THeaderCell[];
  rows: TCell[];
  type: 'products' | 'brand' | 'warehouse' | 'order';
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalCount: number;
  showCheckBoxes?: boolean;
}) => {
  const deleteProduct = useProductStore((state) => state.deleteProduct);
  const fetchProducts = useProductStore((state) => state.fetchProducts);

  const navigate = useNavigate();

  const handleProductDelete = useCallback(
    async (selected: number[]) => {
      for (const item of selected) {
        const result = await deleteProduct(item);
        if (result) {
          showSnack('success', result);
        }
      }
      await fetchProducts(0, 10);
      setPage(0);
    },
    [deleteProduct, setPage, fetchProducts]
  );
  const handleDelete = () => {
    switch (type) {
      case 'products':
        return handleProductDelete;
      case 'brand':
        return (selected: number[]) => {
          return Promise.all(selected.map(deleteBrand)).then(() => {
            fetchBrands();
          });
        };
      default:
        return (selected: number[]) => Promise.resolve();
    }
  };

  const handleProductClick = useCallback(
    (rowId: number) => {
      navigate(`/products/edit/${rowId}`, { state: { rowId, isDisabled: true } });
    },
    [navigate]
  );
  const handleBrandClick = useCallback(
    (rowId: number) => {
      navigate(`/brand/edit/${rowId}`, { state: { rowId, isDisabled: true } });
    },
    [navigate]
  );
  const handleWarehouseClick = useCallback(
    (rowId: number) => {
      navigate(`/warehouse/edit/${rowId}`, { state: { rowId, isDisabled: true } });
    },
    [navigate]
  );
  const handleOrderClick = useCallback(
    (rowId: number) => {
      // navigate(`/warehouse/edit/${rowId}`, { state: { rowId, isDisabled: true } });
    },
    [navigate]
  );

  const handleDetails = () => {
    switch (type) {
      case 'products':
        return handleProductClick;
      case 'brand':
        return handleBrandClick;
      case 'warehouse':
        return handleWarehouseClick;
      case 'order':
        return handleOrderClick;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <DataTable
        title="Products"
        headerCells={productHeaderCells}
        rows={rows}
        type={type}
        handleDelete={handleDelete()}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        handleDetails={handleDetails()}
        showCheckBoxes={showCheckBoxes}
      />
    </Box>
  );
};
