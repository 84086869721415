import { Box, CircularProgress } from '@mui/material';
import { useBrandService } from '../state/stores/brands.store';
import { useOrdersStore } from '../state/stores/orders.store';
import { usePostStore } from '../state/stores/posts.store';
import { useProductStore } from '../state/stores/products.store';
import { useWarehouseStore } from '../state/stores/warehouse.store';

export const AppLoader = ({ loading }: { loading?: boolean }) => {
  const productLoader = useProductStore((state) => state.loading);
  const postLoader = usePostStore((state) => state.loading);
  const ordersLoader = useOrdersStore((state) => state.loading);
  const brandLoading = useBrandService((state) => state.loading);
  const warehouseLoading = useWarehouseStore((state) => state.loading);
  const isLoading =
    productLoader || postLoader || ordersLoader || brandLoading || warehouseLoading || loading;

  return isLoading ? (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1200,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1201,
        }}
      >
        <CircularProgress size={60} sx={{ zIndex: 1201 }} />
      </Box>
    </Box>
  ) : null;
};
