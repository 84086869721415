import { Stack, Box, Divider, Tooltip } from '@mui/material';
import { useProductStore } from '../../state/stores/products.store';
import { ProductPrice } from './ProductPrice';
import { NoData } from '../DataTable/NoData';
import { CustomDataGrid } from '../DataGrid';
import { GridColumns, GridSortModel } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { blue } from '@mui/material/colors';
import { useState } from 'react';

export const ProductPriceTable = ({ id }: { id: string }) => {

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);
  const productPriceList = useProductStore((state) => state.productPriceList);

  return (
    <Stack spacing={2} direction="row" sx={{ mt: 4 }}>
      <Box sx={{ flex: '1 1 100%' }}>
        {productPriceList?.length ? (
          <CustomDataGrid
            rows={productPriceList}
            columns={productPriceColumns}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        ) : (
          <NoData isSmall={true} title="No product variant available." />
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ flex: '1 2 100%' }}>
        <ProductPrice id={id} />
      </Box>
    </Stack>
  );
};

const productPriceColumns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    sortingOrder: ['desc', 'asc'],
  },
  {
    field: 'unit_quantity',
    headerName: 'Variant',
    width: 200,
  },
  {
    field: 'selling_price',
    headerName: 'Selling Price',
    width: 180,
  },
  {
    field: 'offer_price',
    headerName: 'Offer Price',
    width: 180,
  },
  {
    field: 'tax_percentage',
    headerName: 'Tax Percentage',
    width: 180,
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 50,
    renderCell: function (params: any) {
      const rowId = params.row.id;
      const offerPrice = params.row.offer_price;
      const sellingPrice = params.row.selling_price;
      const unitQuantity = params.row.unit_quantity;
      const taxPercentage = params.row.taxPercentage;
      return (
        <Box
          onClick={() => {
            useProductStore.getState().setPriceModalForm({
              priceId: rowId,
              extra: {
                offer_price: offerPrice,
                selling_price: sellingPrice,
                unit_quantity: unitQuantity,
                product_id: rowId,
                taxPercentage: taxPercentage
              },
            });
          }}
        >
          <Tooltip title="Edit">
            <EditIcon
              sx={{
                fontSize: 24,
                opacity: 0.7,
                cursor: 'pointer',
                ':hover': {
                  color: blue[400],
                },
              }}
            />
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 50,
    renderCell: function (params: any) {
      const rowId = params.row.id;
      const productId = params.row.product_id
      return (
        <Box
          onClick={() => {
            console.log("rowID", rowId);

            useProductStore.getState().deleteProductPrice({
              productId: productId,
              priceId: rowId,
            }).then((value) => window.location.reload());
          }}
        >
          <Tooltip title="Delete">
            <DeleteIcon
              sx={{
                fontSize: 24,
                opacity: 0.7,
                cursor: 'pointer',
                ':hover': {
                  color: blue[400],
                },
              }}
            />
          </Tooltip>
        </Box>
      );
    },
  }
];
