import { Chip, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useProductStore } from '../../state/stores/products.store';

export const PostTags = ({
  hashTags,
  disabled,
}: {
  hashTags: string[];
  disabled: boolean | undefined;
}) => {
  const setTag = useProductStore((state) => state.setTag);
  return (
    <Stack spacing={3} flex={1}>
      <Box>
        {hashTags.map((tag, index) => (
          <Chip
            key={`${tag}-${index}`}
            label={`${tag}`}
            onDelete={() => {
              if (disabled) return;
              setTag(tag, 'remove');
            }}
            sx={{ my: 1, mr: 1 }}
            disabled={disabled}
          />
        ))}
      </Box>
    </Stack>
  );
};
