import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { fetchStore } from '../../state/actions/warehouse.actions';
import { useOrdersStore } from '../../state/stores/orders.store';
import { showSnack } from '../../state/stores/snackBar.store';
import { useWarehouseStore } from '../../state/stores/warehouse.store';
import { FormSelect } from '../ProductForm/FormSelect';
import { TSelectOptions } from '../ProductForm/productForm.types';

export const StoreDialog = ({ handleOrders }: { handleOrders: () => void }) => {
  const [selected, setSelected] = useState<TSelectOptions | null>(null);
  const modalOpen = useOrdersStore((state) => state.storeModal.open);
  const modalData = useOrdersStore((state) => state.storeModal.data);
  const storeList = useWarehouseStore((state) => state.store);
  const loading = useOrdersStore((state) => state.loading);

  const setStoreModal = useOrdersStore((state) => state.setStoreModal);
  const createVendorOrder = useOrdersStore((state) => state.createVendorOrder);

  useEffect(() => {
    fetchStore();
  }, []);

  const storeSelectList = useMemo(() => {
    return (storeList || [])?.map((store) => ({ label: store.registered_name, id: store.id }));
  }, [storeList]);

  const handleModal = () => {
    setStoreModal({ open: false, data: null });
    setSelected(null);
  };

  const handleStoreChange = (store: TSelectOptions | null) => {
    setSelected(store);
  };

  const handleGenerate = () => {
    if (!selected) {
      showSnack('info', 'Please select a store to continue');
      return;
    }
    if (selected.id && modalData.uid) {
      createVendorOrder(selected.id + '', modalData.uid).then((res) => {
        if (res) {
          handleOrders();
          showSnack('success', 'Order waybill number successfully generated');
        }
        setStoreModal({ open: false, data: null });
        setSelected(null);
      });
    }
  };

  return (
    <Dialog onClose={handleModal} open={modalOpen} fullWidth>
      <DialogTitle>Select Store</DialogTitle>
      <DialogContent dividers>
        <FormSelect
          value={selected}
          handleChange={handleStoreChange}
          label="Stores"
          selectId="store"
          options={storeSelectList}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleGenerate}
          disabled={loading}
          endIcon={<CircularProgress size={20} sx={{ display: loading ? 'flex' : 'none' }} />}
        >
          Generate Waybill
        </Button>
      </DialogActions>
    </Dialog>
  );
};
