import { TableCell } from '@mui/material';
import { Fragment } from 'react';
import { TCell } from './dataTable.types';
// import { blue } from '@mui/material/colors';

export const OrderDataCell = ({ row, labelId }: { row: TCell; labelId: string }) => {

  return (
    <Fragment>
      <TableCell align="left" id={labelId}>
        {row.id}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.vendor_id}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.product_id}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.customer_address_line_1 || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.customer_address_city || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.customer_address_state || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.quantity || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.item_price || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.product_unit_quantity || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.total_price || '-'}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.created_date}
      </TableCell>
      <TableCell align="left" id={labelId}>
        {row.modified_date}
      </TableCell>
    </Fragment>
  );
};
