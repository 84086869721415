import { Box, ImageList, ImageListItem, Switch, TableCell, Tooltip } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { TCell } from './dataTable.types';
import EditIcon from '@mui/icons-material/Edit';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { showSnack } from '../../state/stores/snackBar.store';
import { useProductStore } from '../../state/stores/products.store';

export const ProductsDataCell = ({ row, labelId }: { row: TCell; labelId: string }) => {
  const navigate = useNavigate();

  const imagesData = useMemo(() => {
    let images: string[] = [];
    const imageList = row.images_list as string[];
    if (imageList?.length > 0) {
      images = imageList;
    } else {
      images = [row.default_image as string];
    }
    return images;
  }, [row.images_list, row.default_image]);

  const handleProductDetails = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, rowId: number) => {
    e.stopPropagation();
    if (e.isPropagationStopped()) {
      navigate(`/products/edit/${rowId}`, { state: { rowId, isDisabled: false } });
    }
  };

  return (
    <Fragment>
      <TableCell component="th" id={labelId} scope="row">
        {row.name}
      </TableCell>
      <TableCell align="left">{row.sku_id}</TableCell>
      <Tooltip title={row.short_description as string}>
        <TableCell align="left" className="ellipsis">
          {row.short_description}
        </TableCell>
      </Tooltip>
      <Tooltip title={row.long_description as string}>
        <TableCell align="left" className="ellipsis">
          {row.long_description}
        </TableCell>
      </Tooltip>
      <TableCell align="left">{row.average_rating}</TableCell>
      <TableCell align="left">{row.rating_count}</TableCell>
      <TableCell align="left">{row.review_count}</TableCell>
      <TableCell align="left" padding="none">
        <ImageList sx={{ maxHeight: 100 }}>
          {imagesData.map((item: string, index) => (
            <ImageListItem
              key={index}
              sx={{
                width: {
                  lg: 60,
                  xl: 100,
                },
                height: {
                  lg: 60,
                  xl: 100,
                },
              }}
            >
              <img src={`${item}`} alt={row.name as string} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </TableCell>
      <TableCell>
        <ProductSwitch isActive={Boolean(row.is_active)} id={Number(row.id)} />
      </TableCell>
      <TableCell align="center">
        <Box onClick={(e) => handleProductDetails(e, row.id as number)}>
          <Tooltip title="Edit">
            <EditIcon
              sx={{
                fontSize: 24,
                opacity: 0.7,
                cursor: 'pointer',
                ':hover': {
                  color: blue[400],
                },
              }}
            />
          </Tooltip>
        </Box>
      </TableCell>
    </Fragment>
  );
};

export const ProductSwitch = ({ isActive, id }: { isActive: boolean; id: number | null }) => {
  const [checked, setChecked] = useState(() => isActive);

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  return (
    <Switch
      checked={checked}
      inputProps={{ 'aria-label': 'product-enable/disable' }}
      onClick={(e) => {
        e.stopPropagation();
        if (e.isPropagationStopped() && id) {
          useProductStore
            .getState()
            .toggleProduct(id, !checked)
            .then((message) => {
              if (message) {
                showSnack('success', message);
                setChecked(!checked);
              }
            })
            .catch(() => {
              showSnack('error', 'Something went wrong. Please try again.');
            });
        }
      }}
    />
  );
};
