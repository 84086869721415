import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormState, useQuery } from '../../hooks';
import { createSupportTicket, fetchIssueCategoryList } from '../../state/actions/support.action';
// import { useOrdersStore } from '../../state/stores/orders.store';
import Input from '../Input';
import { FormSelect } from '../ProductForm/FormSelect';
import { TSelectOptions } from '../ProductForm/productForm.types';
import { OrderListData } from './OrderListData';

import { ReactComponent as CheckIcon } from './check.svg';
import { useEffect, useState } from 'react';
export const CreateSupportTicketModal = ({
  approvedSelected,
  handleToggle,
  open,
  onClose,
  data = {},
}: {
  approvedSelected: number[];
  handleToggle: () => void;
  open: boolean;
  onClose: () => void;
  data: any;
}) => {
  console.log(data);
  const { err, exec, loading, data: _data } = useQuery();
  const { exec: execList } = useQuery();
  const [formState, handleInputChange] = useFormState<{
    cetegory: TSelectOptions | null;
    subjectLine: string;
    reason: string;
  }>({
    cetegory: null,
    subjectLine: '',
    reason: '',
  });

  const [reasonList, setReasonList] = useState([]);

  useEffect(() => {
    execList(() =>
      fetchIssueCategoryList().then((d) => {
        const l = d.map((_d: any) => ({ label: _d.title, id: _d.id }));
        setReasonList(l);
      })
    );
  }, []);
  const onSubmit = () => {
    exec(() => createSupportTicket({ ...formState, orderid: data.id }));
  };

  const renderCreateForm = () => {
    return (
      <>
        <Stack
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          paddingRight={'20px'}
          alignItems="center"
        >
          <DialogTitle>Create Support Ticket</DialogTitle>
          <Button style={{ display: 'block' }} variant="outlined" onClick={onClose}>
            Close
          </Button>
        </Stack>
        <DialogContent dividers>
          <Stack display={'flex'} gap={4} direction="row">
            <Stack flex={1}>
              <OrderListData title="Order ID" data={data?.uid} />
              <OrderListData title="Item Name" data={data?.product_name} />
            </Stack>
            <Stack flex={1}>
              <OrderListData title="Order Status" data={data?.accept_status} />
              <OrderListData title="SKU" data={data?.product_id} />
            </Stack>
          </Stack>
          <Stack marginY={2}>
            <Typography variant="caption" marginBottom={'5px'}>
              Select the Category of your query/Issue.
            </Typography>
            <FormSelect
              value={formState.cetegory}
              handleChange={(val: TSelectOptions | null) => {
                console.log(val);
                if (val) handleInputChange({ cetegory: val });
              }}
              label="Select category"
              selectId="category"
              options={reasonList}
              required
            />
          </Stack>
          <Stack marginY={2}>
            <Typography variant="caption" marginBottom={'5px'}>
              Subject Line
            </Typography>
            <Input name="subjectLine" value={formState.subjectLine} _onChange={handleInputChange} />
          </Stack>
          <Stack marginY={2}>
            <Typography variant="caption" marginBottom={'5px'}>
              Issue/Query Description
            </Typography>
            <textarea
              name="reason"
              onChange={(e) => handleInputChange({ reason: e.target.value })}
              placeholder="Describe your issue or query herer"
            >
              {formState.reason}
            </textarea>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={loading}
            endIcon={<CircularProgress size={20} sx={{ display: loading ? 'flex' : 'none' }} />}
          >
            Submit
          </Button>
          <Button
            onClick={onClose}
            disabled={loading}
            endIcon={<CircularProgress size={20} sx={{ display: loading ? 'flex' : 'none' }} />}
          >
            Cancel
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderSuccessRecipt = () => {
    return (
      <Stack padding={4}>
        <Stack display={'flex'} direction="row" alignItems={'center'}>
          <CheckIcon />
          <Typography ml={1} color={'#018786'}>
            Ticket Created Sucesfully
          </Typography>
        </Stack>
        <Stack>
          <p>
            Your query is recorded with us and we have created a ticket with the reference no:
            <strong> {_data.desk_ticket_id}</strong>. We have sent the details on your registered
            email. Please expect a reply from us within 48 hours
          </p>
        </Stack>
        <Stack>
          <Button onClick={onClose}>Close</Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog id="pickup-modal" open={open}>
      {_data ? renderSuccessRecipt() : renderCreateForm()}
    </Dialog>
  );
};
