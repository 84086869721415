import { TableColumn } from 'react-data-table-component';
import { blue } from '@mui/material/colors';
import { Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { PostSwitch } from './PostSwitch';

const TooltipComponent = ({ title }: { title: string }) => {
  return (
    <Tooltip title={title}>
      <Typography className="ellipsis" variant="caption">
        {title}
      </Typography>
    </Tooltip>
  );
};

export type TDataRow = {
  [key: string]: string | number;
};

export type TSelectedRow = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: TDataRow[];
};

export const postColumns: TableColumn<TDataRow>[] = [
  {
    name: 'Brand Name',
    selector: (row) => row.brand_name,
    sortable: true,
  },
  {
    name: 'Title',
    selector: (row) => row.description,
    sortable: true,
    grow: 2,
    cell: (row) => {
      return <TooltipComponent title={row.description as string} />;
    },
  },
  {
    name: 'Likes',
    selector: (row) => row.like_count,
    sortable: true,
    right: true,
  },
  {
    name: 'Comments',
    selector: (row) => row.comment_count,
    right: true,
  },
  {
    name: 'Modified Date',
    selector: (row) => row.modified_date,
    sortable: true,
  },
  {
    name: 'Created Date',
    selector: (row) => row.created_date,
    sortable: true,
  },
  {
    name: 'Active',
    sortable: false,
    center: true,
    cell: (row) => {
      return <PostSwitch isActive={Boolean(row.is_active)} id={+row.id} />;
    },
  },
  {
    name: 'Actions',
    sortable: false,
    center: true,
    cell: (row) => {
      return (
        <Link
          to={`/post/edit/${row.id}`}
          state={{ rowId: row.id, isDisabled: false }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip title="Edit">
            <EditIcon
              sx={{
                fontSize: 24,
                opacity: 0.7,
                cursor: 'pointer',
                color: 'black',
                ':hover': {
                  color: blue[400],
                },
              }}
            />
          </Tooltip>
        </Link>
      );
    },
  },
];
