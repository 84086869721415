import create from 'zustand';
import { TSelectOptions } from '../../components/ProductForm/productForm.types';
import { TFetchProducts } from '../../pages/ProductsPage/products.utils';
import { fetchBrands } from '../actions/brands.actions';
import {
  createHashTagsService,
  deletePostService,
  editPostService,
  fetchPostService,
  getHashTagsService,
  postCreateService,
  postDetailService,
  togglePostService,
  videoUploadService,
} from '../actions/posts.action';
import { fetchPostProductService } from '../actions/products.actions';
import { useProductStore } from './products.store';

type TPosts = {
  id: number;
  category_name: string;
  hashtags: any;
  brand_id: number;
  brand_name: string;
  like_count: number;
  comment_count: number;
  share_count: number;
  image: string;
  brand_logo: string;
  created_date: string;
  description: string;
  modified_date: string;
  is_video: number;
  video_url: string;
};

type TPostResponse = {
  data: TPosts[];
  totalCount: number;
};

type TPostForm = {
  brand?: TSelectOptions | null;
  category: TSelectOptions | null;
  description: string;
  hashTags: string[];
  media_info: any;
  product: TSelectOptions | null;
};

type TVideoUploadResponse = {
  video_str: string;
};

type THashtagsList = {
  id: number;
  tag: string;
};

type TFetchFilteredProducts = {
  _start: number;
  _end: number;
  brand?: number;
  category?: number;
};

const initialPostForm: TPostForm = {
  brand: null,
  category: null,
  description: '',
  hashTags: [],
  media_info: [],
  product: null,
};

export const usePostStore = create<{
  posts: TPosts[];
  loading: boolean;
  error: string | unknown;
  postForm: TPostForm;
  hashTagsList: THashtagsList[];
  postTotalCount: number;
  filteredProductList: TSelectOptions[];
  postStatus: boolean | null;
  setForm: (payload: { item: string; value: any | null }) => void;
  setBrand: (payload: TSelectOptions | null) => void;
  setTag: (payload: string, type: 'add' | 'remove') => void;
  setCategory: (payload: TSelectOptions | null) => void;
  setProduct: (payload: TSelectOptions | null) => void;
  clearForm: () => void;
  fetchPosts: (_start: number, _end: number) => Promise<void>;
  uploadVideo: (videoData: any) => Promise<TVideoUploadResponse | undefined>;
  fetchPostDetails: (id: number) => Promise<string | void>;
  createPost: (formData: any) => Promise<void | string>;
  editPost: (formData: any) => Promise<void | string>;
  fetchHashTags: () => Promise<THashtagsList[] | undefined>;
  createHashTags: (tag: string) => Promise<void | number | string>;
  deletePost: (postId: number) => Promise<void | string>;
  togglePost: (postId: number, enable: boolean) => Promise<void | string>;
  fetchFilteredProducts: (
    payload: TFetchFilteredProducts
  ) => Promise<{ id: number; label: string }[] | undefined>;
}>((set, get) => ({
  posts: [],
  loading: false,
  error: null,
  postForm: initialPostForm,
  hashTagsList: [],
  postTotalCount: 0,
  filteredProductList: [],
  postStatus: false,
  setForm: (payload) => {
    const { item, value } = payload;

    const setMediaInfo = (state: any) => {
      let index: number;
      let isMediaRemoved = false;
      let media_info = state.postForm.media_info;

      if (item === 'media_info') {
        const keys = Object.keys(value);

        if (keys.includes('removeVideo')) {
          index = value['removeVideo'];
          isMediaRemoved = true;
          media_info = state.postForm.media_info.filter((item: any, i: number) => i !== index);
        }
        if (keys.includes('removeImage')) {
          index = value['removeImage'];
          isMediaRemoved = true;
          media_info = state.postForm.media_info.filter((item: any, i: number) => i !== index);
        }
        console.log('media removed index', media_info, isMediaRemoved);

        return isMediaRemoved ? media_info : [...media_info, value];
      }

      return value;
    };

    set((state) => ({
      postForm: {
        ...state.postForm,
        [item]: setMediaInfo(state),
      },
    }));
  },
  setBrand: (payload) => {
    set((state) => {
      return {
        postForm: {
          ...state.postForm,
          brand: payload,
        },
      };
    });
  },
  setTag: (payload, type) => {
    const tags = get().postForm.hashTags;
    if (type === 'remove') {
      set((state) => ({
        postForm: {
          ...state.postForm,
          hashTags: tags.filter((tag) => tag !== payload),
        },
      }));
    } else {
      const addedTag = [...tags, payload];
      const uniqueTags = Array.from(new Set(addedTag));
      set((state) => ({
        postForm: {
          ...state.postForm,
          hashTags: uniqueTags,
        },
      }));
    }
  },
  setCategory: (payload) => {
    set((state) => {
      return {
        postForm: {
          ...state.postForm,
          category: payload,
        },
      };
    });
  },
  setProduct: (payload) => {
    set((state) => {
      return {
        postForm: {
          ...state.postForm,
          product: payload,
        },
      };
    });
  },
  clearForm: () => {
    set({
      postForm: initialPostForm,
      postStatus: null,
    });
  },
  fetchPosts: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const posts: TPostResponse = await fetchPostService(_start, _end);
      set({
        posts: posts.data.map((post) => ({ ...post, hashtags: post.hashtags?.join(',') })),
        loading: false,
        postTotalCount: posts.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  uploadVideo: async (videoData: any) => {
    try {
      set({ loading: true });
      const video: TVideoUploadResponse = await videoUploadService(videoData);
      set({ loading: false });
      return video;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createPost: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await postCreateService(formData);
      console.log(formData);

      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  editPost: async (formData: any) => {
    try {
      set({ loading: true });
      const status = await editPostService(formData);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchPostDetails: async (id: number) => {
    try {
      set({ loading: true });
      const postDetails = await postDetailService(id);
      const categories = await useProductStore.getState().fetchCategories();
      const hashTagsList = await get().fetchHashTags();
      const brands = await fetchBrands();
      let formCategory: TSelectOptions | null = null;
      let formBrand: TSelectOptions | null = null;
      let formHashTags: string[] | null = null;
      let formProduct: TSelectOptions | null = null;
      if (postDetails) {
        const { category_id, description, brand_id, product_id, hashtags, is_active, media_info } =
          postDetails;

        if (categories) {
          const filteredCategory = categories
            .filter((category) => category.id === category_id)
            ?.map((category) => ({
              label: category.title,
              id: category.id,
            }))?.[0];
          if (filteredCategory) formCategory = filteredCategory;
        }
        if (brands) {
          const filteredBrand = brands
            .filter((brand: any) => brand.id === brand_id)
            .map((brand: any) => ({
              label: brand.name,
              id: brand.id,
            }))[0];
          if (filteredBrand) formBrand = filteredBrand;
        }
        if (hashTagsList) {
          formHashTags = hashTagsList
            ?.filter((tag) => hashtags?.includes(tag.tag))
            ?.map((tag) => tag.tag);
        }
        const products = await get().fetchFilteredProducts({
          _start: 0,
          _end: 9,
          brand: brand_id,
          category: category_id,
        });
        if (products) {
          const filteredProduct = products.filter((product: any) => product.id === product_id)[0];
          if (filteredProduct) formProduct = filteredProduct;
        }

        set({
          postForm: {
            category: formCategory,
            brand: formBrand,
            description,
            hashTags: formHashTags || [],
            media_info: media_info || [],
            product: formProduct,
          },
          postStatus: Boolean(is_active),
        });
      }
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
      if ((error as any)?.response?.data) {
        return (error as any).response.data?.message;
      }
    }
  },
  fetchHashTags: async () => {
    try {
      set({ loading: true });
      const hashTagsList: THashtagsList[] = await getHashTagsService();
      set({ loading: false, hashTagsList });
      return hashTagsList;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createHashTags: async (tag: string) => {
    try {
      set({ loading: true });
      const status = await createHashTagsService(tag);
      set({ loading: false });
      if (status?.id) {
        let timeout: any;
        timeout = setTimeout(() => {
          get().fetchHashTags();
          clearTimeout(timeout);
        }, 1000);
        return status.id;
      }
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchFilteredProducts: async (payload) => {
    try {
      const { _start, _end, brand, category } = payload;
      set({ loading: true });
      const products: TFetchProducts = await fetchPostProductService(_start, _end, brand, category);
      const productSelectList = products?.data?.map((product) => ({
        id: product.id,
        label: product.name,
      }));
      set({ filteredProductList: productSelectList, loading: false });
      return productSelectList;
    } catch (error) {
      set({ error, loading: false });
    }
  },
  deletePost: async (postId: number) => {
    try {
      set({ loading: true });
      const status = await deletePostService(postId);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  togglePost: async (postId: number, enable: boolean) => {
    try {
      set({ loading: true });
      const status = await togglePostService(postId, enable);
      set({ loading: false });
      if (status?.message) {
        return status.message;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
}));
