import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Header } from '../../components/Header';
import { PostForm } from '../../components/PostForm';
import { fetchBrands } from '../../state/actions/brands.actions';
import { usePostStore } from '../../state/stores/posts.store';
import { useProductStore } from '../../state/stores/products.store';

export const CreatePost = () => {
  const fetchHashTags = usePostStore((state) => state.fetchHashTags);
  const fetchCategories = useProductStore((state) => state.fetchCategories);

  useEffect(() => {
    fetchHashTags();
    fetchBrands();
    fetchCategories();
  }, [fetchHashTags, fetchCategories]);

  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Create Post" />
      <PostForm />
    </Box>
  );
};
