import { Stack, Typography } from '@mui/material';
import React from 'react';
import './card.css';

const ReplyCard = ({ email = '', comment = '', date = Date.now() }: any) => {
  return (
    <Stack mt={3} mb={1} borderTop="1px solid rgb(0 0 0 / 20%)">
      <Stack mt={1} py={2} px={2} className={'reply_card'}>
        <Stack direction={'row'} gap={2} pb={2}>
          <Typography variant="caption">{email} replied</Typography>
          <Typography variant="caption">|</Typography>
          <Typography variant="caption">{`${(new Date(date) as Date).toLocaleDateString()} ${(
            new Date(date) as Date
          ).toLocaleTimeString()}`}</Typography>
        </Stack>
        <Stack>{comment}</Stack>
      </Stack>
    </Stack>
  );
};

export default ReplyCard;
