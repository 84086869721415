import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
// import { useNavigate } from 'react-router-dom';
import { useOrdersStore, DataRow } from '../../state/stores/orders.store';
import { CustomDataTable } from '../CustomDataTable';
import { HStack, RowData, RowTitle } from './ExpandedStyles';

const TOTAL_APPROVED_ORDERS_PER_PAGE = 10;

export const RejectedOrders = () => {
  // const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const rejectedOrders = useOrdersStore((state) => state.rejectedOrders);
  const orderTotalCount = useOrdersStore((state) => state.totalCount);

  const fetchRejectedOrders = useOrdersStore((state) => state.fetchRejectedOrders);

  const handleOrders = useCallback(() => {
    const _start = (page - 1) * TOTAL_APPROVED_ORDERS_PER_PAGE;
    const _end = page * TOTAL_APPROVED_ORDERS_PER_PAGE - 1;
    fetchRejectedOrders(_start, _end);
  }, [fetchRejectedOrders, page]);

  const handleRowClick = (rowData: any) => {
    // rowData?.id && navigate('/order/' + rowData.id);
  };

  useEffect(() => {
    handleOrders();
  }, [handleOrders]);

  const columns: TableColumn<{ [key: string]: string | number }>[] = useMemo(
    () => [
      {
        name: 'UID',
        selector: (row) => row.uid,
        sortable: true,
      },
      {
        name: 'Customer Address',
        selector: (row) => row.address,
        sortable: true,
      },
      {
        name: 'Product Name',
        selector: (row) => row.product_name,
        sortable: true,
      },
      {
        name: 'Variant',
        selector: (row) => row.product_unit_quantity,
        sortable: true,
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity,
        sortable: true,
      },
      {
        name: 'Rejected Reason',
        selector: (row) => row.reject_reason,
        sortable: true,
      },
    ],
    []
  );

  const expandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    return (
      <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
        <HStack>
          <RowTitle>Customer Email</RowTitle>
          <RowData>{data.customer_email ?? '-'}</RowData>
        </HStack>
        <HStack>
          <RowTitle>Mobile Number</RowTitle>
          <RowData>{data.customer_mobile ?? '-'}</RowData>
        </HStack>
        <HStack>
          <RowTitle>Item Price</RowTitle>
          <RowData>{data.item_price ?? '-'}</RowData>
        </HStack>
        <HStack>
          <RowTitle>Total Price</RowTitle>
          <RowData>{data.total_price ?? '-'}</RowData>
        </HStack>
      </Stack>
    );
  };

  return (
    <Box>
      <CustomDataTable
        highlightOnHover
        defaultSortFieldId="created_date"
        columns={columns}
        data={rejectedOrders}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        paginationTotalRows={orderTotalCount}
        paginationServer
        noHeader={true}
        handleRowClicked={handleRowClick}
        expandedComponent={expandedComponent}
      />
    </Box>
  );
};
