const isNullOrUndefined = (value: any) =>
  value === null ||
  value === undefined ||
  value === '' ||
  (Array.isArray(value) && value.length === 0);

const postRequiredColumns = ['brand', 'categoryId', 'description', 'hashTags'];

export const validatePostForm = (formData: any, isEdit: boolean): string[] => {
  const invalidColumns: string[] = [];
  for (const item in formData) {
    if (!isEdit && postRequiredColumns.includes(item) && isNullOrUndefined(formData[item])) {
      invalidColumns.push(item);
    }
  }
  // if (!isEdit && isNullOrUndefined(formData.image) && isNullOrUndefined(formData.videoUrl)) {
  //   invalidColumns.push(isNullOrUndefined(formData.image) ? 'image' : 'videoUrl');
  // }
  return invalidColumns;
};
