import { Checkbox, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
// import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { TSelectedRow, TDataRow } from '../../pages/PostsPage/posts.utils';
import { DataRow, useOrdersStore } from '../../state/stores/orders.store';
import { CustomDataTable } from '../CustomDataTable';
import { ApprovedMenu } from './ApprovedMenu';
import { ShippingLabelComponentToPrint } from './ShippingLabelComponentToPrint';
import { StoreDialog } from './StoreDialog';
import { PickupRequestModal } from './PickupRequestModal';
import { OrderListData } from './OrderListData';

const TOTAL_APPROVED_ORDERS_PER_PAGE = 10;
const selectProps = { indeterminate: (isIndeterminate: any) => isIndeterminate };

export const ApprovedOrders = ({
  approvedSelected,
  setSelectedRows,
}: {
  approvedSelected: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  // const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [toggleCleared, setToggleCleared] = useState(false);
  const approvedOrders = useOrdersStore((state) => state.approvedOrders);
  const orderTotalCount = useOrdersStore((state) => state.totalCount);

  const fetchApprovedOrders = useOrdersStore((state) => state.fetchApprovedOrders);

  const handleOrders = useCallback(() => {
    const _start = (page - 1) * TOTAL_APPROVED_ORDERS_PER_PAGE;
    const _end = page * TOTAL_APPROVED_ORDERS_PER_PAGE - 1;
    fetchApprovedOrders(_start, _end);
  }, [fetchApprovedOrders, page]);

  const handleRowClick = (rowData: any) => {
    // rowData?.id && navigate('/order/' + rowData.id);
  };

  useEffect(() => {
    handleOrders();
  }, [handleOrders]);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: 'print-page',
  });

  const handleShippingPrint = useCallback(() => {
    handlePrint();
  }, [handlePrint]);

  const handleChange = (selected: TSelectedRow) => {
    if (selected.selectedRows) {
      const allRows = selected.selectedRows.map((item) => item.id as number);
      setSelectedRows(allRows);
    }
  };

  const handleToggle = useCallback(() => {
    setToggleCleared(!toggleCleared);
  }, [toggleCleared]);

  const columns: TableColumn<TDataRow>[] = [
    {
      name: 'UID',
      selector: (row) => row.uid,
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: 'Variant',
      selector: (row) => row.product_unit_quantity,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.accept_status,
      sortable: true,
    },
    {
      name: 'Ordered At',
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: 'Actions',
      sortable: false,
      center: true,
      width: '300px',
      cell: (row) => (
        <ApprovedMenu
          id={row.id as number}
          uid={row.uid as string}
          waybill={row.waybill_number as string}
          invoiceLink={row.invoice_link as string}
          deliveryStatus={row.delivery_status as string}
          handleShippingPrint={handleShippingPrint}
          visibility={{
            downloadInvoice: true,
            generatewaybill: true,
            printShippingLabel: true,
            updateAsDispatch: true,
          }}
        />
      ),
    },
  ];

  const expandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    return (
      <Grid container columns={2}>
        <Grid item sx={{ flex: 1 }}>
          <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
            <OrderListData title="Store Name" data={data.store_name} />
            <OrderListData title="Item Price" data={data.item_price} />
            <OrderListData title="Order Amount" data={data.total_price} />
            <OrderListData title="Shipping Address" data={data.address} />
            <OrderListData title="Shipment Status" data={data.total_price} />
            <OrderListData title="Customer Email" data={data.customer_email} />
          </Stack>
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Stack spacing={2} sx={{ p: 2, mx: 5 }}>
            <OrderListData title="Mobile Number" data={data.customer_mobile} />
            <OrderListData title="Order Status" data={data.delivery_status} />
            <OrderListData title="Last Updated" data={data.modified_date} />
            <OrderListData title="Reason/notes" data={data.return_reason} />
            <OrderListData title="Remarks" data={data.reject_reason} />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomDataTable
        highlightOnHover
        defaultSortFieldId="created_date"
        columns={columns}
        data={approvedOrders}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        paginationTotalRows={orderTotalCount}
        paginationServer
        noHeader={true}
        handleRowClicked={handleRowClick}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={selectProps}
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggleCleared}
        expandedComponent={expandedComponent}
      />
      <StoreDialog handleOrders={handleOrders} />
      <PickupRequestModal approvedSelected={approvedSelected} handleToggle={handleToggle} />
      <div style={{ display: 'none' }}>
        <ShippingLabelComponentToPrint ref={componentRef} />
      </div>
    </>
  );
};
