import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, SortDirection } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { BrandsDataCell } from './BrandsDataCell';
import { TCell, TDataTable, TEnhancedTableHead } from './dataTable.types';
import { NoData } from './NoData';
import { ProductsDataCell } from './ProductsDataCell';
import { getComparator, stableSort } from './table.utils';
import { WarehouseDataCell } from './WarehouseDataCell';
import { OrderDataCell } from './OrderDataCell';

function EnhancedTableHead(props: TEnhancedTableHead) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headerCells,
    showCheckBoxes = true,
  } = props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {showCheckBoxes && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headerCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order === 'asc' ? 'asc' : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = ({
  numSelected,
  title,
  handleDelete,
  selected,
  setSelected,
  type,
}: {
  numSelected: number;
  title: string;
  handleDelete: (selected: number[]) => Promise<void>;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  type: string;
}) => {
  const addTitle = title;

  const getRedirectUrl = () => {
    switch (type) {
      case 'products':
        return '/products/create';
      case 'brand':
        return '/brand/create';
      case 'warehouse':
        return '/warehouse/create';
      default:
        return '';
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h5" id="tableTitle" component="div">
          {title} Listing
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setSelected([]);
              handleDelete(selected);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Link
          to={getRedirectUrl()}
          style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ minWidth: 200, fontSize: '12px' }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Create {addTitle}
          </Button>
        </Link>
      )}
    </Toolbar>
  );
};

export const DataTable = ({
  title,
  headerCells,
  rows,
  type,
  handleDelete,
  totalCount,
  page,
  setPage,
  handleDetails,
  showCheckBoxes = true,
}: TDataTable) => {
  const [order, setOrder] = React.useState<SortDirection>('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyData = rows.length === 0;

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id as number);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.FormEvent, name: string | number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name as number);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string | number) => selected.indexOf(name as number) !== -1;

  const renderCells = (row: TCell, labelId: string) => {
    switch (type) {
      case 'products':
        return <ProductsDataCell row={row} labelId={labelId} />;
      case 'brand':
        return <BrandsDataCell row={row} labelId={labelId} />;
      case 'warehouse':
        return <WarehouseDataCell row={row} labelId={labelId} />;
      case 'order':
        return <OrderDataCell row={row} labelId={labelId} />;
      default:
        return null;
    }
  };

  const getTitles = () => {
    switch (type) {
      case 'products':
        return 'Product';
      case 'brand':
        return 'Brand';
      case 'warehouse':
        return 'Warehouse';
      case 'order':
        return 'Orders';
      default:
        return null;
    }
  };

  const getNoDataTitle = () => {
    switch (type) {
      case 'products':
        return {
          title: 'Click on create product to add an item',
          subtitle: 'No products added.',
        };
      case 'brand':
        return {
          title: 'Click on create brand to add an item',
          subtitle: 'No brand added.',
        };
      case 'warehouse':
        return {
          title: 'Click on create store to add an item',
          subtitle: 'No stores added.',
        };
      case 'order':
        return {
          title: 'Click on reject order to add an item',
          subtitle: 'No orders added.',
        };
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        mx: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ width: '100%', height: '100%' }}>
        <EnhancedTableToolbar
          title={getTitles() || ('' as string)}
          numSelected={selected.length}
          handleDelete={handleDelete}
          setSelected={setSelected}
          selected={selected}
          type={type}
        />
        {emptyData ? (
          <NoData {...getNoDataTitle()} />
        ) : (
          <TableContainer sx={{ maxHeight: 'calc(100vh - 180px)', height: '100%' }}>
            <Table sx={{ minWidth: 750 }} stickyHeader size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headerCells={headerCells}
                showCheckBoxes={showCheckBoxes}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row: TCell, index: number) => {
                    const isItemSelected = isSelected(row.id as number);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        sx={{ cursor: 'pointer' }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id as number}
                        selected={isItemSelected}
                        onClick={() => {
                          handleDetails(row.id as number);
                        }}
                      >
                        {showCheckBoxes && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              onClick={(event) => handleClick(event, row.id as number)}
                            />
                          </TableCell>
                        )}
                        {renderCells(row, labelId)}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
