import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { CustomDataTable } from '../../components/CustomDataTable';
import { useQuery } from '../../hooks';
import { fetchSupportTickerList } from '../../state/actions/support.action';

const SupportPage = () => {
  const navigate = useNavigate();
  const { exec, data } = useQuery();
  const [page, setPage] = useState(1);

  const TOTAL_APPROVED_ORDERS_PER_PAGE = 10;

  const handleRowClick = (rowData: any) => {
    rowData?.id && navigate('/support/' + rowData.id);
  };

  //   const handleActionClick =
  //     (action: 'REJECTED' | 'ACCEPTED', orderId?: string | number) => (e: any) => {
  //       e.stopPropagation();
  //       setShowModal(false);
  //       setTimeout(() => {
  //         setAction(action);
  //         setSelectedId(orderId);
  //         setShowModal(true);
  //       }, 0);
  //     };

  //   const handleCloseModal = () => {
  //     setShowModal(false);
  //     setTimeout(() => {
  //       setAction('ACCEPTED');
  //       setSelectedId(undefined);
  //       handleInputChange({ reason: '' });
  //     }, 0);
  //   };

  //   const handleSubmit = (e: FormEvent) => {
  //     e.preventDefault();
  //     if (selectedId)
  //       updateOrderStatus(selectedId, action, formState.reason).then(() => {
  //         handleCloseModal();
  //         handleOrders();
  //       });
  //   };

  const handleOrders = useCallback(() => {
    const _start = (page - 1) * TOTAL_APPROVED_ORDERS_PER_PAGE;
    const _end = page * TOTAL_APPROVED_ORDERS_PER_PAGE - 1;
    exec(() => fetchSupportTickerList(_start, _end));
  }, [fetchSupportTickerList, page]);

  useEffect(() => {
    handleOrders();
  }, [handleOrders]);

  const columns: TableColumn<{ [key: string]: string | number }>[] = useMemo(
    () => [
      {
        name: 'Ticket ID',
        selector: (row) => row.desk_ticket_id,
        sortable: true,
      },
      {
        name: 'Issue',
        selector: (row) => row.description,
        sortable: true,
      },
      // {
      //   name: 'Response ID',
      //   selector: (row) => row.product_name,
      //   sortable: true,
      // },
      {
        name: 'Response',
        selector: (row) => row.category_text,
        sortable: true,
      },
      {
        name: 'Status',
        selector: (row) => row.desk_ticket_status,
      },
    ],
    []
  );

  return (
    <Box>
      <CustomDataTable
        highlightOnHover
        defaultSortFieldId="created_date"
        columns={columns}
        data={data?.data || []}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        paginationTotalRows={data?.totalCount || 0}
        paginationServer
        noHeader={true}
        handleRowClicked={handleRowClick}
        // expandedComponent={expandedComponent}
      />
    </Box>
  );
};

export default SupportPage;
