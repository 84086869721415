import create from 'zustand';
import {
  fetchApprovedOrdersService,
  generateShippingLabelService,
  fetchPendingOrdersService,
  fetchRejectedOrdersService,
  fetchCancelledOrdersService,
  fetchReturnedOrdersService,
  createVendorOrderService,
  pickupRequestService,
  orderPickedupService,
  fetchCompletedOrdersService,
} from '../actions/orders.action';
import { showSnack } from './snackBar.store';

type TStoreModal = {
  open: boolean;
  data?: any;
};
export type DataRow = {
  customer_email: string;
  customer_mobile: string;
  item_price: string;
  total_price: string;
  store_name: string;
  return_address: string;
  address: string;
  delivery_status: string;
  modified_date: string;
  return_reason: string;
  reject_reason: string;
  cancel_reason: string;
};
export const useOrdersStore = create<{
  loading: boolean;
  pendingOrders: any[];
  approvedOrders: any[];
  rejectedOrders: any[];
  cancelledOrders: any[];
  returnedOrders: any[];
  completedOrders: any[];
  totalCount: number;
  totalRejectedCount: number;
  shippingLabelData: any;
  error: unknown;
  storeModal: TStoreModal;
  pickupRequestModal: TStoreModal;
  setStoreModal: (payload: TStoreModal) => void;
  setPickupRequestModal: (payload: TStoreModal) => void;
  fetchPendingOrders: (_start: number, _end: number) => Promise<void>;
  fetchApprovedOrders: (_start: number, _end: number) => Promise<void>;
  generateShippingLabel: (uid: string) => Promise<boolean | void>;
  fetchRejectedOrders: (_start: number, _end: number) => Promise<void>;
  fetchCancelledOrders: (_start: number, _end: number) => Promise<void>;
  fetchReturnedOrders: (_start: number, _end: number) => Promise<void>;
  fetchCompletedOrders: (_start: number, _end: number) => Promise<void>;
  createVendorOrder: (storeId: string, uid: string) => Promise<boolean | void>;
  createPickupRequest: (
    orderIds: number[],
    storeId: string,
    pickupDate: any,
    pickupTime: any
  ) => Promise<void>;
  orderPickedup: (orderId: number) => Promise<void>;
}>((set, get) => ({
  loading: false,
  pendingOrders: [],
  approvedOrders: [],
  rejectedOrders: [],
  cancelledOrders: [],
  returnedOrders: [],
  completedOrders: [],
  totalCount: 0,
  totalRejectedCount: 0,
  shippingLabelData: null,
  error: null,
  storeModal: { open: false, data: null },
  pickupRequestModal: { open: false, data: null },
  setStoreModal: (payload) => {
    const { open, data } = payload;
    if (data) {
      set({ storeModal: { open, data } });
    } else {
      set({ storeModal: { ...get().storeModal, open } });
    }
  },
  setPickupRequestModal: (payload) => {
    const { open, data } = payload;
    if (data) {
      set({ pickupRequestModal: { open, data } });
    } else {
      set({ pickupRequestModal: { ...get().pickupRequestModal, open } });
    }
  },
  fetchPendingOrders: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const pendingOrders: any = await fetchPendingOrdersService(_start, _end);
      set({
        pendingOrders: pendingOrders?.data,
        loading: false,
        totalCount: pendingOrders?.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchApprovedOrders: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const approvedOrders: any = await fetchApprovedOrdersService(_start, _end);
      set({
        approvedOrders: approvedOrders?.data,
        loading: false,
        totalCount: approvedOrders?.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  generateShippingLabel: async (uid: string) => {
    try {
      set({ loading: true });
      const shippingLabelData: any = await generateShippingLabelService(uid);
      set({
        shippingLabelData,
        loading: false,
      });
      if (!!shippingLabelData?.shipping_label_barcode) {
        return true;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchRejectedOrders: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const rejectedOrders: any = await fetchRejectedOrdersService(_start, _end);
      set({
        rejectedOrders: rejectedOrders?.data,
        loading: false,
        totalCount: rejectedOrders?.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchCancelledOrders: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const cancelledOrders: any = await fetchCancelledOrdersService(_start, _end);
      set({
        cancelledOrders: cancelledOrders?.data,
        loading: false,
        totalCount: cancelledOrders?.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchReturnedOrders: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const returnedOrders: any = await fetchReturnedOrdersService(_start, _end);
      set({
        returnedOrders: returnedOrders?.data,
        loading: false,
        totalCount: returnedOrders?.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  fetchCompletedOrders: async (_start: number, _end: number) => {
    try {
      set({ loading: true });
      const completedOrders: any = await fetchCompletedOrdersService(_start, _end);
      set({
        completedOrders: completedOrders?.data,
        loading: false,
        totalCount: completedOrders?.totalCount,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createVendorOrder: async (storeId: string, uid: string) => {
    try {
      set({ loading: true });
      const createdOrder: any = await createVendorOrderService(storeId, uid);
      set({
        loading: false,
      });
      if (createdOrder?.waybill_number) {
        return true;
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
  createPickupRequest: async (
    orderIds: number[],
    storeId: string,
    pickupDate: any,
    pickupTime: any
  ) => {
    try {
      set({ loading: true });
      const pickupRequest: any = await pickupRequestService(
        orderIds,
        storeId,
        pickupDate,
        pickupTime
      );
      if (pickupRequest === 200) {
        showSnack('success', 'Pickup request created for selected orders.');
      }
      set({
        loading: false,
      });
    } catch (error) {
      set({ error, loading: false });
    }
  },
  orderPickedup: async (orderId: number) => {
    try {
      set({ loading: true });
      const pickedUp: any = await orderPickedupService(orderId);
      set({
        loading: false,
      });
      if (pickedUp === 200) {
        showSnack('success', 'Order pickedup successfully.');
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },
}));
