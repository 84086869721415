import React, { memo } from 'react';
import { FormControl, InputLabel, FilledInput } from '@mui/material';

type TFormProps = {
  label: string;
  value: string;
  handleChange: (
    item: string
  ) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  inputId: string;
  multiline?: boolean;
  inputComponent?: any;
  item: string;
  required?: boolean;
  error?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  maxRows?: number;
  minRows?: number;
};

export const FormInput = memo(
  ({
    label,
    value,
    handleChange,
    inputId,
    multiline = false,
    inputComponent,
    item,
    required,
    error,
    placeHolder,
    disabled,
    maxRows = 3,
    minRows = 3,
  }: TFormProps) => {
    const multiLineProps = {
      multiline: true,
      minRows,
      maxRows,
    };
    const InputProps = {
      inputComponent: inputComponent,
    };
    const placeHolderProps = {
      placeholder: placeHolder,
    };

    return (
      <FormControl variant="filled">
        <InputLabel htmlFor={inputId} required={required} error={error}>
          {label}
        </InputLabel>
        <FilledInput
          id={inputId}
          value={value}
          onChange={handleChange(item)}
          {...(multiline && multiLineProps)}
          {...(!!inputComponent && InputProps)}
          {...(!!placeHolder && placeHolderProps)}
          error={error}
          disabled={disabled}
        />
      </FormControl>
    );
  }
);
