import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useProductStore } from '../../state/stores/products.store';
import { FileUpload } from './FileUpload';
// import { VideoUpload } from './VideoUpload';

type TMedia = {
  images: string[];
  preFilledImages: string[] | undefined;
  columnError: Record<string, boolean>;
  setColumnError: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  disabled: boolean | undefined;
};

export const ProductMultimedia = ({
  images,
  preFilledImages,
  columnError,
  setColumnError,
  disabled,
}: TMedia) => {
  const removePreFilledImage = useProductStore((state) => state.removePreFilledImage);
  const uploadImage = useProductStore((state) => state.uploadImage);
  const setForm = useProductStore((state) => state.setForm);

  const onImageDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        let imageLinks: string[] = [];
        for (const image of acceptedFiles) {
          const imageData = new FormData();
          imageData.append('image', image);
          const imageObject = await uploadImage(imageData);
          const imageLink = imageObject?.img_str;
          if (imageLink) {
            imageLinks.push(imageLink);
          }
        }
        setForm({ item: 'images', value: imageLinks });
        if (columnError.images) setColumnError((prev) => ({ ...prev, images: false }));
      }
    },
    [setForm, uploadImage, columnError.images, setColumnError]
  );

  const handlePreFilledImageRemove = useCallback(
    (image: string, index: number) => () => {
      removePreFilledImage(image, index);
    },
    [removePreFilledImage]
  );

  return (
    <Stack spacing={2} direction="row">
      {/* <Box
        sx={{
          flex: 1,
          p: 2,
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;',
        }}
      >
        <Typography variant="h6" sx={{ pl: 2 }} fontWeight={600}>
          Product Video
        </Typography>
        <VideoUpload videos={[]} />
      </Box> */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" sx={{ py: 1 }} fontWeight={600}>
          Product Images
        </Typography>
        <FileUpload
          images={images}
          onImageDrop={onImageDrop}
          preFilledImages={preFilledImages}
          handlePreFilledImageRemove={handlePreFilledImageRemove}
          error={columnError.images}
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
};
