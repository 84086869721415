import { HStack, RowData, RowTitle } from './ExpandedStyles';

export const OrderListData = ({ data, title }: { data: any; title: string }) => {
  return (
    <HStack>
      <RowTitle>{title}</RowTitle>
      <RowData>{data ?? '-'}</RowData>
    </HStack>
  );
};
