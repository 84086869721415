import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppLoader } from '../../components/AppLoader';
import { Header } from '../../components/Header';
import Input from '../../components/Input';
import { FormSelect } from '../../components/ProductForm/FormSelect';
import { useFormState, useQuery } from '../../hooks';
import { fetchBrands } from '../../state/actions/brands.actions';
import { createStore, getStore, updateStore } from '../../state/actions/warehouse.actions';
import { useBrandService } from '../../state/stores/brands.store';

export interface IWarehouseForm {
  name: string;
  brand_id: string;
  registered_name: string;
  mobile_number?: string;
  city?: string;
  pincode?: string;
  address?: string;
  country?: string;
  email?: string;
  state?: string;
  return_address?: string;
  return_pincode?: string;
  return_city?: string;
  return_state?: string;
  return_country?: string;
}

export const WareHouseForm: FC<{ id?: string }> = ({ id = '' }) => {
  const { state } = useLocation();
  const isEdit = !!id;
  const { exec } = useQuery();
  const { exec: exec1, ready } = useQuery();
  const navigate = useNavigate();
  const brandService = useBrandService((state) => state.brands);
  const [isAddressSame, setAddressSame] = useState(false);
  const [startEdit, setStartEdit] = useState(!isEdit || !(state as any).isDisabled);
  const [formState, onInputChange, dirty] =
    useFormState<IWarehouseForm>({
      name: '',
      brand_id: '',
      registered_name: '',
      country: 'India',
      return_country: 'India',
    });

  useEffect(() => {
    fetchBrands();
    if (id) {
      exec1(() => getStore(id).then((data) => onInputChange(data)));
    }
  }, []);

  const brandOptions = useMemo(() => {
    return brandService?.map((brand) => ({
      id: brand.id,
      label: brand.name,
    }));
  }, [brandService]);

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const funcToExec = isEdit ? updateStore : createStore;

    exec(() =>
      funcToExec(formState).then(() => {
        navigate('/warehouse');
      })
    );
  };

  const getBrandOp = useCallback(() => {
    const op = brandOptions.find((op) => {
      return op.id === formState.brand_id;
    });
    return op;
  }, [formState.brand_id]);

  if (isEdit && (brandOptions.length === 0 || !ready)) return <AppLoader loading={true} />;
  return (
    <Box sx={{ p: 2 }} component="form" onSubmit={handleFormSubmit}>
      <Stack
        flex={1}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        mt={1}
        height={50}
      >
        <Button
          variant="text"
          color="error"
          onClick={() => {
            navigate('/warehouse', { replace: true });
          }}
          size="small"
        >
          Cancel
        </Button>

        {isEdit ? (
          startEdit ? (
            <Button
              variant="outlined"
              size="small"
              role="button"
              type="submit"
              disabled={
                !formState.name || !formState.brand_id || !formState.registered_name || !dirty
              }
              sx={{ ml: 1 }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              sx={{ ml: 1 }}
              onClick={() => setTimeout(() => setStartEdit(true), 0)}
            >
              Edit
            </Button>
          )
        ) : (
          <Button
            variant="outlined"
            size="small"
            role="form"
            type="submit"
            disabled={
              !formState.name || !formState.brand_id || !formState.registered_name || !dirty
            }
            sx={{ ml: 1 }}
          >
            Create
          </Button>
        )}
      </Stack>
      <Stack display={'flex'} flexDirection={'row'}>
        <Stack flex={1}>
          <FormSelect
            value={getBrandOp() || null}
            handleChange={(val: any) => {
              onInputChange({ brand_id: val?.id || '' });
            }}
            label="Brand"
            selectId="brandId"
            options={brandOptions}
            error={false}
            disabled={isEdit && !startEdit}
            variant="filled"
            required
          />
        </Stack>
        <Stack flex={1} />
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} marginTop={2}>
        <Input
          fullWidth
          disabled={!startEdit || !formState.brand_id}
          variant="filled"
          label="Warehouse Name"
          value={formState.name}
          name="name"
          _onChange={onInputChange}
          required
        />
        <Input
          sx={{ ml: 1 }}
          fullWidth
          disabled={!startEdit || !formState.brand_id}
          variant="filled"
          label="Registered Name"
          value={formState.registered_name}
          name="registered_name"
          _onChange={onInputChange}
          required
        />
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} marginTop={1}>
        <Input
          fullWidth
          disabled={!startEdit || !formState.brand_id}
          variant="filled"
          label="Email"
          value={formState.email}
          name="email"
          type={'email'}
          _onChange={onInputChange}
        />
        <Input
          type={'number'}
          sx={{ ml: 1 }}
          fullWidth
          disabled={!startEdit || !formState.brand_id}
          variant="filled"
          label="Mobile Number"
          name="mobile_number"
          value={formState.mobile_number}
          _onChange={onInputChange}
        />
      </Stack>
      <Box display="flex" flexDirection="row">
        <Stack flex={1}>
          <Typography variant="subtitle2" mb={1}>
            Primary Address
          </Typography>
          <Stack sx={{ padding: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.38)' }}>
            <Input
              fullWidth
              disabled={!startEdit || !formState.brand_id}
              variant="filled"
              label="Address"
              value={formState.address}
              name="address"
              multiline
              _onChange={(valObj) => {
                onInputChange({
                  ...valObj,
                  ...(isAddressSame && {
                    [`return_${Object.keys(valObj)[0]}`]: Object.values(valObj)[0],
                  }),
                });
              }}
              InputLabelProps={InputLabelProps}
            />
            <Stack display={'flex'} flexDirection={'row'}>
              <Input
                fullWidth
                disabled={!startEdit || !formState.brand_id}
                variant="filled"
                label="Pincode"
                value={formState.pincode}
                name="pincode"
                _onChange={(valObj) => {
                  onInputChange({
                    ...valObj,
                    ...(isAddressSame && {
                      [`return_${Object.keys(valObj)[0]}`]: Object.values(valObj)[0],
                    }),
                  });
                }}
                type={'number'}
                InputLabelProps={InputLabelProps}
              />
              <Input
                sx={{ ml: 1 }}
                fullWidth
                disabled={!startEdit || !formState.brand_id}
                variant="filled"
                label="City"
                value={formState.city}
                name="city"
                _onChange={(valObj) => {
                  onInputChange({
                    ...valObj,
                    ...(isAddressSame && {
                      [`return_${Object.keys(valObj)[0]}`]: Object.values(valObj)[0],
                    }),
                  });
                }}
                multiline
                InputLabelProps={InputLabelProps}
              />
              <Input
                sx={{ ml: 1 }}
                fullWidth
                disabled={!startEdit || !formState.brand_id}
                variant="filled"
                label="State"
                value={formState.state}
                name="state"
                _onChange={(valObj) => {
                  onInputChange({
                    ...valObj,
                    ...(isAddressSame && {
                      [`return_${Object.keys(valObj)[0]}`]: Object.values(valObj)[0],
                    }),
                  });
                }}
                InputLabelProps={InputLabelProps}
              />
            </Stack>
            <Input
              fullWidth
              disabled={true}
              variant="filled"
              label="Country"
              value={formState.country}
              name="country"
              _onChange={onInputChange}
              InputLabelProps={InputLabelProps}
            />
          </Stack>
        </Stack>
        <Stack flex={1} marginLeft={1}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant="subtitle2" mb={1}>
              Return Address{' '}
            </Typography>

            <FormControlLabel
              disabled={!startEdit || !formState.brand_id}
              checked={isAddressSame}
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 0, pr: 0.5, mb: 1 }}
                  onChange={() => {
                    setAddressSame(!isAddressSame);
                    if (!isAddressSame) {
                      onInputChange({
                        return_state: formState.state,
                        return_city: formState.city,
                        return_address: formState.address,
                        return_pincode: formState.pincode,
                      });
                    }
                  }}
                  name="gilad"
                />
              }
              label={
                <Typography variant="subtitle2" mb={1}>
                  {' '}
                  Same As Primary Address
                </Typography>
              }
            />
          </Stack>

          <Stack sx={{ padding: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.38)' }}>
            <Input
              fullWidth
              disabled={!startEdit || !formState.brand_id || isAddressSame}
              variant="filled"
              label="Return Address"
              value={formState.return_address}
              name="return_address"
              _onChange={onInputChange}
              multiline
              InputLabelProps={InputLabelProps}
            />
            <Stack display={'flex'} flexDirection={'row'}>
              <Input
                fullWidth
                disabled={!startEdit || !formState.brand_id || isAddressSame}
                variant="filled"
                label="Return Pincode"
                value={formState.return_pincode}
                name="return_pincode"
                _onChange={onInputChange}
                type={'number'}
                InputLabelProps={InputLabelProps}
              />
              <Input
                sx={{ ml: 1 }}
                fullWidth
                disabled={!startEdit || !formState.brand_id || isAddressSame}
                variant="filled"
                label="Return City"
                value={formState.return_city}
                name="return_city"
                _onChange={onInputChange}
                InputLabelProps={InputLabelProps}
              />
              <Input
                sx={{ ml: 1 }}
                fullWidth
                disabled={!startEdit || !formState.brand_id || isAddressSame}
                variant="filled"
                label="Return State"
                value={formState.return_state}
                name="return_state"
                _onChange={onInputChange}
                InputLabelProps={InputLabelProps}
              />
            </Stack>
            <Input
              disabled
              fullWidth
              variant="filled"
              label="Return Country"
              value={formState.return_country}
              name="return_country"
              _onChange={onInputChange}
              InputLabelProps={InputLabelProps}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

const InputLabelProps = {
  shrink: true,
};

export const CreateWareHouse = () => {
  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Create Ware House" />
      <WareHouseForm />
    </Box>
  );
};
