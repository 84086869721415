import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AppLoader } from '../../components/AppLoader';
import { THeaderCell } from '../../components/DataTable/dataTable.types';
import { Header } from '../../components/Header';
import { Listings } from '../../components/Listings';
import { useQuery } from '../../hooks';
import { fetchStore } from '../../state/actions/warehouse.actions';
import { useWarehouseStore } from '../../state/stores/warehouse.store';

export const warehouseHeaders = [
  { id: 'name', name: 'Name' },
  { id: 'active', name: 'Active' },
  { id: 'city', name: 'City' },
  { id: 'modified_date', name: 'Updated At' },
  { id: 'action', name: 'Actions' },
];

export const Warehouse = () => {
  const { loading, exec } = useQuery();
  const { store } = useWarehouseStore();
  const [page, setPage] = useState(0);

  const warehouseHeaderCells: THeaderCell[] = warehouseHeaders.map((brand) => {
    return {
      id: brand.id,
      numeric: false,
      disablePadding: false,
      label: brand.name,
    };
  });

  useEffect(() => {
    exec(() => fetchStore());
  }, []);
  return (
    <Box sx={{ height: '100%' }}>
      <Header title="Warehouse" />
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Listings
          productHeaderCells={warehouseHeaderCells}
          rows={store}
          type="warehouse"
          page={page}
          setPage={setPage}
          totalCount={store.length}
          showCheckBoxes={false}
        />
      )}
    </Box>
  );
};
