import { axiosInstance } from '../axios';
import { showSnack } from '../stores/snackBar.store';

export const createSupportTicket = (payload: any) => {
  const p = {
    category_id: payload.cetegory.id,
    description: payload.reason,
    orderitem_id: payload.orderid,
    subject: payload.subjectLine,
  };
  return axiosInstance.post('/vendor/ticket/create', p).then((res) => {
    return res.data;
  });
};

export const fetchIssueCategoryList = () => {
  return axiosInstance.get('/vendor/issuecategory/list').then((res) => res.data);
};

export const fetchTicketDetails = (id: string) => {
  return axiosInstance
    .get('/vendor/ticket/details', { params: { ticket_id: id } })
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchSupportTickerList = (_start: number, _end: number) => {
  return axiosInstance
    .get(`/vendor/ticket/list?_start=${_start}&_end=${_end}`)
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const fetchCommentList = (id: string) => {
  return axiosInstance
    .get('/vendor/ticket/comment/list', {
      params: {
        ticket_id: id,
        start: 0,
        limit: 100,
      },
    })
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
    });
};

export const postSupportComment = (id: string, comment: string) => {
  return axiosInstance
    .post('/vendor/ticket/comment/create', {
      ticket_id: id,
      comment: comment,
    })
    .then((res) => {
      return res.data || [];
    })
    .catch((err) => {
      if (err.response?.data?.message) {
        showSnack('error', err.response.data.message);
      } else {
        showSnack('error', 'Something went wrong. Please try again later.');
      }
      throw err;
    });
};
