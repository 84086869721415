import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useProductStore } from '../../state/stores/products.store';

type THashTagsSelect = {
  inputValue?: string;
  title: string;
};

const filter = createFilterOptions<THashTagsSelect>();

export const HashTagsSelect = ({
  error,
  disabled,
  setColumnError,
}: {
  error: boolean;
  disabled: boolean | undefined;
  setColumnError: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}) => {
  const [value, setValue] = React.useState<THashTagsSelect | null>(null);
  const hashTagsList = useProductStore((state) => state.hashTagsList);

  const setTag = useProductStore((state) => state.setTag);
  const createHashTags = useProductStore((state) => state.createHashTags);

  const memoHashTags: readonly THashTagsSelect[] = React.useMemo(() => {
    return hashTagsList?.map((tag) => {
      return {
        title: tag.tag,
      };
    });
  }, [hashTagsList]);

  return (
    <Autocomplete
      value={value}
      onChange={async (event, newValue) => {
        if (typeof newValue !== 'string') {
          if (newValue && newValue.inputValue) {
            const id = await createHashTags(newValue.inputValue);
            if (id) {
              setTag(newValue.inputValue, 'add');
              setValue({ title: '', inputValue: undefined });
              if (error) setColumnError((prev) => ({ ...prev, hashTags: false }));
            }
          } else if (newValue?.title) {
            setTag(newValue.title, 'add');
            setValue({ title: '', inputValue: undefined });
            if (error) setColumnError((prev) => ({ ...prev, hashTags: false }));
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      id="hashtags-select"
      options={memoHashTags}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      fullWidth
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select / Create Tags"
          variant="filled"
          error={error}
          required
        />
      )}
      clearIcon={<ClearIcon />}
      blurOnSelect
      disabled={disabled}
    />
  );
};

const ClearIcon = () => {
  return null;
};
